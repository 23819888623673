import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

import { SalonService } from '../services/salon.service';



@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
  styleUrls: ['./dealer-list.component.css']
})
export class DealerListComponent implements OnInit {

  salons: any = []

  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let region = params['region'].replace("mien-", '');
      if (region) {
        this.getSalonsByRegion(region);
      }
    });

  }

  public getSalonsByRegion(region) {
    this.salonService.getSalonsByRegion(region)
      .then(salons => {
        this.salons = salons;
      });
  }


}
