<!-- <nav class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow sticky-top">
    <a ui-sref="home" class="btn btn-outline-primary m-1" ui-sref-active="active">Home</a>
    <a ui-sref="albums" class="btn btn-outline-primary m-1" ui-sref-active="active">Albums</a>
    <a ui-sref="posts" class="btn btn-outline-primary m-1" ui-sref-active="active">Posts</a>
</nav> -->
<!-- Start Site Header -->
<div class="site-header-wrapper">
  <header class="site-header">
      <div class="container sp-cont">
          <div class="site-logo">
              <h1><a [routerLink]="['home']"><img src="assets/images/logo.png" alt="Logo"></a></h1>
              <span class="site-tagline"><br/>Sàn giao dịch ôtô<br>số 1 Việt Nam!</span>
          </div>
          <div class="header-right">

            <div class="user-login-panel logged-in-user" *ngIf="um.user">
                <a href="#" class="user-login-btn" id="userdropdown" data-toggle="dropdown">
                    <img src="http://placehold.it/100x100&amp;text=IMAGE+PLACEHOLDER" alt="">
                    <span class="user-informa">
                        <span class="meta-data">Xin chào</span>
                        <span class="user-name">{{!um.user.email ? um.user.firstName + ' ' + um.user.lastName : um.user.email}}</span>
                    </span>
                    <span class="user-dd-dropper"><i class="fa fa-angle-down"></i></span>
                </a>
                <ul class="dropdown-menu" role="menu" aria-labelledby="userdropdown">
                    <li><a [routerLink]="['/users', um.user.id ,'quan-ly-chung']">Quản Lý Chung</a></li>
                    <li><a [routerLink]="['/users', um.user.id ,'tim-kiem']">Tìm Kiếm Đã Lưu</a></li>
                    <li><a [routerLink]="['/users', um.user.id ,'tin-da-luu']">Tin Bán Xe Đã Lưu</a></li>
                    <li><a [routerLink]="['/users', um.user.id ,'quan-ly-tin-ban-xe']">Quản Lý Tin Bán Xe</a></li>
                    <li><a [routerLink]="['/users', 'xxx' ,'profile']">Hồ Sơ Cá Nhân</a></li>
                    <li><a [routerLink]="['/users', 'xxx' ,'setting']">Thiết Lập</a></li>
                    <li><a (click)="logOut();">Thoát</a></li>
                </ul>
            </div>
            <div class="user-login-panel" *ngIf="!um.user">
                <a href="#" (click)= "showModalLogin()" class="user-login-btn" data-toggle="modal"><i class="icon-profile"></i></a>
            </div>
              <div class="topnav dd-menu">
                  <ul class="top-navigation sf-menu">
                      <li><a [routerLink]="['/', 'danh-sach-xe-oto']">Mua</a></li>
                      <li><a [routerLink]="['dang-ban-xe-oto', 'chon-xe']">Bán</a></li>
                      <li><a [routerLink]="['joinus']" *ngIf="!um.user">Đăng Ký</a></li>
                  </ul>
              </div>
          </div>
      </div>
  </header>
  <!-- End Site Header -->
  <div class="navbar">
      <div class="container sp-cont">
          <div class="search-function">
              <a href="#" class="search-trigger"><i class="fa fa-search"></i></a>
              <!-- <span><i class="fa fa-phone"></i> Gọi ngay <strong> 0907 506 614 </strong></span> -->
          </div>
          <a href="#" class="visible-sm visible-xs" id="menu-toggle"><i class="fa fa-bars"></i></a>
          <!-- Main Navigation -->
          <nav class="main-navigation dd-menu toggle-menu" role="navigation">
              <ul class="sf-menu">
                  <li>
                      <a [routerLink]="['/', 'danh-sach-xe-oto']">Mua Xe</a>
                  </li>
                  <li>
                      <a  [routerLink]="['dang-ban-xe-oto', 'chon-xe']">Bán Xe</a>
                  </li>
                  <li class="megamenu"><a href="javascript:void(0)">Tìm Nhanh</a>
                      <ul class="dropdown">
                          <li>
                              <div class="megamenu-container container">
                                  <div class="row">
                                      <div class="mm-col col-md-2">
                                          <ul class="sub-menu">
                                              <li><a [routerLink]="['/', 'danh-sach-xe-oto', 'can-mua-xe-oto-moi']">Xe Mới</a></li>
                                              <li><a [routerLink]="['/', 'danh-sach-xe-oto', 'can-mua-xe-oto-da-qua-su-dung']">Xe Qua Sử Dụng</a></li>
                                              <!-- <li><a href="blog.html">Đánh Giá Xe</a></li> -->
                                              <!-- <li><a href="about.html">Bảo Hiểm Xe</a></li> -->
                                          </ul>
                                      </div>
                                      <div class="mm-col col-md-5">
                                          <span class="megamenu-sub-title">Tìm Xe Theo Kiểu Dáng</span>
                                          <span><br/></span>
                                          <ul class="body-type-widget">
                                              <li> <a [routerLink]="['/', 'danh-sach-xe-oto', 'kieu-dang-sedan']"><img src="assets/images/body-types/sedan.png" alt=""> <span>Sedan</span></a></li>
                                              <li> <a [routerLink]="['/', 'danh-sach-xe-oto', 'kieu-dang-hatchback']"><img src="assets/images/body-types/hatchback.png" alt=""> <span>Hatchback</span></a></li>
                                              <li> <a [routerLink]="['/', 'danh-sach-xe-oto', 'kieu-dang-suv']"><img src="assets/images/body-types/suv.png" alt=""> <span>SUV</span></a></li>
                                              <li> <a [routerLink]="['/', 'danh-sach-xe-oto', 'kieu-dang-minivan']"><img src="assets/images/body-types/minivan.png" alt=""> <span>Minivan</span></a></li>
                                              <li> <a [routerLink]="['/', 'danh-sach-xe-oto', 'kieu-dang-convertible']"><img src="assets/images/body-types/convertible.png" alt=""> <span>Convertible</span></a></li>
                                              <li> <a [routerLink]="['/', 'danh-sach-xe-oto', 'kieu-dang-coupe']"><img src="assets/images/body-types/coupe.png" alt=""> <span>Coupe</span></a></li>
                                          </ul>
                                          <a [routerLink]="['/', 'danh-sach-xe-oto']" class="basic-link"><br/>xem tất cả</a>
                                      </div>
                                      <div class="mm-col col-md-5">
                                          <span class="megamenu-sub-title">Tìm Xe Theo Thương Hiệu</span>
                                          <ul class="make-widget">
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-toyota']"><img src="assets/images/make/toyota_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-honda']"><img src="assets/images/make/honda_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-mazda']"><img src="assets/images/make/mazda_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-hyundai']"><img src="assets/images/make/hyundai_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-audi']"><img src="assets/images/make/audi_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-bmw']"><img src="assets/images/make/bmw_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-lexus']"><img src="assets/images/make/lexus_menu.png" alt=""></a></li>
                                              <li class="item"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-porsche']"><img src="assets/images/make/porsche_menu.png" alt=""></a></li>
                                          </ul>
                                          <a [routerLink]="['/', 'tat-ca-thuong-hieu-xe-oto']" class="basic-link">xem tất cả</a>
                                      </div>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </li>
                  <!-- <li><a href="javascript:void(0)">Thông Số Xe Mới</a>
                      <ul class="dropdown">
                          <li><a href="make-detail.html">Thông Số Xe Toyota</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Honda</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Mazda</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Kia</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Hyundai</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Chevrolet</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Mitsubishi</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Nissan</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Vinfast</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Ford</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Audi</a></li>
                          <li><a href="make-detail.html">Thông Số Xe BMW</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Mercedes</a></li>
                          <li><a href="make-detail.html">Thông Số Xe Porche</a></li>
                          <li><a href="all-makes.html">Tất Cả Các Hãng</a></li>
                      </ul>
                  </li> -->
                  <li><a href="javascript:void(0)">Tin Tức</a>
                      <ul class="dropdown">
                          <!-- <li><a href="blog.html">Tin Thị Trường</a></li> -->
                          <li><a [routerLink]="['blog']">Blog ô tô</a></li>
                          <!-- <li><a href="blog.html">Đánh Giá Xe</a></li> -->
                          <!-- <li><a href="vehicle-comparision.html">So Sánh Xe</a></li> -->
                      </ul>
                  </li>
                  <!-- <li><a href="dealers-search-results.html">Salon Ôtô</a>
                      <ul class="dropdown">
                          <li><a [routerLink]="['salon-oto-ca-nuoc', 'mien-bac']">Salon Miền Bắc</a></li>
                          <li><a [routerLink]="['salon-oto-ca-nuoc', 'mien-trung']">Salon Miền Trung</a></li>
                          <li><a [routerLink]="['salon-oto-ca-nuoc', 'mien-nam']">Salon Miền Nam</a></li>
                      </ul>
                  </li> -->
                  <li><a [routerLink]="['lien-he-meoto']">Liên Hệ</a>
                  </li>
              </ul>
          </nav>
      </div>
  </div>
</div>

<!-- Modal login -->


<div class="modal fade" id="modalUser" tabindex="-1" role="dialog" aria-labelledby="modalUserLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title" id="modalUserLabel" style="text-align: center;">Đăng Nhập</h2>
                    <button type="button" class="close button-signup" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div class="modal-body">
                        <form #userForm="ngForm" (ngSubmit)="onLoginSubmit(userForm.value)">
                            <!-- Regular Signup -->
                            <div class="regular-signup">
                                <!-- <div class="onoffswitch">
                                    <input type="checkbox"  (change)="checkValue(isChecked?'email':'phone')" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch-login" checked>
                                    <label class="onoffswitch-label" for="myonoffswitch-login">
                                            <span class="onoffswitch-inner"></span>
                                            <span class="onoffswitch-switch"></span>
                                        </label>
                                </div> -->
                                <!-- <input type="email" [hidden]="!isChecked" #phone  name="phone" ngModel class="form-control" placeholder="Nhập số điện thoại"> -->
                                <input type="text" #userName  name="userName" ngModel class="form-control" placeholder="Nhập phone hoặc email">
                                <input type="password" #password class="form-control"  #password name="password" ngModel placeholder="Nhập mật khẩu" />
                                <input type="submit" class="btn btn-primary btn-lg btn-block" value="Đăng Nhập">
                            </div>
                            <!-- Social Signup -->
                            <div class="social-signup">
                                <span class="or-break">or</span>
                                <button type="button" (click)="submitLoginFacebook();"
                                    class="btn btn-block btn-facebook btn-social"><i class="fa fa-facebook"></i> Đăng nhập
                                    với Facebook</button>
                                    <button type="button" class="btn btn-block btn-google btn-social loginBtn loginBtn--google" #loginRef><i
                                        class="fa fa-google"></i> Đăng ký với Google</button>
                            </div>
                        </form>
                </div>
            </div>
        </div>
</div>
<!-- TODO remove start-->
<!-- <ul class="nav nav-tabs nav-inside-tabs" id="cartTabs">
    <li class="active"><a href="#cart-tab" data-toggle="tab" class="cart-btn">Purchases</a></li>
    <li><a href="#cart-data-tab" data-toggle="tab" id="cart-data-btn" class="cart-btn">Data</a></li>
    <li><a href="#cart-pay-tab" data-toggle="tab" id="cart-pay-btn" class="cart-btn">Pay</a></li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane active" id="cart-tab">
      Cart
    </div>
    <div class="tab-pane" id="cart-data-tab">
      Content data
    </div>
    <div class="tab-pane" id="cart-pay-tab">
      Content pay
    </div>
  </div> -->
<!-- TODO remove end-->

<router-outlet (activate)="onActivate($event)"></router-outlet>
<footer class="site-footer">
    <div class="site-footer-top">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 footer_widget widget widget_newsletter">
            <h4 class="widgettitle">Đăng ký bán xe ngay hôm nay</h4>
            <form>
              <input type="text" class="form-control" placeholder="Tên">
              <input type="email" class="form-control" placeholder="Email">
              <input type="submit" class="btn btn-primary btn-lg" value="Đăng ký ngay">
            </form>
          </div>
          <div class="col-md-2 col-sm-6 footer_widget widget widget_custom_menu widget_links">
            <h4 class="widgettitle">Tin Tức</h4>
            <ul>
              <li><a href="blog.html">Tin Thị Trường</a></li>
              <!-- <li><a href="blog-masonry.html">Đánh Giá Xe</a></li> -->
              <!-- <li><a href="about.html">Bảo Hiểm Xe</a></li> -->
              <!-- <li><a href="about-html">So Sánh Xe</a></li> -->
            </ul>
          </div>
          <div class="col-md-2 col-sm-6 footer_widget widget widget_custom_menu widget_links">
            <h4 class="widgettitle">Trợ Giúp</h4>
            <ul>
              <li><a href="results-list.html">Mua Xe</a></li>
              <li><a href="joinus.html">Bán Xe</a></li>
              <li><a href="about.html">Về Chúng Tôi</a></li>
              <li><a href="contact.html">Liên Hệ</a></li>
            </ul>
          </div>
          <div class="col-md-5 col-sm-6 footer_widget widget text_widget">
            <h4 class="widgettitle">Về MeOTO</h4>
            <p>Là một tập thể nhiều kinh nghiệm trong lĩnh vực mua bán ô tô đã qua sử dụng, MeOTO tự tin giúp quý khách
              hàng thực hiện giao dịch mua bán ô tô dễ dàng, nhanh chóng và chuyên nghiệp nhất.<br /><br />
              <!-- Mọi đóng góp ý
              kiến vui lòng gọi hotline <mark>0907.50.66.14</mark> -->
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="site-footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 copyrights-left">
            <p>&copy; 2020 MEOTO. Bản quyền của công ty Dicom Interactive.</p>
          </div>
          <div class="col-md-6 col-sm-6 copyrights-right">
            <ul class="social-icons social-icons-colored pull-right">
              <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
              <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
              <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
              <li class="youtube"><a href="#"><i class="fa fa-youtube"></i></a></li>
              <li class="flickr"><a href="#"><i class="fa fa-flickr"></i></a></li>
              <li class="vimeo"><a href="#"><i class="fa fa-vimeo-square"></i></a></li>
              <li class="digg"><a href="#"><i class="fa fa-digg"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
