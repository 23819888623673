import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CacheService } from '../services/cache.service';
import { LoginService } from '../services/login.service';
declare var FB: any;
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-joinus',
  templateUrl: './joinus.component.html',
  styleUrls: ['./joinus.component.css']
})
export class JoinusComponent implements OnInit {
  auth2: any;
  public jn: any = {};
  // loginElement: HTMLElement;
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private _location: Location,
  ) { 
    
  }
  
  ngOnInit(): void {
    // back /home if user login
    let isLogin = this.loginService.isLogin();
    if (isLogin) {
      this.router.navigate(['/home']);
    }

    // facebook SDK
    this.facebookSDK();
    // google SDK
    this.googleSDK();
  }

  // button login facebook
  public submitLoginFacebook() {
    this.loginService.submitLoginFacebook();
  }

  // button login google
  public submitLoginGoogle() {
    // this.loginService.googleSDK(this.loginElement)
    this.loginService.googleSDK(this.loginElement);
    // this.loginService.submitLoginGoogle(this.loginElement);
  }

  // SDK facebook library
  facebookSDK() {
    this.loginService.facebookSDK();
  }

  // SDK google library
  googleSDK() {
    this.loginService.googleSDK(this.loginElement);
  }


 // button login facebook
 public async onRegisterSubmit(from) {
   await this.loginService.registerUser(from)
}

}
