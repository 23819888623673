// https://www.techiediaries.com/angular/angular-9-8-tutorial-by-example-rest-crud-apis-http-get-requests-with-httpclient/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private REST_API_SERVER = environment.apiUrl;


  constructor(private httpClient: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  }

  public get(endpoint) {
    return this.httpClient.get(this.REST_API_SERVER + endpoint, this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }

  public post(endpoint, data) {
    return this.httpClient.post<any>(this.REST_API_SERVER + endpoint, JSON.stringify(data), this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }

  public patch(endpoint, data) {
    return this.httpClient.patch<any>(this.REST_API_SERVER + endpoint, JSON.stringify(data), this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }

  public delete(endpoint) {
    return this.httpClient.delete(this.REST_API_SERVER + endpoint, this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }
}
