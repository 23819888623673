import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private apiService: ApiService) { }

  public sendContactUs = (contact) => this.apiService.post('contact-us', contact);

}
