import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private apiService: ApiService) { }

  public getVehicleMakes = () => this.apiService.get('vehicle-makes');
  public getVehicleMakesHasLogo = () => this.apiService.get('vehicle-makes?hasLogo=true');
  public getVehicleModels= () => this.apiService.get('vehicle-models');
  public getVehicleAddons = () => this.apiService.get('vehicle-addons');
  // public getVehicleTemplates = (param) => this.apiService.get('vehicle-templates');
  public getVehicleTemplate = (vehicleTemplateId) => this.apiService.get('vehicle-templates/' + vehicleTemplateId);
  public getVehicleBodyTypes = () => this.apiService.get('vehicle-body-types');
  public getVehicleDoors = () => this.apiService.get("vehicle-doors");
  public getVehicleSeats = () => this.apiService.get("vehicle-seats");
  public getVehicleTransmissions = () => this.apiService.get("vehicle-transmissions");
  public getVehicleDriveTypes = () => this.apiService.get("vehicle-driver-types");
  public getVehicleEngines = () => this.apiService.get("vehicle-engines");
  public getVehicleFuels = () => this.apiService.get("vehicle-fuels");
  public getVehicleSources = () => this.apiService.get("vehicle-sources");
  public getVehicleExteriorColor = () => this.apiService.get("vehicle-exterior-colors");


  public getVehicleTemplates(search) {
    let where: any = {};
    let queryBuilder: object = {
      filter: { where: where },
    };
    // inint query param
    let queryParam = '';
    // for place sale the car
    if (search.vehicleMakeId && search.vehicleMakeId != '-') {
      where.vehicleMakeId = search.vehicleMakeId;
    }
    if (search.vehicleModelId && search.vehicleModelId != '-') {
      where.vehicleModelId = search.vehicleModelId;
    }
    if (search.vehicleYearRelease && search.vehicleYearRelease != '-') {
      where.vehicleYearRelease = search.vehicleYearRelease;
    }
    if (Object.keys(where).length > 0) {
      queryParam = "?" + decodeURI(stringify(queryBuilder));
    }

    return this.apiService.get('vehicle-templates' + queryParam);
  }

}
