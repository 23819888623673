import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import {Observable, of } from 'rxjs';
import { VehicleService } from './vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private vehicleService: VehicleService,
    ) { }


  public getVehicleMakes() {
    let _vMakes = "_vmk";
    let rawVehicleMake = localStorage.getItem(_vMakes);
    if (rawVehicleMake) {
      // return Observable.create((observer) => observer.next(JSON.parse(rawVehicleMake)));
      return new Promise((resolve) => resolve(JSON.parse(rawVehicleMake)));
    } else {
      return this.vehicleService.getVehicleMakes()
        .then(vehicleMakes => {    
          // $rootScope.vehicleMakes = vehicleMakes;
          localStorage.setItem("_masterDataCreateAt", Date.now().toString());
          localStorage.setItem(_vMakes, JSON.stringify(vehicleMakes));
          return vehicleMakes;
        });
    }
  }

  public getVehicleModels() {
    let _vModels = "_vmo";
    let rawVehicleModel = localStorage.getItem(_vModels);
    if (rawVehicleModel) {
      // return Observable.create((observer) => observer.next(JSON.parse(rawVehicleModel)));
      return new Promise((resolve) => resolve(JSON.parse(rawVehicleModel)));
    } else {
      return this.vehicleService.getVehicleModels()
        .then(vehicleModels => {
          localStorage.setItem(_vModels, JSON.stringify(vehicleModels));
          return vehicleModels;
        });
    }
  }

  public getVehicleModelsByVehicleMake(vehicleMake) {
    return this.getVehicleModelsByVehicleMakeId(vehicleMake.id);
  }

  public getVehicleModelsByVehicleMakeId(vehicleMakeId) {
    return this.getVehicleModels()
    .then((vehicleModels) => {
      return (<any[]>vehicleModels).filter(vm => {
        if (vm.vehicleMakeId == vehicleMakeId) {
          return vm;
        }
      });
    }
    );
  }

  public getVehicleAddons() {
    let key = "_vadn";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleAddons);
  }

  public getVehicleBodyTypes() {
    let key = "_vbt";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleBodyTypes);
  }
  public getVehicleDoors() {
    let key = "_vdr";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleDoors);
  }
  public getVehicleSeats() {
    let key = "_vst";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleSeats);
  }
  public getVehicleTransmissions() {
    let key = "_vtn";
    // let tmp = this.vehicleService.getVehicleTransmissions().then(trans => {
    //   for (let tran of <any>trans) {
    //    trans['isSelected'] = false;
    //   }
    //   return trans;
    // });
    // return this._getVehicleAbs(key, tmp);
    return this._getVehicleAbs(key, this.vehicleService.getVehicleTransmissions);
  }
  public getVehicleDriveTypes() {
    let key = "_vdt";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleDriveTypes);
  }
  public getVehicleEngines() {
    let key = "_vee";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleEngines);
  }
  public getVehicleFuels() {
    let key = "_vfl";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleFuels);
  }
  public getVehicleSources() {
    let key = "_vse";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleSources);
  }

  public getVehicleExteriorColor() {
    let key = "_vec";
    return this._getVehicleAbs(key, this.vehicleService.getVehicleExteriorColor);
  }

  private _getVehicleAbs(key, apiPromise) {
    let rawData = localStorage.getItem(key);
    if (rawData) {
      // return Observable.create((observer) => observer.next(JSON.parse(rawData)));
      return new Promise((resolve) => resolve(JSON.parse(rawData)));
    } else {
      return apiPromise()
        .then(data => {
          localStorage.setItem(key, JSON.stringify(data));
          return data;
        });
    }
  }
}
