import { Component, OnInit } from '@angular/core';



import { VehicleService } from '../services/vehicle.service'

@Component({
  selector: 'app-all-make',
  templateUrl: './all-make.component.html',
  styleUrls: ['./all-make.component.css']
})
export class AllMakeComponent implements OnInit {
  vehicleMakes: any = [
  ];
  OrignalVehicleMakes: any = [
  ];
  filterCountry = 'all';

  constructor(
    private vehicelService: VehicleService
  ) { }

  ngOnInit(): void {
    this.getVehicleMake();
  }

  public getVehicleMake() {
    this.vehicelService.getVehicleMakesHasLogo()
      .then(vehicleMakes => {
        this.vehicleMakes = vehicleMakes;
        this.OrignalVehicleMakes = vehicleMakes;
      });
  }

  public filerCountry(country) {
    this.filterCountry = country;

    if (country == 'all') {
      this.vehicleMakes = this.OrignalVehicleMakes;
      return;
    }

    const tmp = [];
    for (let vm of this.OrignalVehicleMakes) {
      if (vm.country == country) {
        tmp.push(vm);
      }
    }
    this.vehicleMakes = tmp;
  }
}
