import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { BlogsService } from '../services/blog.service';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.css']
})
export class SinglePostComponent implements OnInit {
  public sp: any = {};
  constructor(
    private blogsService: BlogsService,
    private activatedRoute: ActivatedRoute,

  ) {
    this.sp.slug = null;
    // this.sp.html = $sce.trustAsHtml('');
    this.sp.html = null;
    this.sp.title = '';
    this.sp.customExcerpt = '';
    this.sp.featureImage = ''

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async params => {

      let slug = params['slug'];
      this.sp.slug = slug; 
      // console.log('slug', slug);
      let blog:any = await this.blogsService.getBlogById(slug);
      this.sp.html = blog.html;
      this.sp.title = blog.title;
      this.sp.customExcerpt = blog.customExcerpt;
      this.sp.featureImage = blog.featureImage;




      // .then(blog => {
      //   this.sp.html = blog.html;
      //   this.sp.title = blog.title;
      //   this.sp.customExcerpt = blog.customExcerpt;
      //   this.sp.featureImage = blog.featureImage;


      //   console.log(blog)
      //   this.sp.blogs = blog;
      //   // // go to screen
      //   // this.activatedRoute.params.subscribe(params => {
      //   //   let slug = params['slug'];
      //   //   this.step(slug);
      //   //   console.log('slug', slug);
      //   // });
      // })
      // .catch(resp => {

      // });
    });
    
  }

}
