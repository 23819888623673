// https://www.techiediaries.com/angular/angular-9-8-tutorial-by-example-rest-crud-apis-http-get-requests-with-httpclient/
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedVarService {
  private routerInfo: BehaviorSubject<any>;
  constructor(
  ) {
    this.routerInfo = new BehaviorSubject<any>(false);
  }

  getValue(): Observable<any> {
    return this.routerInfo.asObservable();
  }
  setValue(newValue): void {
    this.routerInfo.next(newValue);
  }

}
