<!-- Start Body Content -->
<div class="main main-content" role="main">
    <div id="content" class="content full" style="padding-top: 20px;">
        <div class="container">
            <!-- Vehicle Details -->
            <article class="single-vehicle-details">
                <div class="single-vehicle-title">
                    <span class="badge-premium-listing">Tin Ưu Tiên</span>
                    <h2 class="post-title">{{advert.title}}</h2>
                </div>
                <div class="single-listing-actions">
                    <div class="btn-group pull-right" role="group">
                        <a href="#" data-toggle="modal" data-target="#testdriveModal" class="btn btn-default" title="Book a test drive"><i class="fa fa-calendar"></i> <span>Đăng ký lái thử</span></a>
                        <!-- <a href="#" data-toggle="modal" data-target="#offerModal" class="btn btn-default" title="Make an offer"><i class="fa fa-dollar"></i> <span>Thương lượng</span></a> -->
                        <a href="#" data-toggle="modal" data-target="#sendModal" class="btn btn-default" title="Send to a friend"><i class="fa fa-send"></i> <span>Gửi tin cho bạn bè</span></a>
                        <!-- <a href="#" class="btn btn-default" title="Download Manual"><i class="fa fa-book"></i> <span>Tải hướng dẫn</span></a>s -->
                        <a href="javascript:void(0)" onclick="window.print();" class="btn btn-default" title="Print"><i class="fa fa-print"></i> <span>In</span></a>
                    </div>
                    <div class="btn btn-info price">{{convertPrice2VietNamese(advert.price)}}</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-star-o"></i> Lưu Xe</button>
                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-random"></i> So Sánh</button>
                        <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#infoModal"><i class="fa fa-warning"></i> Báo Vi Phạm</button>
                    </div>
                    <div class="col-md-8">
                        <div class="single-listing-images">
                            <div class="featured-image format-image">
                                <a href="{{advert.photoCover}}" data-rel="prettyPhoto[gallery]" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img [src]="advert.photoCover" style="width: 100%; height: auto;" alt=""></span></a>
                            </div>
                            <div class="additional-images">
                                    <ul class="owl-carousel" data-columns="4" data-pagination="no" data-arrows="yes" data-single-item="no" data-items-desktop="4" data-items-desktop-small="4" data-items-tablet="3" data-items-mobile="3">
                                        <li class="item format-video"> <a href="https://www.youtube.com/watch?v=gJbugye9mfk" data-rel="prettyPhoto[gallery]" class="media-box"><img src="assets/images/selling-car/selling-car1.jpg" alt=""></a></li>
                                        <li class="item format-image"> <a href="assets/images/selling-car/selling-car2.jpg" data-rel="prettyPhoto[gallery]" class="media-box"><img src="assets/images/selling-car/selling-car2.jpg" alt=""></a></li>
                                        <li class="item format-image"> <a href="assets/images/selling-car/selling-car3.jpg" data-rel="prettyPhoto[gallery]" class="media-box"><img src="assets/images/selling-car/selling-car3.jpg" alt=""></a></li>
                                        <li class="item format-image"> <a href="assets/images/selling-car/selling-car0.jpg" data-rel="prettyPhoto[gallery]" class="media-box"><img src="assets/images/selling-car/selling-car0.jpg" alt=""></a></li>
                                        <li class="item format-image"> <a href="assets/images/selling-car/selling-car1.jpg" data-rel="prettyPhoto[gallery]" class="media-box"><img src="assets/images/selling-car/selling-car1.jpg" alt=""></a></li>
                                    </ul>
                            </div>
                        </div>
                        <!-- <div style="padding-top: 15px;">
                            <button type="button" class="btn btn-info btn-sm"><i class="fa fa-star-o"></i> Lưu Xe</button>
                            <button type="button" class="btn btn-info btn-sm"><i class="fa fa-random"></i> So Sánh</button>
                            <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#infoModal"><i class="fa fa-warning"></i> Báo Vi Phạm</button>
                        </div> -->
                        <div style="padding-top: 50px;">
                            <div class="listing-header">
                                <h3>Giới thiệu</h3>
                            </div>
                            <div style="padding-top: 15px;">
                                <p>🚘 Cần Bán Honda Civic 2020 Tự động (bản cao nhất).<br/>❥ Xe mới chạy ODO 12 Vạn chuẩn.<br/>❥ Máy nguyên bản, KO ngập nước.<br/>❥ Nội ngoại thất còn rất mới.<br/>❥ Xe được trang bị màn hình cảm ứng, Đầu DVD, Camera lùi.<br/>❥ Xe đăng ký tháng 8/2020 Chính chủ sử dụng chạy giữ gìn.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="sidebar-widget widget widget">
                            <h4 class="widgettitle">Thông tin người bán</h4>
                            <div class="vehicle-enquiry-foot">
                                <span class="vehicle-enquiry-foot-ico"><i class="fa fa-user"></i></span>
                                <strong>Đinh Quốc Sơn</strong><i class="fa fa-address-book"></i> cá nhân
                            </div>
                            <div class="vehicle-enquiry-foot">
                                <span class="vehicle-enquiry-foot-ico"><i class="fa fa-phone"></i></span>
                                <strong><a (click)="showPhone()" style="text-decoration: none">{{phone}}</a></strong>Xem tin cùng người đăng: <a href="#">Đinh Quốc Sơn</a>
                            </div>
                        </div>
                        <!--Card content-->
                        <!--Google map-->
                        <!-- <div class="card-body card-body-cascade text-center">

                            <div id="map-container-google-8" class="z-depth-1-half map-container-5" style="height: 300px">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4364498471778!2d106.68710031477148!3d10.777845992320614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3abc204ca7%3A0x449c5af2142f88b2!2zMjIwIE5ndXnhu4VuIMSQw6xuaCBDaGnhu4N1LCBQaMaw4budbmcgNiwgUXXhuq1uIDMsIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2sus!4v1585986807305!5m2!1sen!2sus"
                            width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" allowfullscreen></iframe>
                            </div>

                        </div> -->
                        <!--/.Card content-->
                        <div class="sidebar-widget widget">
                            <h4>Thông tin cơ bản</h4>
                            <ul class="list-group">
                                <li class="list-group-item"> <span class="badge">Mẫu xe</span>🚘{{advert.vehicleMakeName}} {{advert.vehicleModelName}}</li>
                                <li class="list-group-item"> <span class="badge">Kiểu dáng</span>🚗 {{advert.vehicleBodyTypeName}}</li>
                                <li class="list-group-item"> <span class="badge">Chỗ ngồi</span>💺 {{advert.vehicleSeatQuantity}}</li>
                                <li class="list-group-item"> <span class="badge">Năm sản xuất</span>📅 {{advert.vehicleDoorQuantity}}</li>
                                <li class="list-group-item"> <span class="badge">Động cơ</span>⚙ {{advert.vehicleEngineName}}</li>
                                <li class="list-group-item"> <span class="badge">Hộp số</span>🕹 {{advert.vehicleTransmissionName}}</li>
                                <li class="list-group-item" *ngIf="!advert.isNew"> <span class="badge">ODO</span>🛣️ {{advert.kmUsed}}km</li>
                                <!-- <li class="list-group-item"> <span class="badge">Tình trạng</span>🔆 Chạy lướt</li> -->
                                <li class="list-group-item"> <span class="badge">Tiêu hao năng lượng</span>⛽ {{advert.vehicleFuelConsumption}}</li>
                                <li class="list-group-item"> <span class="badge">Nhiên liệu</span>⛽ {{advert.vehicleFuelName}}</li>
                            </ul>
                        </div>
                        <!-- Vehicle Enquiry -->
                        <div class="sidebar-widget widget seller-contact-widget">
                            <h4 class="widgettitle">Đặt câu hỏi cho người bán</h4>
                            <div class="vehicle-enquiry-in">
                                <form>
                                    <input type="text" placeholder="Tên của bạn (*)" class="form-control" required>
                                    <input type="email" placeholder="Địa chỉ email (*)" class="form-control" required>
                                    <div class="row">
                                        <div class="col-md-6"><input type="text" placeholder="Số điện thoại (*)" class="form-control" required></div>
                                        <div class="col-md-6"><input type="text" placeholder="Thành phố" class="form-control"></div>
                                    </div>
                                    <textarea name="comments" class="form-control" placeholder="Nội dung (*)" required></textarea>
                                    <label class="checkbox-inline">
                                        <input type="checkbox" id="inlineCheckbox2" value="option2"> Nhớ thông tin của tôi
                                    </label>
                                    <input type="submit" class="btn btn-primary" value="Gửi">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-50"></div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="tabs vehicle-details-tabs">
                            <ul class="nav nav-tabs">
                                <li class="active"> <a data-toggle="tab" href="#vehicle-overview">Tổng Quan</a></li>
                                <li> <a data-toggle="tab" href="#vehicle-specs">Thông Số Kỹ Thuật</a></li>
                                <!--li> <a data-toggle="tab" href="#vehicle-safety">An Toàn</a></li-->
                                <li> <a data-toggle="tab" href="#vehicle-add-features">Tiện Ích</a></li>
                            </ul>
                            <div class="tab-content">
                                <div id="vehicle-overview" class="tab-pane fade in active">
                                    <p class="drop-caps">Honda Civic là một dòng xe cỡ nhỏ được phát triển và sản xuất bởi tập đoàn Honda. Civic, cùng với Honda Accord và Honda Prelude, chính là bao gồm những xe của Honda được bán ở Bắc Mỹ cho đến thập niên 1990, khi số các mẫu được mở rộng. Trải qua nhiều thế hệ, Civic trở nên lớn hơn và được cải tiến hơn, hiện nay Civic nằm giữa Honda Fit và Honda Accord.</p>
                                    <p>Civic được giới thiệu lần đầu vào tháng 7 năm 1972 với một chiếc coupe hai cửa, sau đó là một chiếc hatchback ba cửa vào tháng 9 năm đó. Nó trang bị hệ dẫn động cầu trước. Những mẫu ban đầu của Civic thường có AM radio cơ bản, bộ phận tạo nhiệt, đệm bằng chất dẻo, cần gạt nước hai tốc độ, và vành bánh xe bằng thép. Theo thời gian, nó được cải tiến với nhiều tùy chọn như điều hòa nhiệt độ, khóa điện, cửa sổ điện, ghế bọc da, hộp truyền động sáu cấp. Những thế hệ ban đầu của Civic thường nổi tiếng với vai trò là mẫu xe tiết kiệm nhiên liệu, nhiều phiên bản sau này được biết đến với công suất động cơ và tính thế thao, đặc biệt là Civic Type-R, Civic Si và Civic SiR.</p>
                                </div>
                                <div id="vehicle-specs" class="tab-pane fade">
                                    <div class="accordion" id="toggleArea">
                                        <div class="accordion-group panel">
                                            <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseOne"> Động cơ <i class="fa fa-plus-circle"></i> <i class="fa fa-minus-circle"></i> </a> </div>
                                            <div id="collapseOne" class="accordion-body collapse">
                                                <div class="accordion-inner">
                                                    <table class="table-specifications table table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Loại động cơ</td>
                                                                <td>SKYACTIV-G 2.5 L DOHC 16-valve 4-cylinder engine with VVT</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hộp số</td>
                                                                <td>CVT</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Dung tích xylanh (cc)</td>
                                                                <td>2,488</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tỉ số sức nén</td>
                                                                <td>13.0:1</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Công suất cực đại (mã lực/rpm)</td>
                                                                <td>184 @ 5,700</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Mô men xoắn cực đại (Nm/rpm)</td>
                                                                <td>185 @ 3,250</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tốc độ tối đa (km/h)</td>
                                                                <td>200</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Dung tích bình xăng (lít)</td>
                                                                <td>47</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Loại nhiên liệu</td>
                                                                <td>Xăng không chì có trị số octane 95 trở lên</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống nhiên liệu</td>
                                                                <td>Phun xăng điện tử</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Mức tiêu thụ nhiên liệu trong đô thị/ngoài đô thị (L/100 km)*<br />Số sàn<br />Tự động</td>
                                                                <td>8.1/5.3<br />7.6/5.1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-group panel">
                                            <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseTwo"> Ngoại thất <i class="fa fa-plus-circle"></i> <i class="fa fa-minus-circle"></i> </a> </div>
                                            <div id="collapseTwo" class="accordion-body collapse">
                                                <div class="accordion-inner">
                                                    <table class="table-specifications table table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Chiều dài cơ sở/Chiều dài tổng thể (mm)</td>
                                                                <td>2,830/4,895</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Chiều rộng tổng thể (mm)</td>
                                                                <td>1,840</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Chiều cao tổng thể (mm)</td>
                                                                <td>1,450</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Mâm xe (inch)</td>
                                                                <td>19</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Kích thước lốp trước - sau</td>
                                                                <td>205/60R16 - 205/60R16</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Đèn pha - Đèn sương mù</td>
                                                                <td>Full LED</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Đèn chạy ban ngày</td>
                                                                <td>DRLs</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Đèn hậu</td>
                                                                <td>LED</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Bán kính vòng quay tối thiểu (m)</td>
                                                                <td>5.3</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Gương chiếu hậu</td>
                                                                <td>Tích hợp đèn báo rẽ, gập điện</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tay nắm cửa</td>
                                                                <td>Mạ chrome</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Khoảng sáng gầm xe (mm)</td>
                                                                <td>133</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Trọng lượng không tải (kg)<br /> Số sàn<br />Tự động</td>
                                                                <td>1,442<br />1,465</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-group panel">
                                            <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseThird"> Nội thất <i class="fa fa-plus-circle"></i> <i class="fa fa-minus-circle"></i> </a> </div>
                                            <div id="collapseThird" class="accordion-body collapse">
                                                <div class="accordion-inner">
                                                    <table class="table-specifications table table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Bảng đồng hồ trung tâm</td>
                                                                <td>Analog</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Số chỗ ngồi</td>
                                                                <td>5</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Chất liệu ghế</td>
                                                                <td>Da</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ghế lái chỉnh điện</td>
                                                                <td>8 hướng</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hàng ghế 2</td>
                                                                <td>Gập 60:40</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Cửa sổ trời</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Chất liệu tay lái</td>
                                                                <td>Da</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tay lái điều chỉnh 4 hướng</td>
                                                                <td>Có</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-group panel">
                                            <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseForth"> Hỗ trợ vận hành <i class="fa fa-plus-circle"></i> <i class="fa fa-minus-circle"></i> </a> </div>
                                            <div id="collapseForth" class="accordion-body collapse">
                                                <div class="accordion-inner">
                                                    <table class="table-specifications table table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Trợ lực lái điện</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Van bướm ga điện tử</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ga tự động (Cruise control)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Chế độ tiết kiệm nhiên liệu</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hướng dẫn lái tiết kiệm nhiên liệu</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Chế độ lái thể thao</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Khởi động bằng nút bấm</td>
                                                                <td>Có</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="accordion-group panel">
                                            <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseFifth"> An toàn <i class="fa fa-plus-circle"></i> <i class="fa fa-minus-circle"></i> </a> </div>
                                            <div id="collapseFifth" class="accordion-body collapse">
                                                <div class="accordion-inner">
                                                    <table class="table-specifications table table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Phanh trước</td>
                                                                <td>Đĩa tản nhiệt</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phanh sau</td>
                                                                <td>Tang trống</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống đánh lái chủ động (AHA)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống cân bằng điện tử (VSA)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống kiểm soát lực kéo (TCS)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống chống cứng bó phanh (ABS)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống phân phối lực phanh điện tử (EBD)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hỗ trợ lực phanh khẩn cấp (BA)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hệ thống khởi hành ngang dốc (HSA)</td>
                                                                <td>Có</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Camera lùi</td>
                                                                <td>3 góc quay</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Khóa cửa tự động</td>
                                                                <td>Không</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Túi khí</td>
                                                                <td>2</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Toggle --> 
                                </div>
                                <div id="vehicle-add-features" class="tab-pane fade">
                                    <ul class="add-features-list">
                                        <li>Phanh tay điện tử</li>
                                        <li>Chế độ giữ phanh tự động</li>
                                        <li>Chìa khóa thông minh tích hợp mở cốp</li>
                                        <li>Đóng mở cửa bằng cảm biến</li>
                                        <li>Kết nối WIFI, HDMI, Bluetooth, USB</li>
                                        <li>Đài AM/FM</li>
                                        <li>Hệ thống 8 loa stereo</li>
                                        <li>Điều hòa điện tử</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-50"></div>
                        <!-- Recently Listed Vehicles -->
                        <section class="listing-block recent-vehicles">
                            <div class="listing-header">
                                <h3>Xe Cùng Khoảng Giá</h3>
                            </div>
                            <div class="listing-container">
                                <div class="carousel-wrapper">
                                    <div class="row">
                                        <ul class="owl-carousel carousel-fw" id="vehicle-slider" data-columns="3" data-autoplay="" data-pagination="yes" data-arrows="no" data-single-item="no" data-items-desktop="3" data-items-desktop-small="3" data-items-tablet="2" data-items-mobile="1">
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-default vehicle-age">2014</span>
                                                    <span class="label label-success premium-listing">Tin Ưu Tiên</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Người bán cá nhân">Nguyễn Thế Tân</abbr></span>
                                                    <a href="#" title="View all Sedans" class="vehicle-body-type"><img src="assets/images/body-types/sedan.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">700 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-primary vehicle-age">Xe Mới</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Đại lý">Salon XXX</abbr></span>
                                                    <a href="#" title="View all SUVs" class="vehicle-body-type"><img src="assets/images/body-types/suv.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">800 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-default vehicle-age">2013</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Người bán cá nhân">Danh Lê</abbr></span>
                                                    <a href="#" title="View all convertibles" class="vehicle-body-type"><img src="assets/images/body-types/convertible.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">1 tỉ 200 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-default vehicle-age">2014</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Đại lý">Salon Autostars</abbr></span>
                                                    <a href="#" title="View all coupes" class="vehicle-body-type"><img src="assets/images/body-types/coupe.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">760 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-default vehicle-age">2014</span>
                                                    <span class="label label-success premium-listing">Tin Ưu Tiên</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Người bán cá nhân">Đinh Quốc Sơn</abbr></span>
                                                    <a href="#" title="View all Sedans" class="vehicle-body-type"><img src="assets/images/body-types/sedan.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">350 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-primary vehicle-age">Xe Mới</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Đại lý">Salon Number1</abbr></span>
                                                    <a href="#" title="View all SUVs" class="vehicle-body-type"><img src="assets/images/body-types/suv.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">500 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-default vehicle-age">2013</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Người bán cá nhân">Hoàng Minh Châu</abbr></span>
                                                    <a href="#" title="View all convertibles" class="vehicle-body-type"><img src="assets/images/body-types/convertible.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">650 triệu</span>
                                                </div>
                                            </li>
                                            <li class="item">
                                                <div class="vehicle-block format-standard">
                                                    <a href="#" class="media-box"><span style="text-align: center; display: block; background-color: #2c9c80;"><img src="assets/images/model/honda/civic.png" alt="" style="width: 86%; height: auto;"></span></a>
                                                    <span class="label label-default vehicle-age">2014</span>
                                                    <h5 class="vehicle-title"><a href="#">Mercedes-benz SL 300</a></h5>
                                                    <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="Đại lý">Salon ABC</abbr></span>
                                                    <a href="#" title="View all coupes" class="vehicle-body-type"><img src="assets/images/body-types/coupe.png" width="30" alt=""></a>
                                                    <span class="vehicle-cost">2 tỉ</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <!-- Vehicle Details Sidebar -->
                    <div class="col-md-4 vehicle-details-sidebar sidebar">
                    
                        <!-- Vehicle Enquiry -->
                        <!-- <div class="sidebar-widget widget seller-contact-widget">
                            <h4 class="widgettitle">Đặt câu hỏi cho người bán</h4>
                            <div class="vehicle-enquiry-in">
                                <form>
                                    <input type="text" placeholder="Tên của bạn (*)" class="form-control" required>
                                    <input type="email" placeholder="Địa chỉ email (*)" class="form-control" required>
                                    <div class="row">
                                        <div class="col-md-6"><input type="text" placeholder="Số điện thoại (*)" class="form-control" required></div>
                                        <div class="col-md-6"><input type="text" placeholder="Thành phố" class="form-control"></div>
                                    </div>
                                    <textarea name="comments" class="form-control" placeholder="Nội dung (*)" required></textarea>
                                    <label class="checkbox-inline">
                                        <input type="checkbox" id="inlineCheckbox2" value="option2"> Nhớ thông tin của tôi
                                    </label>
                                    <input type="submit" class="btn btn-primary" value="Gửi">
                                </form>
                            </div>
                        </div> -->
                        
                        <!-- Financing Calculator -->
                        <div class="sidebar-widget widget calculator-widget">
                            <h4>Phí lăn bánh</h4>
                            <form>
                                <div class="loan-calculations">
                                    <input type="text" class="form-control" placeholder="Giá xe">
                                    <select name="Max Mileage" class="form-control selectpicker">
                                        <option selected disabled>Nơi mua</option>
                                        <option>Hà Nội</option>
                                        <option>TP. Hồ Chí Minh</option>
                                        <option>Quảng Ninh, Cao Bằng, Lào Cai</option>
                                        <option>Hải Phòng, Lạng Sơn, Sơn La, Cần Thơ</option>
                                        <option>Hà Tĩnh</option>
                                        <option>Nơi Khác</option>
                                    </select>
                                    <div class="row">
                                        <div class="col-md-6" style="padding-left: 35px; padding-right: 0px; padding-bottom: 15px;">
                                            <div><span style="color: #37bc9b;"><i class="fa fa-check"></i></span> Phí trước bạ (2%)</div>
                                            <div><span style="color: #37bc9b;"><i class="fa fa-check"></i></span> Phí đăng kiểm</div>
                                            <div><span style="color: #37bc9b;"><i class="fa fa-check"></i></span> Phí đường bộ</div>
                                            <div><span style="color: #37bc9b;"><i class="fa fa-check"></i></span> Bảo hiểm dân sự</div>
                                            <div><span style="color: #37bc9b;"><i class="fa fa-check"></i></span> Phí sang tên</div>
                                        </div>
                                        <div class="col-md-6" style="text-align: right; padding-right: 30px; padding-bottom: 15px">
                                            <div><strong>7,800,000</strong></div>
                                            <div><strong>340,000</strong></div>
                                            <div><strong>1,560,000</strong></div>
                                            <div><strong>530,700</strong></div>
                                            <div><strong>150,000</strong></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="calculations-result">
                                    <span class="meta-data">Ước lượng tổng số tiền để mua chiếc xe này là</span>
                                    <span class="loan-amount">1 tỉ 500 triệu</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </article>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<!-- End Body Content -->