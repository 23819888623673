<!-- <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-4">{{ vm.header }}</h1>
</div>

<div class="mx-auto col-lg-3 text-center">
    <button ng-click="vm.button = 1" class="btn btn-secondary m-1" ng-class="{'btn-dark': vm.button === 1}">Button 1</button>
    <button ng-click="vm.button = 2" class="btn btn-secondary m-1" ng-class="{'btn-dark': vm.button === 2}">Button 2</button>
</div>
<div class="mx-auto col-lg-3">
    <h3>Button pressed: {{ vm.button }}</h3>
</div> -->
<div [hidden]="isLoad==false" class="hero-area" style="margin-top: 0px;">
    <!-- START REVOLUTION SLIDER 4.5.0 fullwidth mode -->
    <link href='http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800' rel='stylesheet' type='text/css'>
    <link href='http://fonts.googleapis.com/css?family=Raleway:100,200,300,700,800,900' rel='stylesheet' type='text/css'>
    <div class="tp-banner-container">
        <div class="tp-banner">
            <ul>	<!-- SLIDE  -->
                <li data-transition="slidevertical" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide 1">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/home/slider/slide0.jpg"  alt="fullslide1"  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
                    <!-- LAYERS -->

                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption light_heavy_70_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="70"
                        data-y="center" data-voffset="-70"
                        data-speed="600"
                        data-start="800"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                    ><span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman; color: #37bc9b;">ME</span><span>OTO</span>
                    </div>
            
                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption light_medium_30_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="220"
                        data-y="center" data-voffset="20"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        ><span style="font-size:20pt;font-family:Tahoma, Verdana, Bookman">Sàn giao dịch xe ôtô uy tín - nhanh chóng <br/>- an toàn.</span>
                    </div>
            
                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption bignumbers_white lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="-400"
                        data-y="center" data-voffset="-75"
                        data-speed="800"
                        data-start="600"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">01
                    </div>
                </li>
                <!-- SLIDE  -->
                <li data-transition="slidevertical" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide 2">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/home/slider/slide1.jpg"  alt="fullslide2"  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
                    <!-- LAYERS -->
            
                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption light_heavy_70_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="190"
                        data-y="center" data-voffset="-70"
                        data-speed="600"
                        data-start="800"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 2; max-width: auto; max-height: auto; white-space: nowrap;"><span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman; color: #37bc9b;">NHIỀU</span><span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman"> LỰA CHỌN</span>
                    </div>
            
                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption light_medium_30_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="120"
                        data-y="center" data-voffset="20"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 3; max-width: auto; max-height: auto; white-space: nowrap;"><span style="font-size:20pt;font-family:Tahoma, Verdana, Bookman">Dễ dàng tìm một chiếc xe phù hợp<br/>với khả năng tài chính.</span>
                    </div>
            
                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption bignumbers_white lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="-400"
                        data-y="center" data-voffset="-75"
                        data-speed="800"
                        data-start="600"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">02
                    </div>
                </li>
                <!-- SLIDE  -->
                <li data-transition="slidevertical" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide 3">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/home/slider/slide2.jpg"  alt="fullslide6"  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
                    <!-- LAYERS -->
            
                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption light_heavy_70_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="165"
                        data-y="center" data-voffset="-70"
                        data-speed="600"
                        data-start="800"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 2; max-width: auto; max-height: auto; white-space: nowrap;"><span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman">TRẢI NGHIỆM</span><span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman; color: #37bc9b;"> MỚI</span>
                    </div>
            
                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption light_medium_30_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="130"
                        data-y="center" data-voffset="20"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 3; max-width: auto; max-height: auto; white-space: nowrap;"><span style="font-size:20pt;font-family:Tahoma, Verdana, Bookman">Đăng bán xe chỉ với vài thao tác đơn giản,<br/>cho cả cá nhân và salon ôtô.</span>
                    </div>
            
                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption bignumbers_white lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="-400"
                        data-y="center" data-voffset="-75"
                        data-speed="800"
                        data-start="600"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">03
                    </div>
                </li>
                <!-- SLIDE  -->
                <li data-transition="slidevertical" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide 4">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/home/slider/slide3.jpg"  alt="fullslide6"  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
                    <!-- LAYERS -->
            
                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption light_heavy_70_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="215"
                        data-y="center" data-voffset="-70"
                        data-speed="600"
                        data-start="800"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 2; max-width: auto; max-height: auto; white-space: nowrap;"><span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman">ĐỐI TÁC</span> <span style="font-size:50pt;font-family:Tahoma, Verdana, Bookman; color: #37bc9b;">UY TÍN</span>
                    </div>
            
                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption light_medium_30_shadowed lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="215"
                        data-y="center" data-voffset="20"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 3; max-width: auto; max-height: auto; white-space: nowrap;"><span style="font-size:20pt;font-family:Tahoma, Verdana, Bookman">Hệ thống Salon được xác minh kỹ lưỡng,<br/>chính xác.</span>
                    </div>
            
                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption bignumbers_white lfb ltt tp-resizeme"
                        data-x="center" data-hoffset="-400"
                        data-y="center" data-voffset="-75"
                        data-speed="800"
                        data-start="600"
                        data-easing="Power4.easeOut"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.1"
                        data-endspeed="500"
                        data-endeasing="Power4.easeIn"
                        style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">04
                    </div>
                </li>
            </ul>	
            <div class="tp-bannertimer"></div>	
        </div>
    </div>
</div>
<!-- Utiity Bar -->
<div class="utility-bar">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-8">
                <div class="toggle-make">
                    <a href="#"><i class="fa fa-navicon"></i></a>
                    <span>Tìm xe theo kiểu dáng</span>
                </div>
            </div>
            <div class="col-md-8 col-sm-6 col-xs-4">
                <ul class="utility-icons social-icons social-icons-colored">
                    <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li class="googleplus"><a href="#"><i class="fa fa-google-plus"></i></a></li>
                    <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                </ul>
            </div>
          </div>
      </div>
    <div class="by-type-options">
        <div class="container">
               <div class="row">
                  <ul class="owl-carousel carousel-alt" data-columns="6" data-autoplay="" data-pagination="no" data-arrows="yes" data-single-item="no" data-items-desktop="6" data-items-desktop-small="4" data-items-mobile="3" data-items-tablet="4">
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/sedan.png" alt=""> <span>Sedan</span></a></li>
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/hatchback.png" alt=""> <span>Hatchback</span></a></li>
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/suv.png" alt=""> <span>SUV</span></a></li>
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/pickup.png" alt=""> <span>Pickup</span></a></li>
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/coupe.png" alt=""> <span>Coupe</span></a></li>
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/convertible.png" alt=""> <span>Convertible</span></a></li>
                    <li class="item"> <a href="results-list.html"><img src="assets/images/body-types/minivan.png" alt=""> <span>Minivan</span></a></li>
                  </ul>
               </div>
        </div>
    </div>
</div>
<!-- Search Form -->
<div class="search-form">
    <div class="search-form-inner">
        <form class="container">
            <h3>Tìm xe theo tiêu chí của bạn</h3>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Từ Khóa</label>
                            <input [(ngModel)]="vm.search.text" [ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="Ví dụ: toyota vios 2020">
                        </div>
                        <div class="col-md-6">
                            <label>Vị Trí Xe</label>
                            <select [(ngModel)]="vm.search.placeId" [ngModelOptions]="{standalone: true}" name="Exterior Color" class="form-control selectpicker-bk">
                                <option value="-">Tất cả Thành Phố</option>
                                <option value="tp-ho-chi-minh">Hồ Chí Minh</option>
                                <option value="ha-noi">Hà Nội</option>
                                <option value="hai-phong">Hải Phòng</option>
                                <option value="nghe-an">Nghệ An</option>
                                <option value="da-nang">Đà Nẵng</option>
                                <option value="dong-thap">Đồng Tháp</option>
                             </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Thương Hiệu</label>
                            <select [(ngModel)]="vm.search.vehicleMakeId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleMake(vm.search.vehicleMakeId)" class="form-control selectpicker-bk-bk">
                                <option value="-">Tất cả thương hiệu</option>
                                <option *ngFor="let entity of vm.vehicleMakes; let i = index" [value]="entity.id">
                                    {{entity.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label>Mẫu Xe</label>
                            <select [(ngModel)]="vm.search.vehicleModelId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleModel(vm.search.vehicleModelId)" [disabled]="vm.search.vehicleMakeId == '-'" class="form-control selectpicker-bk">
                                <option value="-">Tất cả mẫu xe</option>
                                <option *ngFor="let entity of vm.vehicleModels; let i = index" [value]="entity?.id">
                                    {{entity?.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Kiểu Dáng</label>
                            <select [(ngModel)]="vm.search.vehicleBodyTypeId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleBodyType()" class="form-control selectpicker-bk">
                                <option value="-">Tất cả kiểu xe</option>
                                <option *ngFor="let entity of vm.vehicleBodyTypes; let i = index" [value]="entity.id">
                                    {{entity.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Từ Năm</label>
                            <select [(ngModel)]="vm.search.vehicleYearMin" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleYearMin()" class="form-control selectpicker-bk">
                                <option value="-">Chọn năm&nbsp;</option>
                                <option *ngFor="let entity of vm.vehicleYearMins; let i = index" [value]="entity">
                                    {{entity}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Đến Năm</label>
                            <select [(ngModel)]="vm.search.vehicleYearMax" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleYearMax()" class="form-control selectpicker-bk">
                                <option value="-">Chọn năm</option>
                                <option *ngFor="let entity of vm.vehicleYearMaxs; let i = index" [value]="entity">
                                    {{entity}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <label class="checkbox-inline">
                                <input [(ngModel)]="vm.search.onlyShowNewVehicle" [ngModelOptions]="{standalone: true}" type="checkbox" id="inlineCheckbox1"> Chỉ hiện xe mới
                            </label>
                            <label class="checkbox-inline">
                                <input type="checkbox" id="inlineCheckbox2" value="option2"> Xe có chứng nhận đăng kiểm
                            </label>
                        </div>
                    </div> -->
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Hộp Số</label>
                            <select [(ngModel)]="vm.search.vehicleTransmissionId" [ngModelOptions]="{standalone: true}" class="form-control selectpicker-bk">
                                <option value="-">Tất cả</option>
                                <option *ngFor="let entity of vm.vehicleTransmissions; let i = index" [value]="entity.id">
                                    {{entity.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label>Xuất Xứ</label>
                            <select [(ngModel)]="vm.search.vehicleSourceId" [ngModelOptions]="{standalone: true}" class="form-control selectpicker-bk">
                                <option value="-">Tất cả</option>
                                <option *ngFor="let entity of vm.vehicleSources; let i = index" [value]="entity.id">
                                    {{entity.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Khoảng Giá:&nbsp;</label>
                            <strong>{{convertPrice2VietNameseRange(vm.search.minPrice)}}</strong> <strong>  -  </strong> <strong>{{convertPrice2VietNameseRange(vm.search.maxPrice)}}{{vm.search.maxPrice == vm.search.maxPriceCfg ? '+': ''}}</strong>
                            <ng5-slider [(value)]="vm.search.minPrice" [(highValue)]="vm.search.maxPrice" (userChangeEnd)="onUserChangePriceEnd($event)" [options]="priceOptions"></ng5-slider>
                        </div>
                        <div class="col-md-6">
                            <label>Số Km Tối Đa:&nbsp;</label>
                            <strong></strong> <strong>{{vm.search.maxKmUsed | number}} Km{{vm.search.maxKmUsed == vm.search.maxKmUsedCfg ? '+': ''}}</strong>
                            <ng5-slider [(value)]="vm.search.maxKmUsed" [options]="kmUsedoptions"></ng5-slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <input type="button" class="btn btn-block btn-info btn-lg btn-search-home" value="TÌM KIẾM" (click)="onSearchVehice()">
                        </div>
                    </div>
                    <label class="checkbox-inline pull-right">
                        <input [(ngModel)]="vm.search.onlyShowNewVehicle" [ngModelOptions]="{standalone: true}" (change)="onSelectShowNewVehicleOnly()" type="checkbox" id="inlineCheckbox1"> Chỉ hiện xe mới
                    </label>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Start Body Content -->
  <div class="main" role="main">
    <div id="content" class="content full padding-b0">
        <div class="container">
            <!-- Recently Listed Vehicles -->
            <section *ngIf="vm.adverts" class="listing-block recent-vehicles">
                <div class="listing-header">
                    <h3>Xe Mới Đăng Bán</h3>
                </div>
                <div class="listing-container">
                    <div class="carousel-wrapper">
                        <div class="row">
                            <ul class="owl-carousel carousel-fw" id="vehicle-slider" data-columns="4" data-autoplay="" data-pagination="yes" data-arrows="no" data-single-item="no" data-items-desktop="4" data-items-desktop-small="3" data-items-tablet="2" data-items-mobile="1">
                                <!-- repeat recently adverting -->
                                <li *ngFor="let advert of vm.adverts"class="item">
                                    <div class="vehicle-block format-standard">
                                        <a routerLink="['', advert.slug]" class="media-box"><img [src]="advert.photoCover" alt=""></a>
                                        <div class="vehicle-block-content">
                                            <span class="label label-default vehicle-age">{{advert.vehicleYearRelease}}</span>
                                            <span class="label label-success premium-listing">Tin VIP</span>
                                            <h5 class="vehicle-title"><a routerLink="['', advert.slug]">{{advert.title}}</a></h5>
                                            <span class="vehicle-meta">Bán bởi <abbr class="user-type" title="{{advert.isIndividual ? 'Người bán cá nhân' : 'Bán bởi Salon ôtô'}}">{{advert.lastName}}</abbr></span>
                                            <a  [routerLink]="['/danh-sach-xe-oto', 'kieu-dang-' + advert.vehicleBodyTypeId ]" title="Xem tất cả xe {{advert.vehicleBodyTypeId}}" class="vehicle-body-type"><img src="assets/images/body-types/{{advert.vehicleBodyTypeId}}.png" width="30" alt=""></a>
                                            <span class="vehicle-cost">{{convertPrice2VietNamese(advert.price)}}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div class="spacer-60"></div>
             <div class="row">
                <!-- Latest News -->
                <div class="col-md-8 col-sm-6">
                    <section class="listing-block latest-news">
                        <div class="listing-header">
                            <a href="blog.html" class="btn btn-sm btn-default pull-right">Xem tất cả</a>
                            <h3>Tin tức ôtô mới nhất</h3>
                        </div>
                        <div class="listing-container">
                            <div class="carousel-wrapper">
                                <div class="row">
                                    <ul class="owl-carousel" id="news-slider" data-columns="2" data-autoplay="" data-pagination="yes" data-arrows="yes" data-single-item="no" data-items-desktop="2" data-items-desktop-small="1" data-items-tablet="2" data-items-mobile="1">
                                        <li *ngFor="let blog of vm.blogs" class="item">
                                            <div class="post-block format-standard">
                                                <a [routerLink]="['/blog', blog.slug]" class="media-box post-image"><img [src]="blog.featureImage" alt="meoto.vn"></a>
                                                <div class="post-actions">
                                                    <div class="post-date">{{_convertTimestamp(blog.createAt)}}</div>
                                                    <div class="comment-count"><a [routerLink]="['/blog', blog.slug]"><i class="icon-dialogue-text"></i> 12</a></div>
                                                </div>
                                                <h3 class="post-title"><a [routerLink]="['/blog', blog.slug]">{{blog.title}}</a></h3>
                                                <div class="post-content">
                                                    <p>{{blog.customExcerpt}}....</p>
                                                </div>
                                                <div class="post-meta">
                                                    Chuyên mục: <a href="#" *ngFor="let tag of blog.tagNames; let i = index">{{" " + tag}},</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                      </section>
                    <div class="spacer-40"></div>
                    <section class="listing-block">
                        <div class="listing-header">
                            <a href="blog.html" class="btn btn-sm btn-default pull-right">Xem tất cả</a>
                            <h3>Salon nổi bật</h3>
                        </div>
                        <div class="listing-container">
                            <div class="carousel-wrapper">
                                <div class="row">
                                    <ul class="owl-carousel carousel-fw" id="vehicle-slider" data-columns="2" data-autoplay="" data-pagination="no" data-arrows="yes" data-single-item="no" data-items-desktop="2" data-items-desktop-small="1" data-items-tablet="1" data-items-mobile="1">
                                        <li class="item">
                                            <div class="dealer-block">
                                                <div class="dealer-block-inner" style="background-image:url(assets/images/content/vip-salon-background.jpg);">
                                                    <div class="dealer-block-cont">
                                                        <div class="dealer-block-info">
                                                            <span class="label label-default">Bán 30 xe tháng này</span>
                                                            <span class="dealer-avatar"><img src="assets/images/salon/salon-0-avatar.jpg" alt=""></span>
                                                            <h5><a href="dealer-prosite.html">Salon Anycar</a></h5>
                                                            <span class="meta-data">Tất cả vì khách hàng</span>
                                                        </div>
                                                        <div class="dealer-block-text">
                                                            <p>Đại lý phân phối xe Toyota miền Nam. Với hơn 25 năm trong lĩnh vực mua bán xe đã qua sử dụng.</p>
                                                            <div class="dealer-block-add">
                                                                <span>Thành lập từ <strong>tháng 5, 2012</strong></span>
                                                                <span>Đang bán <strong>122 xe</strong></span>
                                                            </div>
                                                        </div>
                                                        <div class="text-align-center"><a href="dealer-prosite.html" class="btn btn-default">Chi tiết Salon</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="item">
                                            <div class="dealer-block">
                                                <div class="dealer-block-inner" style="background-image:url(assets/images/content/vip-salon-background.jpg);">
                                                    <div class="dealer-block-cont">
                                                        <div class="dealer-block-info">
                                                            <span class="label label-default">Bán 14 xe tháng này</span>
                                                            <span class="dealer-avatar"><img src="assets/images/salon/salon-1-avatar.jpg" alt=""></span>
                                                            <h5><a href="dealer-prosite.html">Salon Hàn Tín</a></h5>
                                                            <span class="meta-data">Chữ tín là trên hết</span>
                                                        </div>
                                                        <div class="dealer-block-text">
                                                            <p>Đại lý phân phối xe Honda miền Bắc. Với hơn 25 năm trong lĩnh vực mua bán xe đã qua sử dụng.</p>
                                                            <div class="dealer-block-add">
                                                                <span>Thành lập từ <strong>tháng 3, 2014</strong></span>
                                                                <span>Đang bán <strong>228 xe</strong></span>
                                                            </div>
                                                        </div>
                                                        <div class="text-align-center"><a href="dealer-prosite.html" class="btn btn-default">Chi tiết Salon</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="item">
                                            <div class="dealer-block">
                                                <div class="dealer-block-inner" style="background-image:url(assets/images/content/vip-salon-background.jpg);">
                                                    <div class="dealer-block-cont">
                                                        <div class="dealer-block-info">
                                                            <span class="label label-default">Bán 25 xe tháng này</span>
                                                            <span class="dealer-avatar"><img src="assets/images/salon/salon-2-avatar.jpg" alt=""></span>
                                                            <h5><a href="dealer-prosite.html">Salon Sơn Tường</a></h5>
                                                            <span class="meta-data">Thích thì mua</span>
                                                        </div>
                                                        <div class="dealer-block-text">
                                                            <p>Đại lý phân phối xe Suzuki miền Trung. Với hơn 25 năm trong lĩnh vực mua bán xe đã qua sử dụng.</p>
                                                            <div class="dealer-block-add">
                                                                <span>Thành lập từ <strong>tháng 5, 2011</strong></span>
                                                                <span>Đang bán <strong>87 xe</strong></span>
                                                            </div>
                                                        </div>
                                                        <div class="text-align-center"><a href="dealer-prosite.html" class="btn btn-default">Chi tiết Salon</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="item">
                                            <div class="dealer-block">
                                                <div class="dealer-block-inner" style="background-image:url(assets/images/content/vip-salon-background.jpg);">
                                                    <div class="dealer-block-cont">
                                                        <div class="dealer-block-info">
                                                            <span class="label label-default">Bán 15 xe tháng này</span>
                                                            <span class="dealer-avatar"><img src="assets/images/salon/salon-3-avatar.jpg" alt=""></span>
                                                            <h5><a href="dealer-prosite.html">Salon Sao Đỏ</a></h5>
                                                            <span class="meta-data">Ôtô trong tầm tay</span>
                                                        </div>
                                                        <div class="dealer-block-text">
                                                            <p>Đại lý phân phối xe Cadillac miền Tây. Với hơn 25 năm trong lĩnh vực mua bán xe đã qua sử dụng.</p>
                                                            <div class="dealer-block-add">
                                                                <span>Thành lập từ <strong>tháng 9, 2010</strong></span>
                                                                <span>Đang bán <strong>99 xe</strong></span>
                                                            </div>
                                                        </div>
                                                        <div class="text-align-center"><a href="dealer-prosite.html" class="btn btn-default">Chi tiết Salon</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <!-- Latest Reviews -->
                
                <div class="col-md-4 col-sm-6 sidebar">
                    <section class="listing-block latest-reviews">
                        
                        <div class="listing-header">
                            <h3>Top xe tháng 3/2020</h3>
                        </div>
                        <div style="position: relative; width: 100%; height: 100px;overflow: hidden;">
                            <div style="position: relative; border: white; border-style:solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver; ">
                                    <img src="assets/images/model/toyota/altis.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">1</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Altis</span>
                                    </div>
                                </div>
                            </div>
                            <div style="position: relative; border: white; border-style: solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver;">
                                    <img src="assets/images/model/toyota/alphard.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">2</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Alphard</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative; width: 100%; height: 100px;overflow: hidden;">
                            <div style="position: relative; border: white; border-style:solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver; ">
                                    <img src="assets/images/model/toyota/fortuner.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">3</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Fortuner</span>
                                    </div>
                                </div>
                            </div>
                            <div style="position: relative; border: white; border-style: solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver;">
                                    <img src="assets/images/model/toyota/vios.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">4</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Vios</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative; width: 100%; height: 100px;overflow: hidden;">
                            <div style="position: relative; border: white; border-style:solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver; ">
                                    <img src="assets/images/model/toyota/avalon.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">5</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Avalon</span>
                                    </div>
                                </div>
                            </div>
                            <div style="position: relative; border: white; border-style: solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver;">
                                    <img src="assets/images/model/toyota/camry.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">6</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Camry</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative; width: 100%; height: 100px;overflow: hidden;">
                            <div style="position: relative; border: white; border-style:solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver; ">
                                    <img src="assets/images/model/toyota/previa.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">7</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Previa</span>
                                    </div>
                                </div>
                            </div>
                            <div style="position: relative; border: white; border-style: solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver;">
                                    <img src="assets/images/model/toyota/yaris.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">8</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Yaris</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative; width: 100%; height: 100px;overflow: hidden;">
                            <div style="position: relative; border: white; border-style:solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver; ">
                                    <img src="assets/images/model/toyota/wigo.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">9</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Wigo</span>
                                    </div>
                                </div>
                            </div>
                            <div style="position: relative; border: white; border-style: solid;width: 50%; height: 100%;float: left;">
                                <div class="post-block post-review-block" style="width:100%;background-color:silver;">
                                    <img src="assets/images/model/toyota/rav4.png" alt="" style="width:100%;height:100%;">
                                    <div style="position: absolute;bottom: 0px; left: 5px;">
                                        <span style="font-size: 30px; color:#37bc9b; font-weight: bold;text-shadow: 1px 1px #252525;">10</span>
                                        <span style="font-size: 15px; color:#ffffff; font-weight: bold;text-shadow: 1px 1px #252525;">Toyota Rav4</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </section>
                    <div class="spacer-40"></div>
                    <!-- Connect with us -->
                    <section class="connect-with-us widget-block">
                        <h4><i class="fa fa-rss"></i> Đăng ký nhận tin mới</h4>
                        <form role="form">
                            <label for="NewsletterInput" class="sr-only">Email</label>
                            <input type="email" class="form-control" id="NewsletterInput" placeholder="Điền email của bạn">
                            <button type="submit" class="btn btn-sm btn-primary">Đăng ký</button>
                            <span class="meta-data">Chúng tôi chỉ gửi những tin nổi bật</span>
                        </form>
                        <hr>
                        <h4><i class="fa fa-facebook"></i> Facebook Fanpage</h4>
                        <div class="fb-page" data-href="https://www.facebook.com/dicominteractive/" data-tabs="timeline" data-width="300" data-height="300" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/dicominteractive/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/dicominteractive/">DICOM interactive</a></blockquote></div>
                    </section>
                </div>
              </div>
           </div>
        <div class="spacer-50"></div>
        <div class="lgray-bg make-slider">
            <div class="container">
                <!-- Search by make -->
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <br/><br/>
                        <h4>Tìm xe theo thương hiệu</h4>
                        <a href="all-makes.html" class="btn btn-default btn-lg">Xem tất cả thương hiệu</a>
                    </div>
                    <div class="col-md-9 col-sm-8">
                        <div class="row">
                            <ul class="owl-carousel" id="make-carousel" data-columns="5" data-autoplay="6000" data-pagination="no" data-arrows="no" data-single-item="no" data-items-desktop="5" data-items-desktop-small="4" data-items-tablet="3" data-items-mobile="3">
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-cadillac']"><img src="assets/images/make/cadillac.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-ferrari']"><img src="assets/images/make/ferrari.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-jaguar']"><img src="assets/images/make/jaguar.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-lamborghini']"><img src="assets/images/make/lamborghini.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-maserati']"><img src="assets/images/make/maserati.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-mini']"><img src="assets/images/make/mini.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-porsche']"><img src="assets/images/make/porsche.png" alt=""></a></li>
                                <li class="item"> <a [routerLink]="['/danh-sach-xe-oto', 'xe-subaru']"><img src="assets/images/make/subaru.png" alt=""></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
