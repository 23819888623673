// core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from  '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// 3rd
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';

//create
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdvertComponent } from './advert/advert.component';
import { HomeComponent } from './home/home.component';
import { AdvertListComponent } from './advert-list/advert-list.component';
import { JoinusComponent } from './joinus/joinus.component';
import { BlogComponent } from './blog/blog.component';
import { SinglePostComponent } from './blog/single-post.component';
import { ContactComponent } from './contact/contact.component';
import { AdvertDetailComponent } from './advert-detail/advert-detail.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { AdvertManagementComponent } from './advert-management/advert-management.component';
import { AllMakeComponent } from './all-make/all-make.component';
import { DealerListComponent } from './dealer-list/dealer-list.component';

@NgModule({
  declarations: [
    AppComponent,
    AdvertComponent,
    HomeComponent,
    AdvertListComponent,
    JoinusComponent,
    BlogComponent,
    SinglePostComponent,
    ContactComponent,
    AdvertDetailComponent,
    AdvertManagementComponent,
    AllMakeComponent,
    DealerListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
