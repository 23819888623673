import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Title } from "@angular/platform-browser";


import { UtilsService } from '../services/utils.service';
import { AdvertService } from '../services/advert.service';

@Component({
  selector: 'app-advert-detail',
  templateUrl: './advert-detail.component.html',
  styleUrls: ['./advert-detail.component.css']
})
export class AdvertDetailComponent implements OnInit {
  advert: any = {};
  phone: string = "*****";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private _u: UtilsService,
    private advertService: AdvertService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(param => {
      this.getAvert(param.get('id'));
    });
  }

  public getAvert(advertId) {
    this.advertService.getAdvert(advertId)
      .then(advert => {
        this.advert = advert;
        // mask phone
        this.maskPhone(this.advert.phone);
        this.titleService.setTitle(this.advert.vehicleMakeName + " "  + this.advert.vehicleModelName);
      });
  }

  public convertPrice2VietNamese = (price) => this._u.convertPrice2VietNamese(price);

  public showPhone() {
    this.phone = this.advert.phone;
    // TODO call API save request view phone number
  }

  public maskPhone(phone) {
    console.log('phone:: ', phone);
    this.phone = phone.substring(0, 5) + "*****   (click để xem)";
  }
}
