<!-- Start Body Content -->
<div class="main" role="main">
    <div id="content" class="content full dashboard-pages">
        <div class="container">
            <div class="dashboard-wrapper">
                <div class="row">
                    <div class="col-md-3 col-sm-4 users-sidebar-wrapper">
                        <!-- SIDEBAR -->
                        <div class="users-sidebar tbssticky">
                            <div style="text-align: center; margin-bottom: 10px;">
                                <img src="./assets/images/user/user-avatar.png" alt=""
                                    style="border-radius: 100%; width: 100px; height: 100px;" />
                                <p style="margin-top: 10px; margin-bottom: 0px;"><strong>Đinh Quốc Sơn</strong></p>
                                <span class="label label-default">Cá Nhân</span>
                                <p><strong>Tài khoản:</strong> 30 xu</p>
                                <!--span class="label label-success">Bán Chuyên</span>
                                    <span class="label label-primary">Salon</span-->
                            </div>
                            <a href="add-listing-form.html" class="btn btn-block btn-primary add-listing-btn">Đăng Bán
                                Xe</a>
                            <ul class="list-group">
                                <li id="quan-ly-chung" (click) = "getGeneralManagement" class="active" data-target="#listing-management-one" class="list-group-item active" data-toggle="tab"> <span class="badge">5</span> 
                                    <a  [routerLink]="['/users', um.user.id, 'quan-ly-chung']"><i class="fa fa-home"></i> Quản Lý Chung</a></li>

                                <li id="quan-ly-tin-ban-xe" (click) = "getUserAdvert" class="list-group-item" data-target="#listing-management-four" data-toggle="tab"> <span class="badge">2</span> 
                                    <a  [routerLink]="['/users', um.user.id, 'quan-ly-tin-ban-xe']"><i class="fa fa-edit"></i> Quản Lý Tin Bán Xe</a></li>

                                <li (click) = "getSavedAdvert" class="list-group-item" data-target="#listing-management-three" data-toggle="tab"> <span class="badge">12</span> 
                                    <a id="tin-ban-xe-da-luu" [routerLink]="['/users', um.user.id, 'tin-ban-xe-da-luu']"><i class="fa fa-star-o"></i> Tin Bán Xe Đã Lưu</a> </li>
                                
                                <li (click) = "getSavedSearch" class="list-group-item" data-target="#listing-management-two" data-toggle="tab"> <span class="badge">5</span> 
                                    <a id="tim-kiem-da-luu" [routerLink]="['/users', um.user.id, 'tim-kiem-da-luu']"><i class="fa fa-folder-o"></i> Tìm Kiếm Đã Lưu</a> </li>
                                
                                <li class="list-group-item"> <a href="user-dashboard-profile.html"><i
                                            class="fa fa-user"></i> Hồ Sơ Cá Nhân</a></li>
                                <li class="list-group-item"> <a href="user-dashboard-settings.html"><i
                                            class="fa fa-cog"></i> Thiết Lập</a></li>
                                <li class="list-group-item"> <a href="javascript:void(0)"><i class="fa fa-sign-out"></i>
                                        Thoát</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9 col-sm-8">
                        <div class="listing-add-form">
                            <section class="listing-form-content">
                                <!-- Quản Lý Chung -->
                                <div id="listing-management-one" class="tab-pane fade in active">
                                    <h2>Quản Lý Chung</h2>
                                    <!-- Tin Bán Xe -->
                                    <div class="dashboard-block">
                                        <div class="dashboard-block-head">
                                            <a href="user-dashboard-manage-ads.html"
                                                class="btn btn-default btn-sm pull-right">Xem tất cả (2)</a>
                                            <h3>Tin Bán Xe</h3>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered dashboard-tables saved-cars-table">
                                                <thead>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Thông tin xe</td>
                                                        <td>Giá bán</td>
                                                        <td>Thời gian đăng tin</td>
                                                        <td>Tình trạng</td>
                                                        <td>Thao tác</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let uAdvert of userAdverts; let i = index">
                                                    <tr>
                                                        <td valign="middle"><input type="checkbox" (change)="onChangeCheckBoxUserAdvert(userAdverts[i].id, $event)"></td>
                                                        <td>
                                                            <!-- Result -->
                                                            <a href="vehicle-details.html" class="car-image"><img
                                                                    src="{{userAdverts[i].photoCover}}"
                                                                    alt=""></a>
                                                            <div class="search-find-results">
                                                                <h5><a href="vehicle-details.html">Honda City 2016 2.0
                                                                        CVT</a></h5>
                                                                <ul class="inline">
                                                                    <li> {{userAdverts[i].vehicleBodyTypeId}}</li>
                                                                    <li>{{userAdverts[i].vehicleExteriorColorName}}</li>
                                                                    <li>{{userAdverts[i].vehicleTransmissionName}}</li>
                                                                    <li>Động cơ {{userAdverts[i].vehicleEngineName}}</li>
                                                                    <li>Đã đi {{userAdverts[i].kmUsed}} km</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><span class="price">{{userAdverts[i].price}}triệu</span></td>
                                                        <td><span class="text-success">{{t.convertTimestamp(userAdverts[i].createAt)}}</span></td>
                                                        <td align="center"><span class="label label-warning">{{userAdverts[i].status}}</span></td>
                                                        <td align="center">
                                                            <button class="text-default" title="Chỉnh sửa"><i class="fa fa-pencil" style="width: 12px;"></i></button>
                                                            <button class="text-default" title="Up tin"><i class="fa fa-rocket" style="width: 12px;"></i></button>
                                                            <button class="text-danger" title="Xóa" data-toggle="modal" data-target="#myModal"><i class="fa fa-times" style="width: 12px;"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button [disabled]="this.checkBoxUserAdverts.length == 0" (click)="delListUserAdverts()" class="btn btn-default btn-sm">Xóa Tin Đang Chọn</button>
                                    </div>
                                    <!-- Tin Bán Xe Đã Lưu -->
                                    <div class="dashboard-block">
                                        <div class="dashboard-block-head">
                                            <a href="user-dashboard-saved-cars.html"
                                                class="btn btn-default btn-sm pull-right">Xem Tất Cả (12)</a>
                                            <h3>Tin Bán Xe Đã Lưu</h3>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered dashboard-tables saved-cars-table">
                                                <thead>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Thông tin xe</td>
                                                        <td>Giá bán</td>
                                                        <td>Thời gian lưu tin</td>
                                                        <td>Thao tác</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let sAdvert of savedAdverts; let i = index">
                                                    <tr>
                                                        <td valign="middle"><input type="checkbox" (change)="onChangeCheckBoxSavedAdvert(savedAdverts[i].id, $event)"></td>
                                                        <td>
                                                            <!-- Result -->
                                                            <a href="vehicle-details.html" class="car-image"><img
                                                                    src="{{savedAdverts[i].ads.photoCover}}"
                                                                    alt=""></a>
                                                            <div class="search-find-results">
                                                                <h5><a href="vehicle-details.html">{{savedAdverts[i].ads.vehicleMakeName + ' ' + savedAdverts[i].ads.vehicleModelName + ' ' + savedAdverts[i].ads.vehicleYearRelease + ' ' + savedAdverts[i].ads.vehicleEngineName + ' ' + savedAdverts[i].ads.vehicleTransmissionName}}</a></h5>
                                                                <ul class="inline">
                                                                    <li> {{savedAdverts[i].ads.vehicleBodyTypeId}}</li>
                                                                    <li>{{savedAdverts[i].ads.vehicleExteriorColorName}}</li>
                                                                    <li>{{savedAdverts[i].ads.vehicleTransmissionName}}</li>
                                                                    <li>Động cơ {{savedAdverts[i].ads.vehicleEngineName}}</li>
                                                                    <li>Đã đi {{savedAdverts[i].ads.kmUsed}} km</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><span class="price">{{savedAdverts[i].ads.price}} triệu</span></td>
                                                        <td><span class="text-success">{{t.convertTimestamp(savedAdverts[i].createAt)}}</span></td>
                                                        <td align="center"><button (click) = "delSavedAdvert(i, savedAdverts[i].id)" class="text-danger" title="Xóa" data-toggle="modal" data-target="#myModal"><i class="fa fa-times" style="width: 12px;"></i></button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button [disabled]="this.checkBoxSavedAdverts.length == 0" (click)="delListSavedAdverts()" class="btn btn-default btn-sm">Xóa Tin Đang Chọn</button>
                                        <button class="btn btn-default btn-sm disabled">So Sánh Xe Đang Chọn</button>
                                    </div>
                                    <!-- Tìm Kiếm Đã Lưu -->
                                    <div class="dashboard-block">
                                        <div class="dashboard-block-head">
                                            <a href="user-dashboard-saved-cars.html"
                                                class="btn btn-default btn-sm pull-right">Xem tất cả (5)</a>
                                            <h3>Tìm Kiếm Đã Lưu</h3>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered dashboard-tables saved-searches-table">
                                                <thead>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Mô Tả</td>
                                                        <td>Chi tiết</td>
                                                        <td>Nhận thông báo qua mail</td>
                                                        <td>Thời gian tạo</td>
                                                        <td>Thao tác</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let sSearch of savedSearchs; let i = index">
                                                    <tr>
                                                        <td valign="middle"><input type="checkbox" (change)="onChangeCheckBoxSavedSearch(savedSearchs[i].id, $event)"></td>
                                                        <td><a [routerLink]="savedSearchs[i].name" class="search-name">Tìm kiếm {{i+1}}</a></td>
                                                        <td>{{savedSearchs[i].detail}}</td>
                                                        <td><a><select (change)="onChangeUpdateNotify(savedSearchs[i].id, $event.target.value)" class="form-control selectpicker input-sm">  <option>{{savedSearchs[i].isReciveNofitication ? 'Bật' : 'Tắt'}}</option><option>{{savedSearchs[i].isReciveNofitication ? 'Tắt' : 'Bật'}}</option>   </select></a></td>
                                                        <td><span class="text-success">{{t.convertTimestamp(savedSearchs[i].createAt)}}</span></td>
                                                        <td align="center">
                                                            <button (click)="delSavedSearch(i, savedSearchs[i].id)" class="text-danger" title="Xóa" data-toggle="modal" data-target="#myModal"><i
                                                                    class="fa fa-times"
                                                                    style="width: 12px;"></i></button>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button [disabled]="this.checkBoxSavedSearchs.length == 0" (click)="delListSavedSearchs()"  class="btn btn-default btn-sm ">Xóa Tìm Kiếm Đang Chọn</button>
                                    </div>
                                </div>

                                <!-- Quản Lý Bán Xe -->
                                <div id="listing-management-four" class="tab-pane fade">
                                    <h2>Tin Bán Xe</h2>
                                    <div class="dashboard-block">
                                        <div class="table-responsive">
                                            <table class="table table-bordered dashboard-tables saved-cars-table">
                                                <thead>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Thông tin xe</td>
                                                        <td>Giá bán</td>
                                                        <td>Thời gian đăng tin</td>
                                                        <td>Tình trạng</td>
                                                        <td>Thao tác</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let uAdvert of userAdverts; let i = index">
                                                    <!-- {{vm.vehicleMakes[i].name}} -->
                                                    <tr>
                                                        <td valign="middle"><input type="checkbox" (change)="onChangeCheckBoxUserAdvert(userAdverts[i].id, $event)"></td>
                                                        <td>
                                                            <!-- Result -->
                                                            <a href="vehicle-details.html" class="car-image"><img src="{{userAdverts[i].photoCover}}" alt=""></a>
                                                            <div class="search-find-results">
                                                                <h5><a href="vehicle-details.html">Honda City 2016 2.0 CVT</a></h5>
                                                                <ul class="inline">
                                                                    <li> {{userAdverts[i].vehicleBodyTypeId}}</li>
                                                                    <li>{{userAdverts[i].vehicleExteriorColorName}}</li>
                                                                    <li>{{userAdverts[i].vehicleTransmissionName}}</li>
                                                                    <li>Động cơ {{userAdverts[i].vehicleEngineName}}</li>
                                                                    <li>Đã đi {{userAdverts[i].kmUsed}} km</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><span class="price">{{userAdverts[i].price}}triệu</span></td>
                                                        <td><span class="text-success">{{t.convertTimestamp(userAdverts[i].createAt)}}</span></td>
                                                        <td align="center"><span class="label label-warning">{{userAdverts[i].status}}</span></td>
                                                        <td align="center">
                                                            <button class="text-default" title="Chỉnh sửa"><i class="fa fa-pencil" style="width: 12px;"></i></button>
                                                            <button class="text-default" title="Up tin"><i class="fa fa-rocket" style="width: 12px;"></i></button>
                                                            <button class="text-danger" title="Xóa" data-toggle="modal" data-target="#myModal"><i class="fa fa-times" style="width: 12px;"></i></button>
                                                        </td>
                                                        
                                                        <!-- <div class="modal fade" id="myModal" tabindex="-1" role="dialog"  aria-hidden="true">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                                        <h4 class="modal-title" id="myModalLabel">Bạn chắc chứ?</h4>
                                                                    </div>
                                                                    <div class="modal-body"> Bạn không thể hoàn tác sau khi xác nhận. </div>
                                                                        <div class="modal-footer">
                                                                            <button type="button" class="btn btn-default inverted" data-dismiss="modal">Không</button>
                                                                            <button type="button" class="btn btn-primary">Chắc chắn!</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        <button [disabled]="this.checkBoxUserAdverts.length == 0" (click)="delListUserAdverts()" class="btn btn-default btn-sm">Xóa Tin Đang Chọn</button>
                                    </div>
                                </div>

                                <!-- Tin Bán Xe Đã Lưu  -->
                                <div id="listing-management-three" class="tab-pane fade">
                                    <h2>Tin Bán Xe Đã Lưu</h2>
                                    <div class="dashboard-block">
                                        <div class="table-responsive">
                                            <table class="table table-bordered dashboard-tables saved-cars-table">
                                                <thead>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Thông tin xe</td>
                                                        <td>Giá bán</td>
                                                        <td>Thời gian lưu tin</td>
                                                        <td>Thao tác</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let sAdvert of savedAdverts; let i = index">
                                                    <tr>
                                                        <td valign="middle"><input type="checkbox" (change)="onChangeCheckBoxSavedAdvert(savedAdverts[i].id, $event)"></td>
                                                        <td>
                                                            <!-- Result -->
                                                            <a href="vehicle-details.html" class="car-image"><img src="{{savedAdverts[i].ads.photoCover}}" alt=""></a>
                                                            <div class="search-find-results">
                                                                <h5><a href="vehicle-details.html">{{savedAdverts[i].ads.vehicleMakeName + ' ' + savedAdverts[i].ads.vehicleModelName + ' ' + savedAdverts[i].ads.vehicleYearRelease + ' ' + savedAdverts[i].ads.vehicleEngineName + ' ' + savedAdverts[i].ads.vehicleTransmissionName}}</a></h5>
                                                                <ul class="inline">
                                                                    <li> {{savedAdverts[i].ads.vehicleBodyTypeId}}</li>
                                                                    <li>{{savedAdverts[i].ads.vehicleExteriorColorName}}</li>
                                                                    <li>{{savedAdverts[i].ads.vehicleTransmissionName}}</li>
                                                                    <li>Động cơ {{savedAdverts[i].ads.vehicleEngineName}}</li>
                                                                    <li>Đã đi {{savedAdverts[i].ads.kmUsed}} km</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><span class="price">{{savedAdverts[i].ads.price}} triệu</span></td>
                                                        <td><span class="text-success">{{t.convertTimestamp(savedAdverts[i].createAt)}}</span></td>
                                                        <td align="center"><button (click) = "delSavedAdvert(i, savedAdverts[i].id)" class="text-danger" title="Xóa" data-toggle="modal" data-target="#myModal"><i class="fa fa-times" style="width: 12px;"></i></button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button [disabled]="this.checkBoxSavedAdverts.length == 0" (click)="delListSavedAdverts()" class="btn btn-default btn-sm">Xóa Tin Đang Chọn</button>
                                        <button class="btn btn-default btn-sm disabled">So Sánh Xe Đang Chọn</button>
                                    </div>
                                </div>

                                <!-- Tìm Kiếm Đã Lưu  -->
                                <div id="listing-management-two" class="tab-pane fade">
                                    <h2>Tìm Kiếm Đã Lưu</h2>
                                    <div class="dashboard-block">
                                        <div class="table-responsive">
                                            <table class="table table-bordered dashboard-tables saved-searches-table">
                                                <thead>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Mô Tả</td>
                                                        <td>Chi tiết</td>
                                                        <td>Nhận thông báo qua mail</td>
                                                        <td>Thời gian tạo</td>
                                                        <td>Thao tác</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let sSearch of savedSearchs; let i = index">
                                                    <tr>
                                                        <td valign="middle"><input type="checkbox" (change)="onChangeCheckBoxSavedSearch(savedSearchs[i].id, $event)"></td>
                                                        <td><a [routerLink]="savedSearchs[i].name" class="search-name">Tìm kiếm {{i+1}}</a></td>
                                                        <td>{{savedSearchs[i].detail}}</td>
                                                        <td><a><select (change)="onChangeUpdateNotify(savedSearchs[i].id, $event.target.value)" class="form-control selectpicker input-sm">  <option>{{savedSearchs[i].isReciveNofitication ? 'Bật' : 'Tắt'}}</option><option>{{savedSearchs[i].isReciveNofitication ? 'Tắt' : 'Bật'}}</option>   </select></a></td>
                                                        <td><span class="text-success">04/11/14 @ 12:09am</span></td>
                                                        <td align="center">
                                                            <button (click)="delSavedSearch(i, savedSearchs[i].id)" class="text-danger" title="Xóa" data-toggle="modal" data-target="#myModal"><i
                                                                    class="fa fa-times"
                                                                    style="width: 12px;"></i></button>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button [disabled]="this.checkBoxSavedSearchs.length == 0" (click)="delListSavedSearchs()"  class="btn btn-default btn-sm ">Xóa Tìm Kiếm Đang Chọn</button>
                                    </div>
                                </div>

                              

                                
                            </section>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>