import { Component, ElementRef, ViewChild } from '@angular/core';
import { SharedVarService } from './services/share-var.service';
import { LoginService } from './services/login.service';
declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('userName') userName: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  public um: any = {};
  constructor(
    private service: SharedVarService,
    private loginService: LoginService,

  ) {
    this.um.user = {};
  }
  title = 'dicom-meoto-angualar9';

  ngOnInit(): void {
    this.service.getValue().subscribe((islogin) => {
      if (islogin) {
        let userModel: any = localStorage.getItem("_u");
        userModel = JSON.parse(userModel);
        this.um.user = userModel;
      }
    });
    let userModel: any = localStorage.getItem("_u");
    userModel = JSON.parse(userModel);
    this.um.user = userModel;

    // facebook SDK
    this.facebookSDK();
    // google SDK
    this.googleSDK();

  }
  // button login facebook
  public async submitLoginFacebook() {
    this.loginService.submitLoginFacebook(true);

  }

  // button login google
  public async submitLoginGoogle() {
    this.loginService.submitLoginGoogle(this.loginElement, true);

  }

  // SDK facebook library
  facebookSDK() {
    this.loginService.facebookSDK();
  }

  // SDK google library
  googleSDK() {
    this.loginService.googleSDK(this.loginElement, true);
  }

  // logout event
  public logOut() {
    localStorage.removeItem("_u");
    // logout
    this.um.user = null;
  }

  public showModalLogin() {
    this.userName.nativeElement.value = '';
    this.password.nativeElement.value = '';
    jQuery("#modalUser").modal("show");
  }

  // button login
  public async onLoginSubmit(from) {
    await this.loginService.loginUser(from, true)
  }

  // for jump to top page after navigate
  public onActivate(event) {
    // window.scroll(0,0);

    window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
     });
 }


}
