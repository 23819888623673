// core
import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

// 3rd
import { ToastrService } from 'ngx-toastr';


// self
import { ContactUsService } from '../services/contact-us';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  vm: any = {};

  constructor(
    private contactUsService: ContactUsService,
    private titleService: Title,
    private toastr: ToastrService,
  ) { 
    this.vm.contact = {}
    this.titleService.setTitle('Meoto-liên hệ với chúng tôi');
  }

  ngOnInit(): void {
  }

  public sendContact() {
    this.contactUsService.sendContactUs(this.vm.contact)
    .then(() => {
      this.toastr.success("chúng tôi đã nhận được thông tin của bạn, nhân viên Meoto sẽ liên hệ lại quý khách ngay lập tức!");
    })
    .catch(() => this.toastr.error("Có sự cố bạn vui lòng gửi lại!"));
  }

}
