<div class="page-header parallax"
    style="background-image:url(assets/images/content/joinus-background.png); height:400px"></div>
<!-- End Hero Slider -->
<!-- Start Body Content -->
<div class="main" role="main">
    <div id="content" class="content full">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <h2>Mua bán xe dễ dàng với MeOTO</h2>
                    <p>Với hệ thống đại lý cùng các đối tác tin cậy, MeOTO đề xuất cho bạn hàng ngàn lựa chọn xe ô tô
                        phù hợp với mọi tiêu chí và nhu cầu sử dụng của bạn cũng như của gia đinh.</p>
                    <p>MeOTO hướng đến sự tiện dụng, linh hoạt dành cho mọi đối tượng người dùng từ cá nhân, môi giới
                        cho tới các salon ô tô. MeOTO thực sự mong muốn giúp cho nhu cầu giao dịch xe ô tô trở nên dễ
                        dàng, thuận tiện và nhanh chóng trên nền tảng công nghệ 4.0.</p>
                    <div class="spacer-20"></div>
                    <div class="icon-box ibox-rounded ibox-light ibox-effect">
                        <div class="ibox-icon">
                            <i class="fa fa-shopping-cart"></i>
                        </div>
                        <h3>Mua bán ô tô chưa bao giờ dễ dàng hơn</h3>
                        <p>MeOTO được thiết kế trực quan, dễ sử dụng và tăng độ tương tác giữa người bán và người mua ô
                            tô giúp cho giao dịch diễn ra thuận lợi và nhanh chóng.</p>
                    </div>
                    <div class="spacer-20"></div>
                    <div class="icon-box ibox-rounded ibox-light ibox-effect">
                        <div class="ibox-icon">
                            <i class="fa fa-list-alt"></i>
                        </div>
                        <h3>Tin tức, đánh giá, thông số xe mới</h3>
                        <p>Những thông tin, đánh giá hữu ích về ô tô được cập nhật liên tục. Những bài so sánh, kinh
                            nghiệm về xe sẽ giúp bạn đưa ra quyết định đúng trước khi mua xe</p>
                    </div>
                    <div class="spacer-20"></div>
                    <div class="icon-box ibox-rounded ibox-light ibox-effect">
                        <div class="ibox-icon">
                            <i class="fa fa-cogs"></i>
                        </div>
                        <h3>MeOTO cung cấp nhiều dịch vụ tiện dụng</h3>
                        <p>Hệ thống thông báo qua email giúp bạn không bỏ lỡ bất cứ cơ hội giao dịch nào.</p>
                    </div>
                    <div class="spacer-20"></div>

                    <hr class="fw">

                    <header>
                        <h3>Khách hàng nói về MeOTO</h3>
                    </header>
                    <div class="spacer-40"></div>
                    <!-- Testimonials -->
                    <div class="carousel-wrapper">
                        <div class="row">
                            <ul class="owl-carousel carousel-fw" id="testimonials-slider" data-columns="2"
                                data-pagination="yes" data-arrows="no" data-single-item="no" data-items-desktop="2"
                                data-items-desktop-small="2" data-items-tablet="2" data-items-mobile="1">
                                <li class="item">
                                    <div class="testimonial-block">
                                        <blockquote>
                                            <p>Dịch vụ chuyên nghiệp, hỗ trợ tạo trang salon nhiệt tình. Các bước thao
                                                tác quản lý và đăng bán xe rất đơn giản. MeOTO góp phần tăng doanh số
                                                bán xe, sẽ ủng hộ MeOTO trong thời gian dài sắp tới.</p>
                                        </blockquote>
                                        <div class="testimonial-avatar"><img src="images/user/user-avatar_1.png" alt=""
                                                width="60" height="60"></div>
                                        <div class="testimonial-info">
                                            <div class="testimonial-info-in">
                                                <strong>anh Lê Hoài Đức</strong><span>Salon ABC</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="item">
                                    <div class="testimonial-block">
                                        <blockquote>
                                            <p>Chất lượng dịch vụ tương xứng với chi phí bỏ ra. Những công cụ tiện dụng
                                                của MeOTO giúp phát huy tối đa khả năng tiếp cận với khách hàng mua ô
                                                tô. Chi phí vừa đủ mang lại hiệu quả cao.</p>
                                        </blockquote>
                                        <div class="testimonial-avatar"><img src="images/user/user-avatar_2.png" alt=""
                                                width="60" height="60"></div>
                                        <div class="testimonial-info">
                                            <div class="testimonial-info-in">
                                                <strong>anh Nguyễn Hải Nghĩa</strong><span>Salon XYZ</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="item">
                                    <div class="testimonial-block">
                                        <blockquote>
                                            <p>Rất bất ngờ với hiệu quả mang lại từ mô hình kinh doanh và quản lý của
                                                MeOTO. Nhân viên MeOTO hướng dẫn rất tận tâm, nhiệt tình và chu đáo giải
                                                quyết những thắc mắc đến từ khách hàng.</p>
                                        </blockquote>
                                        <div class="testimonial-avatar"><img src="images/user/user-avatar_3.png" alt=""
                                                width="60" height="60"></div>
                                        <div class="testimonial-info">
                                            <div class="testimonial-info-in">
                                                <strong>anh Dương Mình Hoàng</strong><span>Salon LMN</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <section class="signup-form sm-margint">
                        <form #userForm="ngForm" (ngSubmit)="onRegisterSubmit(userForm.value)" ngNativeValidate>
                            <!-- Regular Signup -->
                            <div class="regular-signup">
                                <h3>Đăng ký</h3>
                                <input type="phone" name="phone" ngModel class="form-control" placeholder="Nhập số điện thoại" required>
                                <input type="email" name="email" ngModel class="form-control" placeholder="Nhập địa chỉ email" required>
                                <input type="password" name="password" ngModel class="form-control password-input margin-5" required
                                    placeholder="Nhập mật khẩu" />
                                <a href="javascript:void(0);" class="password-generate pass-actions"><i
                                        class="fa fa-refresh"></i></a>
                                <div class="progress">
                                    <div class="progress-bar password-output" style="width: 0%"></div>
                                </div>
                                <div class="clearfix spacer-20"></div>
                                <label class="checkbox-inline"><input type="checkbox" name="checkbox" ngModel>Thông qua việc đăng ký, tôi đồng ý
                                    với <a href="#">điều khoản &amp; điều kiện</a> và <a href="#">chính sách bảo
                                        mật</a></label>
                                <div class="spacer-20"></div>
                                <button type="submit" class="btn btn-primary btn-lg btn-block" >Tạo tài khoản </button>
                            </div>
                            <!-- Social Signup -->
                            <div class="social-signup">
                                <span class="or-break">or</span>
                                <button type="button" (click)="submitLoginFacebook();"
                                    class="btn btn-block btn-facebook btn-social"><i class="fa fa-facebook"></i> Đăng ký
                                    với Facebook</button>
                                <button type="button"
                                    class="btn btn-block btn-google btn-social loginBtn loginBtn--google" #loginRef><i
                                        class="fa fa-google"></i> Đăng ký với Google</button>
                            </div>

                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>