import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private apiService: ApiService) { }

  public getBlogs = () => this.apiService.get('blogs');
  public getRecentlyBlogs = this._getRecentlyBlogs;

  public getBlogById = (slug) => this.apiService.get('blogs/' + slug);
  // public createAdverts = (advert) => this.apiService.post('ads', advert);
  // public patchAdverts = (id, advert) => this.apiService.patch('ads/' + id, advert);


  private _getRecentlyBlogs() {
    let queryBuilder = {
      filter: {
        limit: 10,
        order: 'createAt DESC'
      },
    };
    // inint query param
    let queryParam = "?" + decodeURI(stringify(queryBuilder));
    return this.apiService.get('blogs' + queryParam);
  }
}
