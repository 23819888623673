// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';

// 3rd
import { Options, LabelType, ChangeContext } from 'ng5-slider';
import { stringify } from 'qs';
import { combineLatest } from 'rxjs';

// self
import { ScriptService } from '../services/load-script.service';
import { AdvertService } from '../services/advert.service';
import { BlogsService } from '../services/blog.service';
import { CacheService } from '../services/cache.service';
import { UtilsService } from '../services/utils.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  // for load init script
  isLoad = false;
  public adverts = {}
  public vm: any = {adverts: [], blogs: []};
  DEFAULT_VALUE = '-';
  NEW_VEHICLE_SUBFIX = "moi";
  USED_VEHICLE_SUBFIX = "da-qua-su-dung";
  ALL_VEHICLE_SUBFIX = "";

  // for buildpath/query param
  private STATUS_REFIX = "can-mua-xe-oto-";
  private PLACE_REFIX = "can-mua-xe-tai-";
  private MAKE_REFIX = "xe-";
  private MODEL_REFIX = "mau-xe-";
  private BODY_REFIX = "kieu-dang-";
  private YEAR_REFIX = "san-xuat"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private YEAR_REFIX_RANGE = "san-xuat-khoang-"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private YEAR_REFIX_MIN = "san-xuat-tu-"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private YEAR_REFIX_MAX = "san-xuat-den-"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private PRICE_REFIX = "gia-tu-"; // ga-tu-100-trieu-den-1-ty
  private PRICE_MIDLE_REFIX = "-den-"; // ga-tu-100-trieu-den-1-ty
  private KM_USED_REFIX = "toi-da-"; // chay-tu-100-km-den-200-km
  private KM_USED_UNIT = "-km"; // chay-tu-100-km-den-200-km

  private QP_TRANS = "hop-so";
  private QP_VEHICLE_SOURCE = "xuat-su";
  private QP_EXTERIOR_COLOR = "mau";


  kmUsedoptions: Options = {
    floor: 0,
    ceil: 300000,
    disabled: false,
  };
  priceOptions: Options = {
    floor: 0,
    disabled: false,
    ceil: 5000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this._u.convertPrice2VietNameseRange(value);
        case LabelType.High:
          return this._u.convertPrice2VietNameseRange(value);
        default:
          return this._u.convertPrice2VietNameseRange(value);
      }
    }
  };


  constructor(
    private elementRef: ElementRef, @Inject(DOCUMENT) private doc,
    private scriptService: ScriptService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private advertService: AdvertService,
    private blogsService: BlogsService,
    private cacheService: CacheService,
    public _u: UtilsService,
  ) {
    this.vm.search = {
      vehicleStatus: this.ALL_VEHICLE_SUBFIX, // moi vs da-qua-su-dung
      placeId: this.DEFAULT_VALUE,
      vehicleMakeId: this.DEFAULT_VALUE,
      vehicleModelId: this.DEFAULT_VALUE,
      vehicleBodyTypeId: this.DEFAULT_VALUE,
      vehicleYearMin: this.DEFAULT_VALUE,
      vehicleYearMax: this.DEFAULT_VALUE,
      vehicleSourceId: this.DEFAULT_VALUE,
      vehicleTransmissionId: this.DEFAULT_VALUE,
      vehicleExteriorColorId: this.DEFAULT_VALUE,
      //for ng5slide price range
      minPrice: 0,
      minPriceCfg: 0,
      maxPrice: 5000,
      maxPriceCfg: 5000,
      //for ng5slide kmUsed max
      maxKmUsed: 300000,
      maxKmUsedCfg: 300000,
    };
    // default search config
    if (!this.vm.searchCfg) {
      this.vm.searchCfg = { ... this.vm.search };
    }


    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT, this.scriptService.SLIDE).then(() => {
      this.isLoad = true;
    });
    setTimeout(() => { this.isLoad = true }, 1000);
  }

  ngOnInit(): void {
    this._initMasterData();
    this._initRecentlyBlog();
    this._initRecentlyAds();
  }


  /**
  * Init data for first load
  */
  private _initMasterData() {
    this.cacheService.getVehicleMakes()
      .then(vehicleMakes => {
        this.vm.vehicleMakes = vehicleMakes;
        // this.vm.selectedVechicleMake = this._reloadOption(vehicleMakes, this.vm.advert.vehicleMakeId, false) || vehicleMakes[0];
      });
    // vehicle body type
    this._initVehicleBodyTypes();

    // this.cacheService.getVehicleDoors()
    //   .then(vehicleDoors => {
    //     this.vm.vehicleDoors = vehicleDoors;
    //     // this.vm.selfVehicle.vehicleDoor = this._reloadOption(vehicleDoors, this.vm.advert.vehicleDoorId, true);
    //   });
    this.cacheService.getVehicleSeats()
      .then(vehicleSeats => {
        this.vm.vehicleSeats = vehicleSeats;
        // this.vm.selfVehicle.vehicleSeat = this._reloadOption(vehicleSeats, this.vm.advert.vehicleSeatId, true);

      });
    this.cacheService.getVehicleTransmissions()
      .then(vehicleTransmissions => {
        this.vm.vehicleTransmissions = vehicleTransmissions;
        // this.vm.selfVehicle.vehicleTransmission = this._reloadOption(vehicleTransmissions, this.vm.advert.vehicleTransmissionId, true);
      });
    this.cacheService.getVehicleDriveTypes()
      .then(vehicleDriveTypes => {
        this.vm.vehicleDriveTypes = vehicleDriveTypes;
        // this.vm.selfVehicle.vehicleDriveType = this._reloadOption(vehicleDriveTypes, this.vm.advert.vehicleDriveTypeId, true);
      });
    // this.cacheService.getVehicleEngines()
    //   .then(vehicleEngines => {
    //     this.vm.vehicleEngines = vehicleEngines;
    //     // this.vm.selfVehicle.vehicleEngine = this._reloadOption(vehicleEngines, this.vm.advert.vehicleEngineId, true);
    //   });
    // this.cacheService.getVehicleFuels()
    //   .then(vehicleFuels => {
    //     this.vm.vehicleFuels = vehicleFuels;
    //     // this.vm.selfVehicle.vehicleFuel = this._reloadOption(vehicleFuels, this.vm.advert.vehicleFuelId, true);
    //   });
    this.cacheService.getVehicleSources()
      .then(vehicleSources => {
        this.vm.vehicleSources = vehicleSources;
        // this.vm.selfVehicle.vehicleSource = this._reloadOption(vehicleSources, this.vm.advert.vehicleSourceId, true);
      });
    this.cacheService.getVehicleExteriorColor()
      .then(vehicleExteriorColor => {
        this.vm.vehicleExteriorColors = vehicleExteriorColor;
        // this.vm.selfVehicle.vehicleSource = this._reloadOption(vehicleSources, this.vm.advert.vehicleSourceId, true);
      });

    this.vm.vehicleYearMins = this._u._generateYear(0, 0);
    this.vm.vehicleYearMaxs = this._u._generateYear(0, 0);
    // this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
    // this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
  }

  public _initVehicleBodyTypes() {
    this.cacheService.getVehicleBodyTypes()
      .then(vehicleBodyTypes => {
        this.vm.vehicleBodyTypes = vehicleBodyTypes;
        // this.vm.selfVehicle.vehicleBodyType = this._reloadOption(vehicleBodyTypes, this.vm.advert.vehicleBodyTypeId, true);
      });
  }

  public _initRecentlyAds() {
    this.advertService.getRecentlyAdverts()
    .then(adverts => {
      this.vm.adverts = adverts;
    });
  }

  public _initRecentlyBlog() {
    this.blogsService.getRecentlyBlogs()
    .then(blogs => {
      this.vm.blogs = blogs;
    });
  }



  public async onSelectVehicleMake(vehicleMakeId) {
    let isSearch = (vehicleMakeId) ? true : false;
    // default
    if (!vehicleMakeId) {
      vehicleMakeId = this.vm.search.vehicleMakeId;
    }
    // init data again
    if (!vehicleMakeId || vehicleMakeId == this.DEFAULT_VALUE) {
      this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
      this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
      this.vm.search.vehicleModelId = this.DEFAULT_VALUE;
      return;
    }

    // this._handelUpdateUrl(this.MAKE_REFIX, vehicleMakeId);
    await this.cacheService.getVehicleModelsByVehicleMakeId(vehicleMakeId)
      .then(vehicleModels => {
        console.log('vehicleModels: ', vehicleModels);
        this.vm.vehicleModels = vehicleModels;
        let found = false;
        for (let vModel of vehicleModels) {
          if (vModel.id == this.vm.search.vehicleModelId) {
            found = true;
          }
        }
        if (!found) {
          // reset selected vehicle model
          this.vm.search.vehicleModelId = this.DEFAULT_VALUE;
        }
        this.onSelectVehicleModel(undefined);
        // handle body type too
        return;
      });
  }

  public onSelectVehicleModel(vehicleModelId) {
    let isSearch = (vehicleModelId) ? true : false;
    // default
    if (!vehicleModelId) {
      vehicleModelId = this.vm.search.vehicleModelId;
    }

    // init data
    if (!vehicleModelId || vehicleModelId == this.DEFAULT_VALUE) {
      this._initVehicleBodyTypes();
      this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, 0);
      this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
    }

    // get v-model object
    for (let model of this.vm.vehicleModels) {
      if (model.id == vehicleModelId) {
        let { vehicleYearReleases } = model;
        // sort
        vehicleYearReleases.sort((a, b) => b - a);
        this.vm.vehicleBodyTypes = model.vehicleBodyTypes;
        let min = 0;
        let max = 0;
        // re-default year
        if (!vehicleYearReleases.includes(Number(this.vm.search.vehicleYearMax))) {
          // this.vm.search.vehicleYearMin = this.DEFAULT_VALUE;
          this.vm.search.vehicleYearMax = this.DEFAULT_VALUE;
          min = [...vehicleYearReleases].pop();
        }
        if (!vehicleYearReleases.includes(Number(this.vm.search.vehicleYearMin))) {
          // this.vm.search.vehicleYearMax = this.DEFAULT_VALUE;
          this.vm.search.vehicleYearMin = this.DEFAULT_VALUE;
          max = vehicleYearReleases[0];
        }
        if (!min) {
          min = this.vm.search.vehicleYearMin;
        }
        if (!max) {
          max = this.vm.search.vehicleYearMax;
        }

        // generate again
        this.vm.vehicleYearMins = this._u._generateYear(max, [...vehicleYearReleases].pop());
        this.vm.vehicleYearMaxs = this._u._generateYear(vehicleYearReleases[0], min);
        break;
      }
    }
    // when user re-selected vehicle model when bodytype already selected, if bodytype not exted on model we re-default bodytype
    if (this.vm.search.vehicleBodyTypeId != this.DEFAULT_VALUE) {
      let found = false;
      for (let bt of this.vm.vehicleBodyTypes) {
        if (bt.id == this.vm.search.vehicleBodyTypeId) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.vm.search.vehicleBodyTypeId = this.DEFAULT_VALUE;
      }
    }

    if (isSearch) {
      // TODO
      // this._handelNavigateByUrlForVehicleModel();
    }
  }

  public onSelectVehicleBodyType() {
    // this._handelUpdateUrl(this.BODY_REFIX, this.vm.search.vehicleBodyTypeId);
    // this.getAdverts();
  }

  // re-generate min year
  public onSelectVehicleYearMin() {
    this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
    // this._handelUpdateUrlForYearReplease(this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax);
  }

  // belong to model too
  public onSelectVehicleYearMax() {
    this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
    // this._handelUpdateUrlForYearReplease(this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax);
  }

  public onUserChangePriceEnd(changeContext: ChangeContext) {
    console.log("onUserChangePriceEnd:", this.vm.search.minPrice);
    // this._handelNavigateByUrlPriceSlider(this.vm.search.minPrice, this.vm.search.minPriceCfg, this.vm.search.maxPrice, this.vm.search.maxPriceCfg)
  }

  public onUserChangeKmUsedEnd(changeContext: ChangeContext) {
    console.log("onUserChangeKmUsedEnd:", this.vm.search.minPrice);
    // this._handelNavigateByUrlKmUsedSlider(this.vm.search.minKmUsed, this.vm.search.minKmUsedCfg, this.vm.search.maxKmUsed, this.vm.search.maxKmUsedCfg);
  }

  public onSearchVehice() {
    // build query param
    let onlyShowNewVehicle = this.vm.search.onlyShowNewVehicle;
    let path = this.location.path();
    path = this._handelUpdateUrlForYearReplease(path, this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax);
    path = this._handelUpdateUrl(path, this.BODY_REFIX, this.vm.search.vechileBodyTypeId);
    path = this._handelUpdateUrl(path, this.PLACE_REFIX, this.vm.search.placeId);
    path = this._handelUpdateUrl(path, this.STATUS_REFIX, onlyShowNewVehicle ? this.NEW_VEHICLE_SUBFIX : this.ALL_VEHICLE_SUBFIX);
    path = this._handelNavigateByUrlForVehicleModel(path);
    path = this._handelNavigateByUrlForVehicleMake(path);
    path = this._handelNavigateByUrlPriceSlider(path, this.vm.search.minPrice, this.vm.search.minPriceCfg, this.vm.search.maxPrice, this.vm.search.maxPriceCfg);
    if (onlyShowNewVehicle) {
      path = this._handelNavigateByUrlKmUsedSlider(path, this.vm.search.maxKmUsed, this.vm.search.maxKmUsedCfg);
    }
    path = this._handelNavigateByUrlForQueryParam(path);
    // go to
    path = path.replace('home', 'danh-sach-xe-oto');
    this.router.navigateByUrl(path);
  }

  public onSelectShowNewVehicleOnly() {
    this.kmUsedoptions = Object.assign({}, this.kmUsedoptions, { disabled: this.vm.search.onlyShowNewVehicle });
  }


  public convertPrice2VietNameseRange(price) {
    return this._u.convertPrice2VietNameseRange(price);
  }

  public convertPrice2VietNamese(price) {
    return this._u.convertPrice2VietNamese(price);
  }

  // --------------------------------------------------------------------
  // util section
  /**
   *  Nagivate to predict route
   * @param prefix prefix of route
   * @param data param of route
   */
  private _handelUpdateUrl(path, prefix, data) {
    let predictParam = (!data || data == this.DEFAULT_VALUE) ? '' : prefix + data;
    return this._goUrl(path, prefix, predictParam);
  }

  /**
   * VehicleModel belongs to Vehicle Make, so when update make we should be update model too
   */
  private _handelNavigateByUrlForVehicleMake(path) {
    let _vehicleMakeId = this.vm.search.vehicleMakeId;
    let _vehicleModelId = this.vm.search.vehicleModelId;
    let predictVehicleMakeParam = (!_vehicleMakeId || _vehicleMakeId == this.DEFAULT_VALUE) ? '' : this.MAKE_REFIX + _vehicleMakeId;
    let predictVehicleModelParam = (!_vehicleModelId || _vehicleModelId == this.DEFAULT_VALUE) ? '' : this.MODEL_REFIX + _vehicleModelId;
    return this._goUrlV2(path, [{ prefix: this.MAKE_REFIX, predictParam: predictVehicleMakeParam }, { prefix: this.MODEL_REFIX, predictParam: predictVehicleModelParam }]);
  }

  /**
   * Bodytype, ReleaseYears belongs to Vehicle Model, so when update make we should be update them too
   */
  private _handelNavigateByUrlForVehicleModel(path) {
    let _vehicleModelId = this.vm.search.vehicleModelId;
    let _vehicleBodyTypeId = this.vm.search.vehicleBodyTypeId;
    let predictVehicleModelParam = (!_vehicleModelId || _vehicleModelId == this.DEFAULT_VALUE) ? '' : this.MODEL_REFIX + _vehicleModelId;
    let predictVehicleBodyTypeParam = (!_vehicleBodyTypeId || _vehicleBodyTypeId == this.DEFAULT_VALUE) ? '' : this.BODY_REFIX + _vehicleBodyTypeId;
    let predictVehicleYearsParam = this._handelUpdateUrlForYearReplease(path, this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax, true);
    return this._goUrlV2(path, [
      { prefix: this.MODEL_REFIX, predictParam: predictVehicleModelParam },
      { prefix: this.BODY_REFIX, predictParam: predictVehicleBodyTypeParam },
      { prefix: this.YEAR_REFIX, predictParam: predictVehicleYearsParam },
    ]);
  }

  private _goUrl(path, prefix, predictParam) {
    let _params = path.split("/");
    let isReplace = false;

    for (let param of _params) {
      if (param.startsWith(prefix)) {
        path = path.replace(param, predictParam);
        isReplace = true;
        break;
      }
    }
    if (!isReplace) {
      let query = ""
      if (path.indexOf('?') > -1) {
        [path, query] = path.split('?');
        query = '?' + query;
      }
      path += '/' + predictParam + query;
    } {
      // after replace '//' by '/' after remove pram
      path = path.replace("//", "/");
    }

    // this.router.navigateByUrl(path);
    return path;
  }

  // for related and replace/add mutiple param
  private _goUrlV2(path, dataArray) {
    let _params = path.split("/");
    let _subParam = "";
    for (let data of dataArray) {
      let isReplace = false;
      for (let param of _params) {
        if (param.startsWith(data.prefix)) {
          path = path.replace(param, data.predictParam);
          isReplace = true;
          break;
        }
      }
      // summary totals
      if (!isReplace && data.predictParam) {
        _subParam += "/" + data.predictParam
      }
    }
    // consider split query param for update and add it again
    if (_subParam) {
      let query = ""
      if (path.indexOf('?') > -1) {
        [path, query] = path.split('?');
        query = '?' + query;
      }
      path += _subParam + query;
    } {
      // after replace '//' by '/' after remove pram
      path = path.replace("//", "/");
    }

    // this.router.navigateByUrl(path);
    return path;
  }


  private _handelUpdateUrlForYearReplease(path, paramMin, paramMax, isNotGoUrl?) {
    // only set when have param
    let predictParam = '';
    if ((!paramMin && !paramMax) || (paramMin == this.DEFAULT_VALUE && paramMax == this.DEFAULT_VALUE)) {
      predictParam = '';
    } else {
      if (paramMin != this.DEFAULT_VALUE && paramMax != this.DEFAULT_VALUE) {
        predictParam = this.YEAR_REFIX_RANGE + paramMin + '-' + paramMax;
      } else if (paramMin != this.DEFAULT_VALUE) {
        predictParam = this.YEAR_REFIX_MIN + paramMin;
      } else if (paramMax != this.DEFAULT_VALUE) {
        predictParam = this.YEAR_REFIX_MAX + paramMax;
      } else {
        predictParam = '';
      }
    }
    if (isNotGoUrl) {
      return predictParam;
    }

    return this._goUrl(path, this.YEAR_REFIX, predictParam);
  }

  private _handelNavigateByUrlPriceSlider(path, paramMin, paramMinCfg, paramMax, paramMaxCfg) {
    // don't adjust
    if (paramMin == paramMinCfg && paramMax == paramMaxCfg) {
      return path;
    }
    // TODO improve over (+) add meaning word like 'hơn 5 tỉ'
    let predictParam = this.PRICE_REFIX + this._u._convertPrice2VietNamese(paramMin, 'ti', 'trieu', '-') + this.PRICE_MIDLE_REFIX + this._u._convertPrice2VietNamese(paramMax, 'ti', 'trieu', '-');
    return this._goUrl(path, this.PRICE_REFIX, predictParam);
  }

  private _handelNavigateByUrlKmUsedSlider(path, paramMax, paramMaxCfg) {
    // don't adjust
    if (paramMax == paramMaxCfg) {
      return path;
    }
    // TODO improve over (+) add meaning word like 'hơn 300,000 km'
    let predictParam = this.KM_USED_REFIX + paramMax + this.KM_USED_UNIT;
    return this._goUrl(path, this.KM_USED_REFIX, predictParam);
  }

  private _handelNavigateByUrlForQueryParam(path) {
    // transmission
    let queryParam = {
    }
    // for (let trans of this.vm.vehicleTransmissions) {
    //   // console.log('onSelectVhicleTransmission', trans);
    //   if (trans.isSelected) {
    //     // only init when have isSelete because stringify generate empty param like "hop-so="
    //     if (!queryParam[this.QP_TRANS]) {
    //       queryParam[this.QP_TRANS] = [];
    //     }
    //     queryParam[this.QP_TRANS].push(trans.id);
    //   }
    // }
    if (this.vm.search.vehicleTransmissionId != this.DEFAULT_VALUE) {
      if (!queryParam[this.QP_TRANS]) {
        queryParam[this.QP_TRANS] = [];
      }
      queryParam[this.QP_TRANS].push(this.vm.search.vehicleTransmissionId);
    }
    // vehicle source
    if (this.vm.search.vehicleSourceId != this.DEFAULT_VALUE) {
      queryParam[this.QP_VEHICLE_SOURCE] = this.vm.search.vehicleSourceId;
    }

    // exterior color
    // for (let color of this.vm.vehicleExteriorColors) {
    //   // console.log('onSelectVhicleTransmission', color);
    //   if (color.isSelected) {
    //     // only init when have isSelete because stringify generate empty param like "mau="
    //     if (!queryParam[this.QP_EXTERIOR_COLOR]) {
    //       queryParam[this.QP_EXTERIOR_COLOR] = [];
    //     }
    //     queryParam[this.QP_EXTERIOR_COLOR].push(color.id);
    //   }
    // }
    // Text search
    if (this.vm.search.text) {
      queryParam['tim-kiem'] = this.vm.searh.text;
    }
    // let query = decodeURI(stringify(queryParam, { arrayFormat: 'brackets' }));
    let query = decodeURI(stringify(queryParam, { arrayFormat: 'comma' }));
    if (path.indexOf('?') >= -1) {
      path = path.split('?')[0];
    }

    console.log("goto: ", path + "?" + query);
    // this.router.navigateByUrl();
    return query ? path + '?' + query : path;
  }

  public _loadScript(url) {
    console.log('preparing to load...', url);
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('footer')[0].appendChild(node);
  }

  public _convertTimestamp(timestamp) {
    var date = new Date(timestamp);
    var year = date.getFullYear(); // 2020
    var month = date.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
    var day = date.getDate(); // 9
    var postDate = `${day}/${month}/${year}`
    return postDate
  }

}
