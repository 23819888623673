<div class="utility-bar" style="margin-top: 25px;">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-8">
                <ol class="breadcrumb">
                    <li><a [routerLink]="['/home']">Trang Chủ</a></li>
                    <li class="active">Các Hãng Xe Ô Tô</li>
                </ol>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-4">
                <ul class="utility-icons social-icons social-icons-colored">
                    <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li class="googleplus"><a href="#"><i class="fa fa-google-plus"></i></a></li>
                    <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                </ul>
            </div>
        </div>
      </div>
</div>
<!-- Start Body Content -->
  <div class="main" role="main">
    <div id="content" class="content full">
          <div class="container">
        <div class="gallery-filter">
              <ul class="nav nav-pills sort-source" data-sort-id="gallery" data-option-key="filter">
                <li data-option-value="*" [ngClass]="{'active':  filterCountry== 'all'}"><a (click)="filerCountry('all')"><i class="fa fa-th"></i> <span>Tất cả</span></a></li>
                <li data-option-value=".format-jancar" [ngClass]="{'active': filterCountry== 'japan'}"><a (click)="filerCountry('japan')"><i class="fa fa-car"></i> <span>Xe Nhật</span></a></li>
                <li data-option-value=".format-korcar" [ngClass]="{'active': filterCountry== 'korea'}"><a (click)="filerCountry('korea')"><i class="fa fa-car"></i> <span>Xe Hàn</span></a></li>
                <li data-option-value=".format-gercar" [ngClass]="{'active': filterCountry== 'germany'}"><a (click)="filerCountry('germany')"><i class="fa fa-car"></i> <span>Xe Đức</span></a></li>
                <li data-option-value=".format-usacar" [ngClass]="{'active': filterCountry== 'american'}"><a (click)="filerCountry('american')"><i class="fa fa-car"></i> <span>Xe Mỹ</span></a></li>
                <li data-option-value=".format-engcar" [ngClass]="{'active': filterCountry== 'england'}"><a (click)="filerCountry('england')"><i class="fa fa-car"></i> <span>Xe Anh</span></a></li>
                <li data-option-value=".format-itacar" [ngClass]="{'active': filterCountry== 'italy'}"><a (click)="filerCountry('italy')"><i class="fa fa-car"></i> <span>Xe Ý</span></a></li>
                <li data-option-value=".format-othercar" [ngClass]="{'active': filterCountry== 'other'}"><a (click)="filerCountry('other')"><i class="fa fa-car"></i> <span>Khác</span></a></li>
              </ul>
            </div>
            <div class="row">
                <ul class="sort-destination" data-sort-id="gallery">
                    <li *ngFor="let vMake of vehicleMakes; let i = index" class="col-md-2 col-sm-3 grid-item format-jancar images" >
                        <div class="grid-item-inner"> <a [routerLink]="['/', 'danh-sach-xe-oto', 'xe-' + vMake.slug]" data-rel="prettyPhoto" class="media-box"> <img src="{{vMake.logoUrl}}" alt={{vMake.name}}> </a>
                            <span style="text-align: center;"><h4 class="post-title">{{vMake.name}}</h4></span>
                        </div>
                    </li>
                </ul>
               </div>
        </div>
       </div>
  </div>
<!-- End Body Content -->