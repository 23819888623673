import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { BlogsService } from '../services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public b: any = {};
  constructor(
    private blogsService: BlogsService,

  ) {
    this.b.blogs = null;

    this.b.convertTimestamp = function (timestamp) {
      var date = new Date(timestamp);
      var year = date.getFullYear(); // 2020
      var month = date.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
      var day = date.getDate(); // 9
      var postDate = `${day}/${month}/${year}`
      return postDate
      // Resolve start-up logic
    }
  }

  ngOnInit(): void {
    this.blogsService.getBlogs()
      .then(blogs => {
        console.log(blogs)
        this.b.blogs = blogs;
        // // go to screen
        // this.activatedRoute.params.subscribe(params => {
        //   let slug = params['slug'];
        //   this.step(slug);
        //   console.log('slug', slug);
        // });
      })
      .catch(resp => {

      });
  }


}
