<!-- Utiity Bar -->
<div class="utility-bar main-content">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-8">
                <ol class="breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li class="active">Salon</li>
                </ol>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-4">
            </div>
        </div>
      </div>
</div>
<!-- Start Body Content -->
  <div class="main" role="main">
    <div id="content" class="content full padding-b0">
        <div class="container">
        <a href="#gmap" class="scrollto btn btn-default btn-sm pull-right"><i class="fa fa-map-marker"></i> Xem Map</a>
            <!-- <p>We have found 12 dealers matching your search zipcode</p> -->
            <div class="dealers-search-result">
                <div class="row">
                    <div *ngFor="let salon of salons; let i = index" class="col-md-4 col-sm-4 dealer-block">
                        <div class="dealer-block-inner" style="background-image:url(http://placehold.it/600x300&amp;text=IMAGE+PLACEHOLDER);">
                            <div class="dealer-block-cont">
                                <div class="dealer-block-info">
                                    <!-- <span class="label label-default">2.5 miles away</span> -->
                                    <span class="dealer-avatar"><img src="{{salon.logoUrl}}" alt=""></span>
                                    <h5><a href="#">{{salon.name}}</a></h5>
                                    <span class="meta-data">{{salon.slogan}}</span>
                                    <span class="meta-data">{{salon.address}}</span>
                                </div>
                                <div class="dealer-block-text">
                                    <p>{{salon.shortDescription}}</p>
                                    <div class="dealer-block-add">
                                        <span>Thành viên <strong>{{salon.joinDate}}</strong></span>
                                        <span>Tổng tin <strong>{{salon.totalListing}}</strong></span>
                                    </div>
                                </div>
                                <div class="text-align-center"><a href="#" class="btn btn-default">Chi tiết</a></div>
                               </div>
                        </div>
                    </div>
                <!-- <div class="spacer-40"></div> -->
                <!-- <div class="text-align-center"><a href="#" class="btn btn-default"><i class="fa fa-refresh"></i> Load More</a></div> -->
               </div>
        </div>
        <div class="spacer-40"></div>
        <div class="dealer-search-map">
            <div id="gmap" style="height:500px;"></div>
          </div>
    </div>
   </div>
<!-- End Body Content -->