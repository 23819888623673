    <!-- Utiity Bar -->
    <div class="utility-bar main-content">
    	<div class="container">
        	<div class="row">
            	<div class="col-md-8 col-sm-6 col-xs-8">
                    <ol class="breadcrumb">
                        <li><a [routerLink]="['/home']">Trang Chủ</a></li>
                        <li class="active">Tìm Xe Bạn Muốn</li>
                    </ol>
            	</div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                </div>
            </div>
      	</div>
    </div>
    <!-- Actions bar -->
    <div class="actions-bar tsticky">
     	<div class="container">
        	<div class="row">
            	<div class="col-md-3 col-sm-3 search-actions">
                	<ul class="utility-icons tools-bar">
                    	<li><a href="#"><i class="fa fa-star-o"></i></a>
                        	<div class="tool-box">
                            	<div class="tool-box-head">
                            		<a href="user-dashboard-saved-cars.html" class="basic-link pull-right">Xem tất cả</a>
                            		<h5>Tin đã lưu</h5>
                                </div>
                                <div class="tool-box-in">
                                	<ul class="listing tool-car-listing">
                                    	<li>
                                        	<div class="checkb"><input type="checkbox"></div>
                                            <div class="imageb"><a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']"><img src="./assets/images/model/toyota/camry.png" alt=""/></a></div>
                                            <div class="textb">
                                            	<a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']">Toyota Camry 2016 1.5AT</a>
                                                <span class="price">440 triệu</span>
                                           	</div>
                                            <div class="delete"><a href="#"><i class="icon-delete"></i></a></div>
                                        </li>
                                    	<li>
                                        	<div class="checkb"><input type="checkbox"></div>
                                            <div class="imageb"><a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']"><img src="./assets/images/model/toyota/prius.png" alt=""/></a></div>
                                            <div class="textb">
                                            	<a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']">Prius 2015 2.0AT</a>
                                                <span class="price">560 triệu</span>
                                           	</div>
                                            <div class="delete"><a href="#"><i class="icon-delete"></i></a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tool-box-foot">
                                	<a href="#" class="btn btn-xs btn-primary pull-right" data-toggle="modal" data-target="#loginModal">Đăng nhập</a>
                                	<a href="#" class="btn btn-xs btn-info">So sánh (2)</a>
                                </div>
                            </div>
                        </li>
                    	<li><a href="#"><i class="fa fa-folder-o"></i></a>
                        	<div class="tool-box">
                            	<div class="tool-box-head">
                            		<a href="user-dashboard-saved-searches.html" class="basic-link pull-right">Xem tất cả</a>
                            		<h5>Tìm kiếm đã lưu</h5>
                                </div>
                                <div class="tool-box-in">
                                	<ul class="listing tool-search-listing">
                                    	<li>
                                        	<div class="link"><a href="#">Tìm kiếm 1</a></div>
                                            <div class="delete"><a href="#"><i class="icon-delete"></i></a></div>
                                        </li>
                                    	<li>
                                        	<div class="link"><a href="#">Tìm kiếm 2</a></div>
                                            <div class="delete"><a href="#"><i class="icon-delete"></i></a></div>
                                        </li>
                                    	<li>
                                        	<div class="link"><a href="#">Tìm kiếm 3</a></div>
                                            <div class="delete"><a href="#"><i class="icon-delete"></i></a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tool-box-foot">
                                	<a href="#" class="btn btn-xs btn-primary pull-right" data-toggle="modal" data-target="#loginModal">Đăng nhập</a>
                                </div>
                            </div>
                        </li>
                    	<li><a href="#"><i class="fa fa-clock-o"></i></a>
                        	<div class="tool-box">
                            	<div class="tool-box-head">
                            		<h5>Tin vừa xem</h5>
                                </div>
                                <div class="tool-box-in">
                                	<ul class="listing tool-view-listing">
                                    	<li>
                                            <div class="imageb"><a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']"><img src="./assets/images/model/toyota/highlander.png" alt=""/></a></div>
                                            <div class="textb">
                                            	<a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']">Highlander 2018 3.6AT</a>
                                                <span class="price">765 triệu</span>
                                           	</div>
                                            <div class="save"><a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']"><i class="fa fa-star-o"></i></a></div>
                                        </li>
                                    	<li>
                                            <div class="imageb"><a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']"><img src="./assets/images/model/toyota/fortuner.png" alt=""/></a></div>
                                            <div class="textb">
                                            	<a [routerLink]="['/chi-tiet-tin', 'advert.vehicleModelId', 'advert.id']">Fortuner 2013 4.0MT</a>
                                                <span class="price">660 triệu</span>
                                           	</div>
                                            <div class="save"><a href="#"><i class="fa fa-star-o"></i></a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tool-box-foot">
                                	<a href="#" class="btn btn-xs btn-primary pull-right" data-toggle="modal" data-target="#loginModal">Đăng nhập</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9 col-sm-9">
                    <div class="btn-group pull-right results-sorter">
                        <button type="button" class="btn btn-default listing-sort-btn">Sắp xếp</button>
                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          <span class="caret"></span>
                          <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li><a href="#">Tin mới trước</a></li>
                            <li><a href="#">Giá (Cao xuống Thấp)</a></li>
                          	<li><a href="#">Giá (Thấp lên Cao)</a></li>
                          	<li><a href="#">Đời xe (Mới đến Cũ)</a></li>
                          	<li><a href="#">Đời xe (Cũ tới Mới)</a></li>
                        </ul>
                  	</div>

                    <div class="toggle-view view-count-choice pull-right">
                        <div class="btn-group">
                            <a href="#" class="btn btn-default">12</a>
                            <a href="#" class="btn btn-default active">24</a>
                            <a href="#" class="btn btn-default">48</a>
                        </div>
                    </div>

                    <div class="toggle-view view-format-choice pull-right">
                        <div class="btn-group">
                            <a href="#" class="btn btn-default active" id="results-list-view"><i class="fa fa-th-list"></i></a>
                            <a href="#" class="btn btn-default" id="results-grid-view"><i class="fa fa-th"></i></a>
                        </div>
                    </div>
                    <!-- Small Screens Filters Toggle Button -->
                    <button class="btn btn-default visible-xs" id="Show-Filters">Hiện Tiêu Chí Tìm Kiếm</button>
                </div>
            </div>
      	</div>
    </div>
    <!-- Start Body Content -->
  	<div class="main" role="main">
    	<div id="content" class="content full">
        	<div class="container">
            	<div class="row">
                    <!-- Search Filters -->
                    <div class="col-md-3 search-filters" id="Search-Filters">
                    	<div class="tbsticky filters-sidebar">
                            <h3>Tiêu Chí Tìm Kiếm</h3>
                            <div class="accordion" id="toggleArea">
                                <div class="btn-group btn-toggle vehicle-status-btn-group">
                                    <button (click)="onSearchALlVehicle()" [ngClass]="{'btn-toogle-active': vm.search.vehicleStatus == ALL_VEHICLE_SUBFIX}" class="btn btn-sm btn-default btn-vehicle-all">Tất Cả</button>
                                    <button (click)="onSearchNewVehicle()" [ngClass]="{'btn-toogle-active': vm.search.vehicleStatus == NEW_VEHICLE_SUBFIX}" class="btn btn-sm btn-default btn-vehicle-status" >Xe Mới</button>
                                    <button (click)="onSearchOldVehicle()" [ngClass]="{'btn-toogle-active': vm.search.vehicleStatus == USED_VEHICLE_SUBFIX}" class="btn btn-sm btn-default btn-vehicle-status btn-vehicle-used">Xe Qua Sử Dụng</button>
                                </div>
                                 <!-- Filter by Place -->
                                <select [(ngModel)]="vm.search.placeId" [ngModelOptions]="{standalone: true}" (change)="onSelectPlace(vm.search.placeId)" name="Exterior Color" class="form-control selectpicker-bk">
                                    <option value="-">Tất cả Thành Phố</option>
                                    <option value="tp-ho-chi-minh">Hồ Chí Minh</option>
                                    <option value="ha-noi">Hà Nội</option>
                                    <option value="hai-phong">Hải Phòng</option>
                                    <option value="nghe-an">Nghệ An</option>
                                    <option value="da-nang">Đà Nẵng</option>
                                    <option value="dong-thap">Đồng Tháp</option>
                                 </select>
                                <!-- Filter by Make -->
                                <select [(ngModel)]="vm.search.vehicleMakeId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleMake(vm.search.vehicleMakeId)" class="form-control selectpicker-bk">
                                    <option value="-">Tất cả thương hiệu</option>
                                    <option *ngFor="let entity of vm.vehicleMakes; let i = index" [value]="entity.id">
                                        {{entity.name}}
                                    </option>
                                </select>
                                <!-- Filter by Model -->
                                <select [(ngModel)]="vm.search.vehicleModelId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleModel(vm.search.vehicleModelId)" [disabled]="vm.search.vehicleMakeId == '-'" class="form-control selectpicker-bk">
                                    <option value="-">Tất cả mẫu xe</option>
                                    <option *ngFor="let entity of vm.vehicleModels; let i = index" [value]="entity?.id">
                                        {{entity?.name}}
                                    </option>
                                </select>
                                <!-- Filter by Body type -->
                                <select [(ngModel)]="vm.search.vehicleBodyTypeId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleBodyType()" class="form-control selectpicker-bk">
                                    <option value="-">Tất cả kiểu xe</option>
                                    <option *ngFor="let entity of vm.vehicleBodyTypes; let i = index" [value]="entity.id">
                                        {{entity.name}}
                                    </option>
                                </select>
                                <!-- Filter by Year -->
                                <div class="form-inline">
                                    <div class="form-group">
                                        <select [(ngModel)]="vm.search.vehicleYearMin" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleYearMin()" class="form-control selectpicker-bk">
                                            <option value="-">Từ Năm&nbsp;</option>
                                            <option *ngFor="let entity of vm.vehicleYearMins; let i = index" [value]="entity">
                                                {{entity}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group last-child pull-right" style="margin-left: 35px">
                                        <select [(ngModel)]="vm.search.vehicleYearMax" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleYearMax()" class="form-control selectpicker-bk">
                                            <option value="-">Đến Năm</option>
                                            <option *ngFor="let entity of vm.vehicleYearMaxs; let i = index" [value]="entity">
                                                {{entity}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <hr/>
                                <!-- Filter by Price -->
                                <div id="price">
                                    <label>Khoảng Giá:&nbsp;</label>
                                    <strong>{{convertPrice2VietNameseRange(vm.search.minPrice)}}</strong> <strong>  -  </strong> <strong>{{convertPrice2VietNameseRange(vm.search.maxPrice)}}{{vm.search.maxPrice == vm.search.maxPriceCfg ? '+': ''}}</strong>
                                    <ng5-slider [(value)]="vm.search.minPrice" [(highValue)]="vm.search.maxPrice" (userChangeEnd)="onUserChangePriceEnd($event)" [options]="priceOptions"></ng5-slider>
                                </div>
                                <hr/>
                                <!-- Filter by km use -->
                                <div *ngIf="vm.search.vehicleStatus != 'moi'" id="km-used">
                                    <label>Số Km Tối Đa:&nbsp;</label>
                                    <strong></strong> <strong>{{vm.search.maxKmUsed | number}} Km{{vm.search.maxKmUsed == vm.search.maxKmUsedCfg ? '+': ''}}</strong>
                                    <ng5-slider [(value)]="vm.search.maxKmUsed" (userChangeEnd)="onUserChangeKmUsedEnd($event)" [options]="kmUsedoptions"></ng5-slider>
                                </div>
                                <hr *ngIf="vm.search.vehicleStatus != 'moi'"/>
                                <!-- Filter by Transmission -->
                                <div class="accordion-group">
                                    <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseSix">HỘP SỐ<i class="fa fa-angle-down"></i> </a> </div>
                                    <div id="collapseSix" class="accordion-body collapse">
                                        <div class="accordion-inner">
                                            <ul class="filter-options-list list-group">
                                                <!-- <li *ngFor="let entry of vm.vehicleTransmissions; let i = index" class="list-group-item"><span class="badge">4</span><label><input type="checkbox" [(ngModel)]="vm.search.vehicleTransmissionIds[i]" [ngModelOptions]="{standalone: true}" (change)="onSelectVhicleTransmission()" [value]="entry?.id"> {{entry?.name}}</label></li> -->
                                                <li *ngFor="let entry of vm.vehicleTransmissions; let i = index" class="list-group-item"><span class="badge">4</span><label><input type="checkbox" [(ngModel)]="entry.isSelected" [ngModelOptions]="{standalone: true}" (change)="onSelectVhicleTransmission()" [value]="entry?.id"> {{entry?.name}}</label></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- Filter by Location -->
                                <div class="accordion-group">
                                    <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseNine">XUẤT XỨ<i class="fa fa-angle-down"></i> </a> </div>
                                    <div id="collapseNine" class="accordion-body collapse">
                                        <div class="accordion-inner">
                                            <select [(ngModel)]="vm.search.vehicleSourceId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleSource()" class="form-control selectpicker-bk">
                                                <option value="-">Tất cả</option>
                                                <option *ngFor="let entity of vm.vehicleSources; let i = index" [value]="entity.id">
                                                    {{entity.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- Filter by Color -->
                                <div class="accordion-group">
                                    <div class="accordion-heading togglize"> <a class="accordion-toggle" data-toggle="collapse" data-parent="#" href="#collapseTen">MÀU XE<i class="fa fa-angle-down"></i> </a> </div>
                                    <div id="collapseTen" class="accordion-body collapse">
                                        <div class="accordion-inner">
                                            <ul class="filter-options-list list-group color-options">
                                                <li *ngFor="let entry of vm.vehicleExteriorColors; let i = index" class="list-group-item">
                                                    <span class="badge exterior-color" [ngStyle]="{'background-color': entry.hexaColor}"></span>
                                                    <label><input type="checkbox" [(ngModel)]="entry.isSelected" [ngModelOptions]="{standalone: true}" (change)="onSelectVhicleExteriorColor()" [value]="entry?.id"> {{entry?.name}}</label>
                                                    <!-- <label><input type="checkbox"[(ngModel)]="vm.search.vehicleExteriorColorIds[i]" [ngModelOptions]="{standalone: true}" [value]="entry?.id"> {{entry?.name}}</label> -->
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Toggle -->
                            <button (click)="onReset()" class="btn-default btn-sm btn" style="    margin-left: 30px;"><i class="fa fa-refresh"></i> Thiết Lập Lại</button>
                            <button (click)="onSaveSearch()" class="btn-primary btn-sm btn pull-right"><i class="fa fa-folder-o"></i> Lưu Tìm Kiếm</button>
                        </div>
                    </div>

                    <!-- Listing Results -->
                    <div class="col-md-9 results-container">
                        <div class="results-container-in">
                        	<div class="waiting" style="display:none;">
                            	<div class="spinner">
                                  	<div class="rect1"></div>
                                  	<div class="rect2"></div>
                                  	<div class="rect3"></div>
                                  	<div class="rect4"></div>
                                  	<div class="rect5"></div>
                                </div>
                            </div>
                        	<div id="results-holder" class="results-list-view">
                            	<!-- Result Item -->
                            	<div *ngFor="let advert of adverts; let i = index" class="result-item format-standard">
                                	<div class="result-item-image">
                                		<a [routerLink]="['/chi-tiet-tin', advert.vehicleModelId, advert.id]" class="media-box" style="background-color: azure;"><span style="text-align: center; display: block;"><img [src]="advert.photoCover" style="width: auto; height: auto; background-color:azure;" alt=""></span></a>
                                        <span class="label label-default vehicle-age">{{advert.vehicleYearRelease}}</span>
                                        <span class="label label-success premium-listing">Tin Ưu Tiên</span>
                                        <div class="result-item-view-buttons">
                                        	<a href="https://www.youtube.com/watch?v=P5mvnA4BV7Y" data-rel="prettyPhoto"><i class="fa fa-play"></i> Xem video</a>
                                        	<a [routerLink]="['/chi-tiet-tin', advert.vehicleModelId, advert.id]"><i class="fa fa-plus"></i> Xem Chi Tiết</a>
                                        </div>
                                    </div>
                                	<div class="result-item-in">
                                     	<h4 class="result-item-title"><a [routerLink]="['/chi-tiet-tin', advert.vehicleModelId, advert.id]">{{advert.title}}</a></h4>
                                		<div class="result-item-cont">
                                            <div class="result-item-block col1">
                                                <p>{{advert.description}}</p>
                                            </div>
                                            <div class="result-item-block col2">
                                                <div class="result-item-pricing">
                                                    <div class="price">{{convertPrice2VietNamese(advert.price)}}</div>
                                                </div>
                                                <div class="result-item-action-buttons">
                                                    <a (click) = "this.SavedAdvert(advert.id)" class="btn btn-default btn-sm"><i class="fa fa-star-o"></i> Lưu</a>
                                                    <a [routerLink]="['/chi-tiet-tin', advert.vehicleModelId, advert.id]" class="btn btn-default btn-sm"><i class="fa fa-random"></i> So Sánh</a><br>
                                                    <a href="#" class="distance-calc"><i class="fa fa-map-marker"></i>{{advert.place}}</a>
                                                </div>
                                            </div>
                                       	</div>
                                        <div class="result-item-features">
                                            <ul class="list-inline">
                                                <li><i class="fa fa-car"></i> {{advert.vehicleBodyTypeName}}</li>
                                                <li><i class="fa fa-paint-brush"></i>Màu {{advert.vehicleExteriorColorName}}</li>
                                                <li><i class="fa fa-map-pin"></i> {{advert.vehicleTransmissionName}}</li>
                                                <li><i class="fa fa-gears"></i> Động cơ {{advert.vehicleEngineName}}</li>
                                                <li><i class="fa fa-road"></i> Đã đi {{advert.kmUsed | number}} km</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- end result list -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 col-md-offset-3" style="text-align: center;">
                        <button *ngIf="vm.search.remainItems > 0" type="button" class="btn btn-primary" (click)="showMoreItem()">Xem thêm {{vm.search.remainItems}} Xe</button>
                    </div>
               	</div>
            </div>
        </div>
   	</div>
