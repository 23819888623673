import { Component, Injectable, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from './api.service';
import { SharedVarService } from './share-var.service';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var FB: any;
declare var jQuery:any;

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  auth2: any;
  // @ViewChild('loginRef', { static: true }) loginElement: ElementRef;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private _location: Location,
    private service: SharedVarService,
    private toastr: ToastrService

    ) { }

  loginFacebook = (data) => this.apiService.post('users/login/facebook', data);
  loginGoogle = (data) => this.apiService.post('users/login/google', data);
  register = (data) => this.apiService.post('users/register', data);
  login = (data) => this.apiService.post('users/login', data);


  // button login facebook
  public async submitLoginFacebook(closeModal?) {
    // FB.login();
    FB.login(async (response) => {
      if (response.authResponse) {
        let data = {
          "access_token": response.authResponse.accessToken,
          "deviceId": "string",
          "deviceType": "web"
        }
        return this.getUserLoginFacebook(data)
          .then(userModels => {
            if(userModels){
              this.service.setValue(true);
            }
            if (closeModal){
              jQuery("#modalUser").modal("hide");
            }
            this._location.back();
          });
      }
      else {
        alert(JSON.stringify('User login failed'));
      }
    });
  }

  // button login google
  public submitLoginGoogle(loginElement, closeModal?) {
    this.auth2.attachClickHandler(loginElement.nativeElement, {},
      (googleUser) => {
        let data = {
          "access_token": googleUser.getAuthResponse().access_token,
          "deviceId": "string",
          "deviceType": "web"
        }
        return this.getUserLoginGoogle(data)
          .then(userModels => {
            if(userModels){
              this.service.setValue(true);
            }
            if (closeModal){
              jQuery("#modalUser").modal("hide");
            }
            this._location.back();
          });
        //YOUR CODE HERE
      }, (error) => {
        alert(JSON.stringify(error, undefined, 2));
      });
  }

  // SDK facebook library
  public facebookSDK() {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '609825426259280',
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  // SDK google library
  public googleSDK(loginElement, closeModal?) {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: '731427107071-qav5u2pck4ih48li8ret8pdun39alsnp.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.submitLoginGoogle(loginElement, closeModal);
      });
    }
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      // if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }

  // cache login facebook
  public getUserLoginFacebook(data) {
    let _uModels = "_u";
    let rawUserModel = localStorage.getItem(_uModels);
    if (rawUserModel) {
      // return Observable.create((observer) => observer.next(JSON.parse(rawVehicleModel)));
      return new Promise((resolve) => resolve(JSON.parse(rawUserModel)));
    } else {
      return this.loginFacebook(data)
        .then(userModels => {
          localStorage.setItem(_uModels, JSON.stringify(userModels));
          return userModels;
        });
    }
  }

  // cache login google
  public getUserLoginGoogle(data) {
    let _uModels = "_u";
    let rawUserModel = localStorage.getItem(_uModels);
    if (rawUserModel) {
      // return Observable.create((observer) => observer.next(JSON.parse(rawVehicleModel)));
      return new Promise((resolve) => resolve(JSON.parse(rawUserModel)));
    } else {
      return this.loginGoogle(data)
        .then(userModels => {
          localStorage.setItem(_uModels, JSON.stringify(userModels));
          return userModels;
        });
    }
  }

  // cache login 
  public async getUserLogin(data) {
    let _uModels = "_u";
    let rawUserModel = localStorage.getItem(_uModels);
    if (rawUserModel) {
      // return Observable.create((observer) => observer.next(JSON.parse(rawVehicleModel)));
      return new Promise((resolve) => resolve(JSON.parse(rawUserModel)));
    } else {
      return this.login(data)
        .then(userModels => {
          localStorage.setItem(_uModels, JSON.stringify(userModels));
          return userModels;
        });
    }
  }

  public async registerUser(from){
    console.log(from)
    if (!from.checkbox) {
      return alert("Bạn Chưa Chấp Nhận Điều Khoản");
    }
    from.deviceType = "WEB";
    let data = {
      'phone' : from.phone,
      'email' : from.email,
      'password' : from.password,
      'deviceType' : "WEB",
    }
    let userModels = await this.register(data);
    if(userModels.statusCode === 'success') {
      this.toastr.success('Đăng ký thành công', undefined);
      this._location.back();
    }
  }

  //login password 
  public async loginUser(from, closeModal){
    delete from.checkbox;
    from.deviceType = "WEB";
    let userModels = await this.getUserLogin(from);
    if(userModels){
      this.service.setValue(true);
    }
    if (closeModal){
      jQuery("#modalUser").modal("hide");
    }
    this._location.back();
  }

  // Is login 
  public isLogin() {
    try {
      let userModel:any = localStorage.getItem("_u");
      userModel = JSON.parse(userModel);
      if(userModel){
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

}
