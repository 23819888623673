import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public _generateYear(yearMax?, yearMin?) {
    let currentYear = (yearMax && yearMax != '-') ? Number(yearMax): (new Date().getFullYear() + 1);
    yearMin = (yearMin && yearMin != '-') ?  Number(yearMin): 1990;
    let result = [];
    for (let i = currentYear; i >= yearMin; i--) {
      result.push(i);
    }
    return result;
  }

  public _reloadOption(source, key, isVerify) {
    // when use already select vehicleTemplete we don't re-select
    // if (isVerify && this.vm.advert.vehicleTemplateId) {
    //   return null;
    // }
    for (let vObj of source) {
      if (key == vObj.id) {
        return vObj;
      }
    }
    return null;
  }

  public _triggerClick(nextTo){
    let element: HTMLElement = document.getElementById(nextTo) as HTMLElement;
    element.click()
  }

  public _convertCheckboxSelectionToTarget(targetIds, sourceObject) {
    const result = [];
    if (!targetIds.lenght) {
      return result;
    }
    let index = 0;
    for (let value of targetIds) {
      if (value) { // is true
        result.push(sourceObject[index]);
      }
      index++;
    }

    return result;
  }

  public _filterSelectedEntry(sourceObject) {
    const result = [];
    if (!sourceObject) {
      return result;
    }
    for (let entry of sourceObject) {
      if (entry.isSelected) { // is true
        result.push(entry);
      }
    }

    return result;
  }

  public _loadScript(url) {
    console.log('preparing to load...', url);
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('footer')[0].appendChild(node);
  }

  public _loadScripInitScript() {
    this._loadScript('assets/js/init.js');
  }

  public convertPrice2VietNamese(price) {
    return this._convertPrice2VietNamese(price, 'tỉ', 'triệu', ' ');
  }

  public convertPrice2VietNameseRange(price) {
    if (!price) {
      return "0 đồng";
    }
    const unit = 1000;
    let billion = Math.floor(price / unit);
    let million = price - (billion * unit);
    if (price >= unit) {
      if (million > 0) {
        return (price / unit).toFixed(3) + " tỉ";
      }
      // without milion
      return (price / unit).toFixed(1) + " tỉ";
    } else {
      return price + " triệu";
    }
  }

  // 1-ti-200-trieu
  public convertPriceVietNameseToNumber(price) {
    let [billion, million] = ["0", "0"];
    // replace all '-'
    let raw = price.replace(/-/g, '');
    if (price.indexOf('ti') > -1) {
      raw = raw.split("ti");
      billion = raw[0];
      million = raw[1].replace('trieu', '');
    } else {
      million = raw.replace('trieu', '');
    }

    return Number(billion) * 1000 + Number(million);
  }

  public _convertPrice2VietNamese(price, billionUnit, milionUnit, seperateChar) {
    if (!price) {
      return "0"
    }
    const unit = 1000;
    let billion = Math.floor(price / unit);
    let million = price - (billion * unit);
    if (price >= unit) {
      if (million > 0) {
        return billion + seperateChar + billionUnit + seperateChar + million + seperateChar + milionUnit;
      }
      // without milion
      return billion + seperateChar + billionUnit;
    } else {
      return price + seperateChar + milionUnit;
    }
  }


}
