import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";

// self
import { AdvertService } from '../services/advert.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-advert-management',
  templateUrl: './advert-management.component.html',
  styleUrls: ['./advert-management.component.css']
})
export class AdvertManagementComponent implements OnInit {
  userId: string = '';
  userAdverts: any = [];
  savedAdverts: any = [];
  savedSearchs: any = [];
  checkBoxUserAdverts: any = [];
  checkBoxSavedAdverts: any = [];
  checkBoxSavedSearchs: any = [];
  public t: any = {};
  public um: any = {};
  public sam: any = {};
  public STEP_NAME: string = 'quan-ly-chung';


  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private advertService: AdvertService
  ) {
    this.um.user = {};
    // convert time
    this.t.convertTimestamp = function (timestamp) {
      var date = new Date(timestamp);
      var year = date.getFullYear(); // 2020
      var month = date.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
      var day = date.getDate(); // 9
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var timePostAvert = `${day}/${month}/${year}  ${hour}:${minutes}`
      return timePostAvert
    }
    // custom body
    this.sam.modelKeyValue = (key, value) => {
      let obj = new Object();
      obj[key] = value;
      return obj
    }


  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(param => {
      this.userId = param.get('userId');
      console.log(this.userId)
      this.getUserAdvert();
      this.getSavedAdvert();
      this.getSavedSearch();
    });

    this.route.params.subscribe(params => {
      let userModel: any = localStorage.getItem("_u");
      if (userModel) {
        userModel = JSON.parse(userModel);
        this.um.user = userModel;
      }
      
      // set for _triggerClick after loading script
      let slug = params['slug'];
      this.STEP_NAME = slug;
      // this.step(slug);
      console.log('just go to:: ', slug);
      this._triggerClick(this.STEP_NAME);
     
    });
    this.getUserAdvert()
  }
  // clear data selection when click tab
  public clearData(){
    this.checkBoxSavedAdverts = []
    this.checkBoxSavedSearchs = []
    this.checkBoxUserAdverts = []
  }

  public async getUserAdvert() {
    this.clearData()
    this.userAdverts = await this.advertService.getUserAdvert(this.userId);
  }

  public async getSavedAdvert() {
    this.clearData()
    this.savedAdverts = await this.advertService.getSavedAdvert(this.userId);
  }

  public async getSavedSearch() {
    this.clearData()
    this.savedSearchs = await this.advertService.getSavedSearch(this.userId);
  }

  public async getGeneralManagement() {
    this.getUserAdvert();
    this.getSavedAdvert();
    this.getSavedSearch();
  }

  // Update notify Saved Searchs
  public async onChangeUpdateNotify(savedSearchId, newValue) {
    let isReciveNofitication = newValue === 'Bật' ? true : false;
    this.advertService.updateSavedSearch(savedSearchId, this.sam.modelKeyValue('isReciveNofitication', isReciveNofitication));
  }

  public async onChangeCheckBoxUserAdvert(userAdvertId, newValue) {
    if (newValue.currentTarget.checked) {
      this.checkBoxUserAdverts.push(userAdvertId)
    }
    else {
      this.removeItemArrayByValue(this.checkBoxUserAdverts, userAdvertId)
    }
  }

  public async onChangeCheckBoxSavedAdvert(savedAdvertId, newValue) {
    if (newValue.currentTarget.checked) {
      this.checkBoxSavedAdverts.push(savedAdvertId)
    }
    else {
      this.removeItemArrayByValue(this.checkBoxSavedAdverts, savedAdvertId)
    }
  }

  public async onChangeCheckBoxSavedSearch(savedSearchId, newValue) {
    if (newValue.currentTarget.checked) {
      this.checkBoxSavedSearchs.push(savedSearchId)
    }
    else {
      this.removeItemArrayByValue(this.checkBoxSavedSearchs, savedSearchId)
    }
  }

  // delete saved advert
  public async delSavedAdvert(i, saveAdsId) {
    this.savedAdverts.splice(i, 1);
    this.advertService.updateSavedAdvert(saveAdsId, this.sam.modelKeyValue('isDelete', true));
  }

  // delete saved advert
  public async delSavedSearch(i, saveSearchId) {
    this.savedSearchs.splice(i, 1);
    this.advertService.updateSavedSearch(saveSearchId, this.sam.modelKeyValue('isDelete', true));
  }

  // delete user advert
  public async delUserAdvert(i, userAdvertId) {
    this.userAdverts.splice(i, 1);
    this.advertService.updateUserAdvert(userAdvertId, this.sam.modelKeyValue('isDelete', true));
  }

  // remove selection list User Advers
  public async delListUserAdverts(){
    let length = this.checkBoxUserAdverts.length
    while(length--){
      await this.delUserAdvert(length, this.checkBoxUserAdverts[length])
    }
  }

  // remove selection list Saved Adverts
  public async delListSavedAdverts(){
    let length = this.checkBoxSavedAdverts.length
    while(length--){
      await this.delSavedAdvert(length, this.checkBoxSavedAdverts[length])
    }
  }

  // remove selection list Saved Searchs
  public async delListSavedSearchs(){
    let length = this.checkBoxSavedSearchs.length
    while(length--){
      await this.delSavedSearch(length, this.checkBoxSavedSearchs[length])
    }
  }

  // global remove item from array by value
  private removeItemArrayByValue(arr, any?) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
  }

  private _triggerClick(nextTo) {
    let element: HTMLElement = document.getElementById(nextTo) as HTMLElement;
    element.click()
  }

}
