 <!-- Start Page header -->
 <!-- <div class="page-header parallax">
    <div id="contact-map" style="width:100%;height:300px; margin-top: 80px;">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3932.7472854986277!2d106.66862787179839!3d10.800509040941071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752928b68fbc3f%3A0xbd7fe6ce60b2676e!2sDicom%20interactive!5e0!3m2!1sen!2sus!4v1587312134212!5m2!1sen!2sus"
        width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" allowfullscreen></iframe>
    </div>
</div> -->
<!-- Utiity Bar -->
<div class="utility-bar main-content">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-8">
                <ol class="breadcrumb">
                    <li><a href="index.html">Trang Chủ</a></li>
                    <li class="active">Liên Hệ</li>
                </ol>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-4">
            </div>
        </div>
      </div>
</div>
<!-- Start Body Content -->
<div class="main" role="main">
    <div id="content" class="content full">
          <div class="container">
            <div class="listing-header margin-40">
                <h2>Liên Hệ</h2>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-4">
                        <!-- <span><i class="fa fa-home"></i></span> <b>Dicom Interactive.</b><br>
                        202 Hoàng Văn Thụ, Phường 9,<br>
                        Phú Nhuận, Hồ Chí Minh, Vietnam<br><br>
                        <i class="fa fa-phone"></i> <b>0907.50.66.14</b><br>
                        <i class="fa fa-envelope"></i> <a href="mailto:company@dicom-interactive.com">company@dicom-interactive.com</a><br><br>
                        <i class="fa fa-home"></i> <b>Thứ Hai - Thứ Sáu / 9:00 - 18:00</b><br> -->
                </div>
                <div class="col-md-9 col-sm-8">
                       <form #contactUsForm="ngForm" id="contactform" name="contactform" class="contact-form clearfix">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label [hidden]="fFullName.valid || fFullName.pristine" class="pull-right top-alert-danger">Vui lòng nhập tên của bạn</label>
                                    <input [(ngModel)]="vm.contact.fullName"  #fFullName="ngModel" name="fFullName" type="text" id="fname" class="form-control input-lg" placeholder="Họ và Tên*" required>
                                </div>
                                <div class="form-group">
                                    <label [hidden]="fEmail.valid || fEmail.pristine" class="pull-right top-alert-danger">Vui lòng nhập email liên hệ</label>
                                    <input [(ngModel)]="vm.contact.email"  #fEmail="ngModel" name="fEmail" type="email" id="email"  class="form-control input-lg" placeholder="Email*" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                                </div>
                                <div class="form-group">
                                    <label [hidden]="fPhone.valid || fPhone.pristine" class="pull-right top-alert-danger">Vui lòng nhập số điện thoại liên lạc</label>
                                    <input [(ngModel)]="vm.contact.phone"  #fPhone="ngModel" name="fPhone" type="text" id="phone" class="form-control input-lg" placeholder="Số Điện Thoại">
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <textarea [(ngModel)]="vm.contact.content" [ngModelOptions]="{standalone: true}" cols="6" rows="8" id="comments" name="comments" class="form-control input-lg" placeholder="Nội Dung"></textarea>
                                </div>
                                <input (click)="sendContact()" id="submit" name="submit" type="button" class="btn btn-primary btn-lg pull-right" value="Gửi Ngay!" [disabled]="!contactUsForm.form.valid">
                              </div>
                           </div>
                    </form>
                    <div class="clearfix"></div>
                    <div id="message"></div>
                </div>
              </div>
        </div>
      </div>
 </div>
<!-- End Body Content -->
