<!-- Utiity Bar -->
<!-- Utiity Bar -->
<div class="utility-bar main-content">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-8">
                <ol class="breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li><a [routerLink]="['/blog']">Blog</a></li>
                    <li class="active">{{sp.slug}}</li>
                </ol>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-4">
            </div>
        </div>
      </div>
</div>
<!-- Start Body Content -->
<div class="main" role="main">
    <div id="content" class="content full">
        <div class="container">
            <div class="row">
                <div class="col-md-9 single-post">
                    <!-- <header class="single-post-header clearfix">
                        <div class="post-actions">
                            <div class="post-date">November 27, 2014</div>
                            <div class="comment-count"><a href="{{sp.featureImage}}"><i class="icon-dialogue-text"></i>
                                    20</a></div>
                        </div>
                        <h2 class="post-title">{{sp.title }}</h2>
                    </header> -->
                    <header class="post-full-header">
    
                        <section class="post-full-tags">
                            <a>{{sp.slug}}</a>
                        </section>
    
                        <h1 class="post-full-title h1-single-post">{{sp.title}}</h1>
    
                        <p class="post-full-custom-excerpt">{{sp.customExcerpt}}</p>
    
                        <div class="post-full-byline">
                        </div>
                    </header>
    
                    <figure class="post-full-image">
                        <img srcset="{{sp.featureImage}} 200w,
                                    {{sp.featureImage}} 500w,
                                    {{sp.featureImage}} 900w,
                                    {{sp.featureImage}} 1900w" sizes="(max-width: 600px) 200px,
                                (max-width: 1070px) 1070px,
                                    1900px" src="{{sp.featureImage}}" alt="Writing posts with Ghost ✍️">
                    </figure>
    
                    <!-- <div class="post-content" ng-bind-html="sp.html"> -->
                    <section class="post-full-content">
                        <div class="post-content" [innerHTML]="sp.html">
                        </div>
                    </section>
                    <!-- </div> -->
                    
                </div>
                <!-- Start Sidebar -->
                <div class="col-md-3 sidebar">
                    <div class="widget sidebar-widget widget_recent_posts">
                        <h3 class="widgettitle">Recent Posts</h3>
                        <ul>
                            <li>
                                <a ><img src="http://placehold.it/200x200&amp;text=IMAGE+PLACEHOLDER"
                                        alt="" class="img-thumbnail"></a>
                                <h5><a >2015 Proin enim quam, vulputate</a></h5>
                                <div class="post-actions">
                                    <div class="post-date">October 30, 2014</div>
                                </div>
                            </li>
                            <li>
                                <a ><img src="http://placehold.it/200x200&amp;text=IMAGE+PLACEHOLDER"
                                        alt="" class="img-thumbnail"></a>
                                <h5><a >Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                                </h5>
                                <div class="post-actions">
                                    <div class="post-date">October 30, 2014</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="widget sidebar-widget widget_recent_reviews">
                        <h3 class="widgettitle">Latest Reviews</h3>
                        <div class="post-block post-review-block">
                            <div class="review-status">
                                <strong>3.6</strong>
                                <span>Out of 5</span>
                            </div>
                            <h3 class="post-title"><a >Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit</a></h3>
                        </div>
                        <div class="post-block post-review-block">
                            <div class="review-status">
                                <strong>4.1</strong>
                                <span>Out of 5</span>
                            </div>
                            <h3 class="post-title"><a >Curabitur nec nulla lectus, non
                                    hendrerit lorem porttitor eget</a></h3>
                        </div>
                        <div class="post-block post-review-block">
                            <div class="review-status">
                                <strong>5.0</strong>
                                <span>Out of 5</span>
                            </div>
                            <h3 class="post-title"><a >2014 Proin enim quam, vulputate at
                                    lobortis quis, condimentum at justo</a></h3>
                        </div>
                    </div>
                    <div class="widget sidebar-widget widget_categories">
                        <h3 class="widgettitle">Post Categories</h3>
                        <ul>
                            <li><a >Financial</a> (10)</li>
                            <li><a >Trial run</a> (12)</li>
                            <li><a >New Launch</a> (34)</li>
                            <li><a >Advice</a> (14)</li>
                            <li><a >Uncategorized</a> (114)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
       
        <!-- <div ng-bind-html = "sp.html">
                </div> -->
    </div>
</div>