import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root'
})
export class SalonService {

  constructor(private apiService: ApiService) { }

  public getSalonsByRegion = (region) => this.apiService.get('salons?filter[where][region]=' + region);
  

}
