
    <!-- Utiity Bar -->
    <div class="utility-bar main-content">
    	<div class="container">
        	<div class="row">
            	<div class="col-md-8 col-sm-6 col-xs-8">
                    <ol class="breadcrumb">
                        <li><a [routerLink]="['/home']">Trang Chủ</a></li>
                        <li class="active">Bán Xe</li>
                    </ol>
            	</div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                </div>
            </div>
      	</div>
    </div>
    <!-- Start Body Content -->
  	<div class="main" role="main">
    	<div id="content" class="content full">
        	<div class="container">
            	<div class="row">
                	<div class="col-md-4 col-sm-4 listing-form-wrapper">
                    	<!-- SIDEBAR -->
                    	<div class="listing-form-steps-wrapper tbssticky">
                    		<!-- AD LISTING PROGRESS BAR -->
                        	<div class="listing-form-progress">
                                <div class="progress-label"> <span>Hoàn Thành</span> </div>
                            	<div class="progress progress-striped">
                                    <div class="progress-bar progress-bar-primary" data-appear-progress-animation=""></div>
                              	</div>
                            </div>
                    		<!-- AD LISTING FORM STEPS -->
                            <ul class="listing-form-steps">
                                <li class="active" data-target="#listing-add-form-one" data-toggle="tab">
                                    <a id="chon-xe"  [routerLink]="['/dang-ban-xe-oto', 'chon-xe']">
                                        <span class="step-state"></span>
                                        <span class="step-icon"><i class="fa fa-car"></i></span>
                                        <strong class="step-title">Chọn Xe</strong>
                                        <span class="step-desc">Từ danh sách có sẵn</span>
                                    </a>
                                </li>
                                <li data-target="#listing-add-form-two" data-toggle="tab">
                                    <a id="chon-tinh-nang-xe" [routerLink]="['/dang-ban-xe-oto', 'chon-tinh-nang-xe']">
                                        <span class="step-state"></span>
                                        <span class="step-icon"><i class="fa fa-list-alt"></i></span>
                                        <strong class="step-title">Chọn Tính Năng</strong>
                                        <span class="step-desc">Những tính năng xe bạn có</span>
                                    </a>
                                </li>
                                <li data-target="#listing-add-form-three" data-toggle="tab">
                                    <a id="them-thong-tin-xe" [routerLink]="['/dang-ban-xe-oto', 'them-thong-tin-xe']"> 
                                        <span class="step-state"></span>
                                        <span class="step-icon"><i class="fa fa-edit"></i></span>
                                        <strong class="step-title">Thêm Thông Tin</strong>
                                        <span class="step-desc">Thêm chi tiết về xe của bạn</span>
                                    </a>
                                </li>
                                <li data-target="#listing-add-form-four" data-toggle="tab">
                                    <a id="them-anh-va-mo-ta-xe" [routerLink]="['/dang-ban-xe-oto', 'them-anh-va-mo-ta-xe']">
                                        <span class="step-state"></span>
                                        <span class="step-icon"><i class="fa fa-image"></i></span>
                                        <strong class="step-title">Thêm ảnh &amp; mô tả</strong>
                                        <span class="step-desc">Đăng ảnh &amp; mô tả cho tin bán xe</span>
                                    </a>
                                </li>
                                <li data-target="#listing-add-form-five" data-toggle="tab">
                                    <a id="hoan-tat" [routerLink]="['/dang-ban-xe-oto', 'hoan-tat']">
                                        <span class="step-state"></span>
                                        <span class="step-icon"><i class="fa fa-shopping-cart"></i></span>
                                        <strong class="step-title">Hoàn tất đăng tin</strong>
                                        <span class="step-desc">Thanh toán &amp; đăng tin bán xe</span>
                                    </a>
                                </li>
                            </ul>
                       	</div>
                    </div>
                    <div class="col-md-8 col-sm-8">
                    	<!-- AD Loading spinner -->
                        <!-- <image *ngIf="!isLoaded" src="./assets/images/spinner.gif" style="display: block; margin: auto;"></image> -->
                    	<!-- AD LISTING FORM -->
                     	<div class="listing-add-form">
                    		<section class="listing-form-content">

                                <!-- AD LISTING FORM STEP ONE -->
                      			<div id="listing-add-form-one" class="tab-pane fade in active">
                        			<h3>Nhập thông tin xe bạn muốn bán</h3>
                            		<div class="lighter"><p>Bạn có thể chọn thương hiệu, mẫu mã, năm sản xuất từ danh sách bên dưới. Trong trường hợp bạn không thấy xe của bạn trong danh sách, bạn hoàn toàn có thể tự nhập thông tin xe của bạn.</p></div>
                                    <div class="spacer-10"></div>
                                    <!-- listing-step-tabs -->
                                    <ul class="nav nav-tabs">
                                        <li class="active"><a id="select-vehicle-tab" data-target="#a" data-toggle="tab">Chọn từ danh sách</a></li>
                                        <li><a data-target="#b" data-toggle="tab">Tự nhập thông tin xe</a></li>
                                     </ul>
                                     
                                     <div class="tab-content">
                                         <!-- Chọn thông tin xe -->
                                        <div class="tab-pane active" id="a">
                                            <div class="alert alert-warning fade in">
                                                <strong>Xác định</strong> xe của bạn từ danh sách bên dưới. Chọn Thương hiệu, Mẫu mã và năm sản xuất.
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Thương Hiệu</label>
                                                    <select [(ngModel)]="vm.search.vehicleMakeId" [ngModelOptions]="{standalone: true}" (change)="onSelectVehicleMake(vm.search.vehicleMakeId)" id="v-make" class="form-control">
                                                        <option value="">Chọn Thương Hiệu Xe</option>
                                                        <option *ngFor="let entry of vm.vehicleMakes; let i = index" [value]="entry.id">
                                                          {{entry.name}}
                                                        </option>
                                                    </select>
                                                    <label>Mẫu Mã</label>
                                                    <select [(ngModel)]="vm.search.vehicleModelId" [ngModelOptions]="{standalone: true}"  (change)="onSelectVehicleModel(vm.search.vehicleModelId)" [disabled]="!vm.search.vehicleMakeId" id="orders" class="form-control">
                                                        <option value="">Chọn Mẫu Xe</option>
                                                        <option *ngFor="let entry of vm.vehicleModels; let i = index" [value]="entry.id">
                                                          {{entry.name}}
                                                        </option>
                                                      </select>
                                                    <label>Năm Sản Xuất</label>
                                                    <select [(ngModel)]="vm.search.vehicleYearRelease" [ngModelOptions]="{standalone: true}" [disabled]="!vm.search.vehicleModelId" class="form-control">
                                                        <option value="">Chọn Năm Sản Xuất</option>
                                                        <option *ngFor="let entry of vm.vehicleYearReleases; let i = index" [value]="entry">
                                                            {{entry}}
                                                        </option>
                                                    </select>
                                                    <button type="button" (click)="onFindVehicleTemplate()" class="btn btn-primary pull-right">Tìm</button>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div id="finded-results">
                                                        <!-- Result -->
                                                        <div *ngIf="vm.selectedVehicleTemplate"  class="search-find-results">
                                                            <span class="step-icon pull-right" style="color: green;"><i class="fa fa-check"></i></span>
                                                            <h5>{{vm.selectedVehicleTemplate.yearRelease}} {{vm.selectedVehicleTemplate.vehicleMakeName}} {{vm.selectedVehicleTemplate.vehicleModelName}} {{vm.selectedVehicleTemplate.vehicleBodyTypeName}} 1.5 CTV</h5>
                                                            <ul class="inline">
                                                                <li>{{vm.selectedVehicleTemplate.vehicleDoorQuantity}} cửa</li>
                                                                <li>6 xylanh, 1.5L {{vm.selectedVehicleTemplate.vehicleFuelName}}</li>
                                                                <li>Hộ số CTV</li>
                                                                <li>Dẫn động cầu trước</li>
                                                            </ul>
                                                            <button (click)="addVechileAndNext(vm.selectedVehicleTemplate)" class="btn btn-info btn-sm">Chọn &amp; tiếp tục</button>
                                                        </div>
                                                        <!-- <div ng-repeat="vehicleTemplate in vm.vehicleTemplates" class="search-find-results" ng-show="vm.advert && vm.advert.id == vehicleTemplate.id"> -->
                                                        <div *ngFor="let vehicleTemplate of vm.vehicleTemplates">
                                                            <div class="search-find-results" [hidden]="vehicleTemplate.id == vm.advert.vehicleTemplateId">
                                                            <h5>{{vehicleTemplate.yearRelease}} {{vehicleTemplate.vehicleMakeName}} {{vehicleTemplate.vehicleModelName}} {{vehicleTemplate.vehicleBodyTypeName}} 1.5 CTV</h5>
                                                            <ul class="inline">
                                                                <li>{{vehicleTemplate.vehicleDoorQuantity}} cửa</li>
                                                                <li>6 xylanh, 1.5L {{vehicleTemplate.vehicleFuelName}}</li>
                                                                <li>Hộ số CTV</li>
                                                                <li>Dẫn động cầu trước {{vehicleTemplate.id}}</li>
                                                            </ul>
                                                            <button (click)="addVechileAndNext(vehicleTemplate)" class="btn btn-info btn-sm">Chọn &amp; tiếp tục</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Tự Nhập thông tin xe -->
                                        <div class="tab-pane" id="b">
                                        <form #selfEnterVehicleForm="ngForm">
                                            <div class="alert alert-warning fade in">
                                                <strong>Lưu ý:</strong> Xe với thông tin tự nhập sẽ cần nhiều thời gian để được duyệt so với chọn xe từ danh sách có sẵn 
                                                <a (click)="moveToSelectVehicleTab()" data-toggle="tab" data-target="#a">Thử chọn lại xe từ danh sách</a>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label for="fselfVehicleMakeName">Nhập Thương Hiệu*</label><label [hidden]="fselfVehicleMakeName.valid || fselfVehicleMakeName.pristine" class="pull-right top-alert-danger">Vui lòng nhập thương hiệu xe</label>
                                                    <input [(ngModel)]="vm.selfVehicle.vehicleMakeName" [ngModelOptions]="{standalone: true}" #fselfVehicleMakeName="ngModel"  type="text" name="fselfVehicleMakeName" class="form-control" placeholder="Nhập thương hiệu xe của bạn, ví dụ: Toyota" required>

                                                    <label>Nhập Phiên Bản</label>
                                                    <input [(ngModel)]="vm.selfVehicle.vehicleVersion" [ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="Nhập phiên bản xe của bạn, ví dụ: 2.0G">

                                                    <label>Chọn Năm Sản Xuất*</label><label [hidden]="fselfVehicleYearRelease.valid || fselfVehicleYearRelease.pristine || vm.selfVehicle.vehicleYearRelease == 0" class="pull-right top-alert-danger">Vui lòng chọn năm sản xuất</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleYearRelease" min="1" [ngModelOptions]="{standalone: true}" #fselfVehicleYearRelease="ngModel" name="fselfVehicleYearRelease" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleYearReleases;" [ngValue]="entry">
                                                            {{entry}}
                                                        </option>
                                                    </select>

                                                    <label>Chọn Số Chỗ Ngồi*</label><label [hidden]="fselfVehicleSeat.valid || fselfVehicleSeat.pristine" class="pull-right top-alert-danger">Vui lòng chọn số ghế</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleSeat" #fselfVehicleSeat="ngModel"  name="fselfVehicleSeat" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleSeats; let i = index" [ngValue]="entry">
                                                            {{entry.quantity}}
                                                        </option>
                                                    </select>

                                                    <label>Chọn Hệ Thống Dẫn Động*</label><label [hidden]="fselfVehicleDriveType.valid || fselfVehicleDriveType.pristine" class="pull-right top-alert-danger">Vui lòng chọn hệ thống dẫn động</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleDriveType" #fselfVehicleDriveType="ngModel" name="fselfVehicleDriveType" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleDriveTypes; let i = index" [ngValue]="entry">
                                                            {{entry.name}}
                                                        </option>
                                                    </select>

                                                    <label>Chọn Loại Nhiên Liệu*</label><label [hidden]="fselfVehicleFuel.valid || fselfVehicleFuel.pristine" class="pull-right top-alert-danger">Vui lòng chọn loại nhiên liệu</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleFuel" #fselfVehicleFuel="ngModel" name="fselfVehicleFuel" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleFuels; let i = index" [ngValue]="entry">
                                                            {{entry.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Nhập Mẫu Xe*</label><label [hidden]="fselfVehicleModelName.valid || fselfVehicleModelName.pristine" class="pull-right top-alert-danger">Vui lòng nhập thương mẫu xe</label>
                                                    <input [(ngModel)]="vm.selfVehicle.vehicleModelName"  #fselfVehicleModelName="ngModel" name="fselfVehicleModelName" type="text" class="form-control" placeholder="Nhập mẫu xe của bạn, ví dụ: Camrry" required>

                                                    <label>Chọn Kiểu Dáng*</label><label [hidden]="fselfVehicleBodyType.valid || fselfVehicleBodyType.pristine" class="pull-right top-alert-danger">Vui lòng chọn kiểu dáng xe</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleBodyType" #fselfVehicleBodyType="ngModel" name="fselfVehicleBodyType" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleBodyTypes; let i = index" [ngValue]="entry">
                                                            {{entry.name}}
                                                        </option>
                                                    </select>
                                                    <label>Chọn Số Cửa*</label><label [hidden]="fselfVehicleDoor.valid || fselfVehicleDoor.pristine" class="pull-right top-alert-danger">Vui lòng chọn số cửa</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleDoor"  #fselfVehicleDoor="ngModel" name="fselfVehicleDoor" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleDoors; let i = index" [ngValue]="entry">
                                                            {{entry.quantity}}
                                                        </option>
                                                    </select>
                                                    <label>Chon Hộp Số*</label><label [hidden]="fselfVehicleTransmission.valid || fselfVehicleTransmission.pristine" class="pull-right top-alert-danger">Vui lòng chọn hộp số</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleTransmission" #fselfVehicleTransmission="ngModel" name="fselfVehicleTransmission" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleTransmissions; let i = index" [ngValue]="entry">
                                                            {{entry.name}}
                                                        </option>
                                                    </select>
                                                    <label>Chọn Số Lượng Xylanh*</label><label [hidden]="fselfVehicleEngine.valid || fselfVehicleEngine.pristine" class="pull-right top-alert-danger">Vui lòng chọn số Xylanh</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleEngine" #fselfVehicleEngine="ngModel" name="fselfVehicleEngine" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleEngines; let i = index" [ngValue]="entry">
                                                            {{entry.name}}
                                                        </option>
                                                    </select>
                                                    <label>Chọn Xuất Xứ*</label><label [hidden]="fselfVehicleSource.valid || fselfVehicleSource.pristine" class="pull-right top-alert-danger">Vui lòng chọn xuất xứ</label>
                                                    <select [(ngModel)]="vm.selfVehicle.vehicleSource" #fselfVehicleSource="ngModel" name="fselfVehicleSource" class="form-control selectpicker-bk" required>
                                                        <option value="">-</option>
                                                        <option *ngFor="let entry of vm.vehicleSources; let i = index" [ngValue]="entry">
                                                            {{entry.name}}
                                                        </option>
                                                    </select>
                                                     <button (click)="selfEnterVehicleInfo()" [disabled]="!selfEnterVehicleForm.form.valid" class="btn btn-info pull-right">tiếp theo</button>
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                     </div>
                                </div>
                            
                    			<!-- AD LISTING FORM STEP TWO -->
                      			<div id="listing-add-form-two" class="tab-pane fade">
                        			<h3>Chọn những tính năng mà xe bạn có</h3>
                            		<div class="lighter"><p>Những tính năng này có thể là có sẵn theo xe hoặc do bạn lắp ráp thêm.</p></div>
                                    <div class="panel panel-default">
  										<div class="panel-body">
                                        	<ul class="optional-features-list">
                                            	<li *ngFor="let vAddon of vm.vehicleAddons, let i = index" class="checkbox">
                                                    <label>
                                                        <input type="checkbox" [(ngModel)]="vm.selectedVehicleAddons[i]" [ngModelOptions]="{standalone: true}" checklist-value="vAddon.id" ng-checked="vm.selectedVehicleAddons[i]"/>
                                                        {{vAddon.name}}
                                                    </label>
                                                    </li>
                                            </ul>
                                        </div>
                                   	</div>
                                	<button (click)="addAddonAndNext()" class="btn btn-info pull-right">tiếp theo</button>
                                </div>

                    			<!-- AD LISTING FORM STEP THREE -->
                      			<div id="listing-add-form-three" class="tab-pane fade">
                                    <form #addVehicleInfoForm="ngForm">
                                        <h3>Thêm một số thông tin chi tiết hơn về xe của bạn</h3>
                                        <div class="lighter"><p>Càng chi tiết sẽ giúp người mua càng dễ tìm ra được xe của bạn hơn.</p></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Số Km đã đi*</label><label [hidden]="kmUsed.valid || kmUsed.pristine" class="pull-right top-alert-danger">Vui lòng km đã đi</label>
                                                <div class="input-group">
                                                    <input [(ngModel)]="vm.advert.kmUsed" #kmUsed="ngModel" name="kmUsed" type="number" class="form-control" placeholder="" min="1" required>
                                                    <span class="input-group-addon">km</span>
                                                </div>
                                                <label>Chọn Màu Ngoại Thất*</label><label [hidden]="vehicleExteriorColorId.valid || vehicleExteriorColorId.pristine" class="pull-right top-alert-danger">Vui lòng chọn màu xe của bạn</label>
                                                <select [(ngModel)]="vm.advert.vehicleExteriorColorId" #vehicleExteriorColorId="ngModel" name="vehicleExteriorColorId" (change)="onSelectVehicleExteriorColor(vm.advert.vehicleExteriorColorId)"  class="form-control selectpicker-bk" required>
                                                    <option value="">-</option>
                                                    <option *ngFor="let entry of vm.vehicleExteriorColors; let i = index" [value]="entry.id">{{entry.name}}</option>
                                                </select>
                                                <label>Nhập Màu Nội Thất</label>
                                                <input [(ngModel)]="vm.advert.vehicleInteriorColorId" [ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="Vàng, đỏ, nâu...">
                                                <hr class="fw">
                                                <h3>Để lại thông tin liên lạc của bạn</h3>
                                                <label>Số điện thoại của bạn*</label><label [hidden]="phone.valid || phone.pristine" class="pull-right top-alert-danger">Vui nhập số điện thoại liên hệ</label>
                                                <input [(ngModel)]="vm.advert.phone" #phone="ngModel" type="text" name="phone" class="form-control" placeholder="089898988" required>
                                                <label>Địa chỉ email của bạn</label>
                                                <input [(ngModel)]="vm.advert.email" type="email" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="meoto.vn@example.com">

                                                <button type="button" (click)="addCarInfoAndNext()" [disabled]="addVehicleInfoForm.form.invalid" class="btn btn-info pull-right">tiếp theo</button>
                                            </div>
                                            <div class="col-md-5 col-md-offset-1">
                                                <div class="panel panel-info price-suggestion">
                                                    <div class="panel-heading">
                                                        <h3 class="panel-title">Bạn muốn bán với giá bao nhiêu?</h3>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="input-group">
                                                            <input [(ngModel)]="vm.advert.price" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
                                                            <span class="input-group-addon">Triệu VND</span>
                                                        </div>
                                                        <p class="small">Giá gợi ý: 500 triệu - 550 triệu</p>
                                                    </div>
                                                </div>

                                                <label>Nơi Bán*</label><label [hidden]="place.valid || place.pristine" class="pull-right top-alert-danger">Vui chọn nơi bạn muốn bán xe</label>
                                                <select [(ngModel)]="vm.advert.placeId" #place="ngModel" name="place" class="form-control selectpicker-bk" required>
                                                    <option value="">-</option>
                                                    <option value="tp-ho-chi-minh">TP.Hồ Chí Minh</option>
                                                    <option value="ha-noi">Hà Nội</option>
                                                    <option value="hai-phong">Hải Phòng</option>
                                                    <option value="nghe-an">Nghệ An</option>
                                                    <option value="da-nang">Đà Nẵng</option>
                                                    <option value="dong-thap">Đồng Tháp</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            
                    			<!-- AD LISTING FORM STEP FOUR -->
                      			<div id="listing-add-form-four" class="tab-pane fade">
                                    <h3>Tải hình ảnh thực tế xe của bạn lên</h3>
                                    <div class="lighter"><p>Bạn cần tải lên tối thiểu 5 ảnh và tối đa 10 ảnh - Kích thước đề nghị 800x600px.</p></div>
                                    <label class="btn btn-primary">
                                        Tải lên <input type="file" class="hidden">
                                    </label>
                                    <hr class="fw">
                                    <h3>Nhập link Youtube quay xe của bạn</h3>
                                    <div class="lighter"><p>Theo kinh nghiệm và thống kê của chúng tôi, xe có đính kèm video sẽ được để ý và bán nhanh hơn những xe khác.</p></div>
                                    <input [(ngModel)]="vm.advert.videoUrl" [ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="Youtube/Video URL">
                                    <hr class="fw">
                                    <h3>Cần hỗ trợ chụp ảnh, quay phim?</h3>
                                    <div class="lighter"><p>Chúng tôi có cung cấp dịch vụ chụp ảnh, quay phim hỗ trợ upload Youtube với giá phải chăng (<a href="#">Chi tiết dịch vụ</a>). Bạn có muốn đăng ký?</p></div>
                                    <li class="checkbox"><label><input [(ngModel)]="vm.advert.isRequestSupport" [ngModelOptions]="{standalone: true}" type="checkbox"> Có, tôi muốn tin bán xe nhìn chuyên nghiệp hơn.</label></li>
                                    <hr class="fw">
                                    <!-- TODO required min char 100 -->
                                    <h3>Nhập mô tả chi tiết</h3>
                                    <div class="lighter"><p>Nhập thông tin mô tả chi tiết về xe của bạn, tính năng, khuyến mãi, phụ kiện đi kèm.</p></div>
                                    <textarea [(ngModel)]="vm.advert.description" [ngModelOptions]="{standalone: true}" class="form-control" rows="10"></textarea>
                                	<button (click)="addCarImageAndNext()" class="btn btn-info pull-right">tiếp theo</button>
                                </div>
                            
                    			<!-- AD LISTING FORM STEP FIVE -->
                      			<div id="listing-add-form-five" class="tab-pane fade">
                                	<h3>Bạn muốn tự bán xe hay bán cho Salon</h3>
                                    <div class="lighter"><p>Chúng tôi đề xuất 2 cách để bạn có thể bán xe của mình. Ngoài cách bán xe thông thường - bạn sẽ tự quản lý tất cả các việc bán xe, bạn có thể bán trực tiếp cho salon đã được xác minh. <a href="#">Tìm hiểu thêm về lợi ích của việc bán xe cho salon.</a></p></div>
                                    <div class="btn-group selling-choice" data-toggle="buttons">
                                        <label class="btn btn-default" [ngClass]="{'active': !vm.advert.isSaleToSalon}" (click)="vm.advert.isSaleToSalon=false">
                                            <input [(ngModel)]="vm.advert.isSaleToSalon" [ngModelOptions]="{standalone: true}" [value]="false"  type="radio" name="sale-to-salon" id="option1"> <i class="fa fa-group"></i> <strong>Bán xe thông thường</strong>
                                        </label>
                                        <label class="btn btn-default" [ngClass]="{'active': vm.advert.isSaleToSalon}" (click)="vm.advert.isSaleToSalon=true">
                                            <input [(ngModel)]="vm.advert.isSaleToSalon" [ngModelOptions]="{standalone: true}" [value]="true" type="radio" name="sale-to-salon" id="option2">  <i class="fa fa-user"></i><strong>Bán xe cho salon</strong>
                                        </label>
                                    </div>
                                    <hr class="fw">
                                    <h3>Ký gửi xe cho Salon</h3>
                                    <div class="lighter"><p>Ký gửi xe tại salon là hình thức đăng ký bán xe dưới danh nghĩa salon. Tiền hoa hồng cho salo sẽ do 2 bên tự thương lượng. Bạn có muốn đăng ký?</p></div>
                                    <li class="checkbox"><label><input [(ngModel)]="vm.advert.isDepositToSalon" [ngModelOptions]="{standalone: true}"  type="checkbox"> Có, tôi muốn bán xe nhanh hơn.</label></li>
                                    <hr class="fw">
                                	<h3>Chọn gói dịch vụ bạn đang dùng</h3>
                            		<div class="lighter"><p>Chúng tôi cung cấp 4 gói dịch vụ khác nhau. <a href="#">Tìm hiểu thêm.</a></p></div>
                                    <div class="row">
                                    	<div>
                                        	<div class="panel panel-info selected-price-plan">
                                                <div class="panel-body">
                                                    <button class="plan-blocked" style="background-color:gray; width: 25%;" disabled>Miễn phí</button>
                                                    <strong>Bạn đang dùng gói dịch vụ này.</strong>
                                                </div>
                                                <div class="panel-body">
                                                    <button class="plan-blocked" style="background-color:#db396f; width: 25%;" disabled>Cơ bản</button>
                                                    <span>Khuyên dùng cho bán chuyên. <a href="add-listing-pricing.html">Chi tiết.</a></span>
                                                </div>
                                                <div class="panel-body">
                                                    <button class="plan-blocked" style="background-color:#1ba4d9; width: 25%;" disabled>Nâng cao</button>
                                                    <span>Khuyên dùng cho salon. <a href="add-listing-pricing.html">Chi tiết.</a></span>
                                                </div>
                                                <div class="panel-body">
                                                    <button class="plan-blocked" style="background-color:#8952b9; width: 25%;" disabled>Siêu Cấp</button>
                                                    <span>Khuyên dùng cho salon VIP. <a href="add-listing-pricing.html">Chi tiết.</a></span>
                                                </div>
                                            </div>
                                			<button (click)="completeAdvert()" class="btn btn-info btn-block">Lưu &amp; Đăng tin</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- end AD LISTING FORM -->
                        </div> 
                    </div>
                </div>
           	</div>
        </div>
   	</div>