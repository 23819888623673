// core
import { Component, OnInit } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';

// 3rd
import { Options, LabelType, ChangeContext } from 'ng5-slider';
import { stringify } from 'qs';
import { combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';



// self
import { AdvertService } from '../services/advert.service';
import { CacheService } from '../services/cache.service';
import { UtilsService } from '../services/utils.service';
import { ScriptService } from '../services/load-script.service';


@Component({
  selector: 'app-advert-list',
  templateUrl: './advert-list.component.html',
  styleUrls: ['./advert-list.component.css']
})
export class AdvertListComponent implements OnInit {
  public adverts = [];
  public vm: any = {};
  public sam: any = {};
  public ssm: any = {};
  public val = 10;
  public userId:any
  isLoaded = false;
  NEW_VEHICLE_SUBFIX = "moi";
  USED_VEHICLE_SUBFIX = "da-qua-su-dung";
  ALL_VEHICLE_SUBFIX = "";

  // minPrice: number = 0;
  // maxPrice: number = 5000;
  // minKmUsed: number = 0;
  // maxKmUsed: number = 300000;
  priceOptions: Options = {
    floor: 0,
    ceil: 5000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this._u.convertPrice2VietNameseRange(value);
        case LabelType.High:
          return this._u.convertPrice2VietNameseRange(value);
        default:
          return this._u.convertPrice2VietNameseRange(value);
      }
    }
  };
  kmUsedoptions: Options = {
    floor: 0,
    ceil: 300000
  };
  private DEFAULT_VALUE = "-";
  private STATUS_REFIX = "can-mua-xe-oto-";
  private PLACE_REFIX = "can-mua-xe-tai-";
  private MAKE_REFIX = "xe-";
  private MODEL_REFIX = "mau-xe-";
  private BODY_REFIX = "kieu-dang-";
  private YEAR_REFIX = "san-xuat"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private YEAR_REFIX_RANGE = "san-xuat-khoang-"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private YEAR_REFIX_MIN = "san-xuat-tu-"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private YEAR_REFIX_MAX = "san-xuat-den-"; // to research more san-xuat-khoang-2018-2020, san-xuat-nam-tu-2018, san-xuat-den-2020
  private PRICE_REFIX = "gia-tu-"; // ga-tu-100-trieu-den-1-ty
  private PRICE_MIDLE_REFIX = "-den-"; // ga-tu-100-trieu-den-1-ty
  private KM_USED_REFIX = "toi-da-"; // chay-tu-100-km-den-200-km
  private KM_USED_UNIT = "-km"; // chay-tu-100-km-den-200-km


  private QP_TRANS = "hop-so";
  private QP_VEHICLE_SOURCE = "xuat-su";
  private QP_EXTERIOR_COLOR = "mau";


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private advertService: AdvertService,
    private cacheService: CacheService,
    public _u: UtilsService,
    private scriptService: ScriptService,
    private platformLocation: PlatformLocation,
    private toastr: ToastrService,
  ) {
    this.scriptService.load(this.scriptService.INIT);
    this._initMasterData();
    
    // model saved Advert
    this.sam.savedAdvert = {
      userId : '',
      adsId : ''
    }

    // model saved search 
    this.ssm.savedSearches = {
      url : '',
      name : '',
      detail : '',
      userId : '',
    }

    // userId save model saved 
    let userModel: any = localStorage.getItem("_u");
    if (userModel) {
      userModel = JSON.parse(userModel);
      this.userId = userModel.id;
    }
  }

  ngOnInit(): void {
    this.vm.search = {
      vehicleStatus: this.ALL_VEHICLE_SUBFIX, // moi vs da-qua-su-dung
      placeId: this.DEFAULT_VALUE,
      vehicleMakeId: this.DEFAULT_VALUE,
      vehicleModelId: this.DEFAULT_VALUE,
      vehicleBodyTypeId: this.DEFAULT_VALUE,
      vehicleYearMin: this.DEFAULT_VALUE,
      vehicleYearMax: this.DEFAULT_VALUE,
      vehicleSourceId: this.DEFAULT_VALUE,
      minPrice: 0,
      // minPriceCfg:  0,
      maxPrice: 5000,
      maxPriceCfg: 5000,
      minKmUsed: 0,
      maxKmUsed: 300000,
      maxKmUsedCfg: 300000,

      listItemStyle: '',
      pageItems: 12,
      pageCurrent: 0,
      filterCondition: '',
      totalItems: 0,
      remainItems: 0,
      status: 'public'
    };
    // default search config
    if (!this.vm.searchCfg) {
      this.vm.searchCfg = { ... this.vm.search };
    }

    // http://localhost:4200/mua-xe-oto-moi/xe-bmw/mau-xe-bmw-1-series/kieu-dang-sedan/gia-tu-500trieu-den-1-ti-200-trieu/san-xuat-khoang-2018-2019
    // combineLatest([this.route.params, this.route.queryParamMap]).subscribe([params, queryParams]) => {
    combineLatest([this.route.params, this.route.queryParamMap])
      .subscribe(([params, queryParams]) => {
        let transmissionStr = queryParams.get(this.QP_TRANS);// is string as {mau: "do,xanh,vang"}
        if (transmissionStr) {
          let _transmissions = transmissionStr.split(',');
          for (let trans of this.vm.vehicleTransmissions) {
            if (_transmissions.includes(trans.id)) {
              trans.isSelected = true;
            } else {
              trans.isSelected = false;
            }
          }
        }
        let _vehicleSource = queryParams.get(this.QP_VEHICLE_SOURCE);
        if (_vehicleSource) {
          this.vm.search.vehicleSourceId = _vehicleSource;
        }
        let exteriorStr = queryParams.get(this.QP_EXTERIOR_COLOR);// is string as {mau: "do,xanh,vang"}
        if (exteriorStr) {
          let _exteriors = exteriorStr.split(',');
          for (let color of this.vm.vehicleExteriorColors) {
            if (_exteriors.includes(color.id)) {
              color.isSelected = true;
            } else {
              color.isSelected = false;
            }
          }
        }

        for (let key of Object.keys(params)) {
          let data = params[key];
          console.log("route data:: ", data, queryParams);
          // we need set min/max year firt
          if (data.startsWith(this.YEAR_REFIX)) {
            if (data.startsWith(this.YEAR_REFIX_RANGE)) {
              let range = data.replace(this.YEAR_REFIX_RANGE, '');
              let tmp = range.split('-');
              this.vm.search.vehicleYearMin = Number(tmp[0]);
              this.vm.search.vehicleYearMax = Number(tmp[1]);
            } else if (data.startsWith(this.YEAR_REFIX_MIN)) {
              let yearMin = data.replace(this.YEAR_REFIX_MIN, '');
              this.vm.search.vehicleYearMin = Number(yearMin);
            } else if (data.startsWith(this.YEAR_REFIX_MAX)) {
              let yearMax = data.replace(this.YEAR_REFIX_MAX, '');
              this.vm.search.vehicleYearMax = Number(yearMax);
            } else {
              // synxtax error
            }
            this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
            this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
          }
          // re-select BodyType
          if (data.startsWith(this.BODY_REFIX)) {
            this.vm.search.vehicleBodyTypeId = data.replace(this.BODY_REFIX, '');
          }
          // re-select Model
          if (data.startsWith(this.MODEL_REFIX)) {
            this.vm.search.vehicleModelId = data.replace(this.MODEL_REFIX, '');
            // this.onSelectVehicleModel(undefined);
          }
          // re-select place
          if (data.startsWith(this.PLACE_REFIX)) {
            this.vm.search.placeId = data.replace(this.PLACE_REFIX, '');
          }
          // re-select Make
          if (data.startsWith(this.MAKE_REFIX)) {
            this.vm.search.vehicleMakeId = data.replace(this.MAKE_REFIX, '');
            // this async will be run later set model and body... bellow
            this.onSelectVehicleMake(undefined);
          }
          // re-select price
          if (data.startsWith(this.PRICE_REFIX)) {
            // TODO safety check
            let raw = data.replace(this.PRICE_REFIX, '')
            let rawPrices = raw.split(this.PRICE_MIDLE_REFIX);
            this.vm.search.minPrice = this._u.convertPriceVietNameseToNumber(rawPrices[0]);
            this.vm.search.maxPrice = this._u.convertPriceVietNameseToNumber(rawPrices[1]);
            console.log('this.vm.search.minPrice', this.vm.search.minPrice, this.vm.search.maxPrice);
          }
          // re-select km-used
          if (data.startsWith(this.KM_USED_REFIX)) {
            // TODO safety check
            try {
              let raw = data.replace(this.KM_USED_REFIX, '').replace(new RegExp(this.KM_USED_UNIT, 'ig'), '');
              // this.vm.search.minKmUsed = Number(rawKmUsed[0].replace(/-/g, ''));
              this.vm.search.maxKmUsed = Number(raw.replace(/-/g, ''));
              console.log('this.vm.search.max-KmUsed', this.vm.search.maxKmUsed);
            } catch (error) {
              // go to default when have error
              this.vm.search.maxKmUsed = this.vm.search.maxKmUsedCfg;
            }
          }
          if (data.startsWith(this.STATUS_REFIX)) {
            this.vm.search.vehicleStatus = data.replace(this.STATUS_REFIX, '');
          }
        }
        // get advert list with call back is getAdverting
        this.countAdverts().then(() => this.getAdverts());
      });

    // we  can not inint data here, because navigate will make duplicate inint, it not clean all data
    // this._initMasterData();
  }

  public convertPrice2VietNameseRange(price) {
    return this._u.convertPrice2VietNameseRange(price);
  }

  public convertPrice2VietNamese(price) {
    return this._u.convertPrice2VietNamese(price);
  }


  /**
   * get advert as filter condition
   */
  public getAdverts(isConcat?) {
    this.vm.search.vehicleTransmissions = this._u._filterSelectedEntry(this.vm.vehicleTransmissions);
    this.vm.search.vehicleExteriorColors = this._u._filterSelectedEntry(this.vm.vehicleExteriorColors);
    this.advertService.getAdverts(this.vm.search)
      .then(adverts => {
        if (isConcat) {
          // concat notworking
          (<any>adverts).forEach(element => {
            this.adverts.push(element);
          });
        }
        else {
          this.adverts = <any>adverts;
        }
        // push one because page count from zero
        let remainItems = this.vm.search.totalItems - (this.vm.search.pageItems * (this.vm.search.pageCurrent + 1));
        if (remainItems < 0) {
          remainItems = 0
        }
        this.vm.search.remainItems = remainItems;
      })
      .catch(resp => {
        console.log('resp error: ', resp);
      });
  }

  public countAdverts() {
    this.vm.search.vehicleTransmissions = this._u._filterSelectedEntry(this.vm.vehicleTransmissions);
    this.vm.search.vehicleExteriorColors = this._u._filterSelectedEntry(this.vm.vehicleExteriorColors);
    return this.advertService.countAdverts(this.vm.search)
      .then(resp => {
        this.vm.search.totalItems = (<any>resp).count;
        return;
      })
      .catch(resp => {
        console.log('resp error: ', resp);
      });
  }
  /**
   * save advert
   */
  public SavedAdvert(adsId) {
    this.sam.savedAdvert.userId = this.userId
    this.sam.savedAdvert.adsId = adsId
    this.advertService.creatSavedAdverts(this.sam.savedAdvert)
      .then(savedAdvert => {
        console.log('save' + savedAdvert)
        this.toastr.success('Lưu Mua Xe Thành Công', undefined);
      })
      .catch(resp => {
        console.log('resp error: ', resp);
        this.toastr.error('Tin Mua Xe Này Đã Tồn Tại', undefined);

      });
  }

  /**
   * Init data for first load
   */
  private _initMasterData() {
    this.cacheService.getVehicleMakes()
      .then(vehicleMakes => {
        this.vm.vehicleMakes = vehicleMakes;
        // this.vm.selectedVechicleMake = this._reloadOption(vehicleMakes, this.vm.advert.vehicleMakeId, false) || vehicleMakes[0];
      });
    // vehicle body type
    this._initVehicleBodyTypes();

    // this.cacheService.getVehicleDoors()
    //   .then(vehicleDoors => {
    //     this.vm.vehicleDoors = vehicleDoors;
    //     // this.vm.selfVehicle.vehicleDoor = this._reloadOption(vehicleDoors, this.vm.advert.vehicleDoorId, true);
    //   });
    this.cacheService.getVehicleSeats()
      .then(vehicleSeats => {
        this.vm.vehicleSeats = vehicleSeats;
        // this.vm.selfVehicle.vehicleSeat = this._reloadOption(vehicleSeats, this.vm.advert.vehicleSeatId, true);

      });
    this.cacheService.getVehicleTransmissions()
      .then(vehicleTransmissions => {
        this.vm.vehicleTransmissions = vehicleTransmissions;
        // this.vm.selfVehicle.vehicleTransmission = this._reloadOption(vehicleTransmissions, this.vm.advert.vehicleTransmissionId, true);
      });
    this.cacheService.getVehicleDriveTypes()
      .then(vehicleDriveTypes => {
        this.vm.vehicleDriveTypes = vehicleDriveTypes;
        // this.vm.selfVehicle.vehicleDriveType = this._reloadOption(vehicleDriveTypes, this.vm.advert.vehicleDriveTypeId, true);
      });
    // this.cacheService.getVehicleEngines()
    //   .then(vehicleEngines => {
    //     this.vm.vehicleEngines = vehicleEngines;
    //     // this.vm.selfVehicle.vehicleEngine = this._reloadOption(vehicleEngines, this.vm.advert.vehicleEngineId, true);
    //   });
    // this.cacheService.getVehicleFuels()
    //   .then(vehicleFuels => {
    //     this.vm.vehicleFuels = vehicleFuels;
    //     // this.vm.selfVehicle.vehicleFuel = this._reloadOption(vehicleFuels, this.vm.advert.vehicleFuelId, true);
    //   });
    this.cacheService.getVehicleSources()
      .then(vehicleSources => {
        this.vm.vehicleSources = vehicleSources;
        // this.vm.selfVehicle.vehicleSource = this._reloadOption(vehicleSources, this.vm.advert.vehicleSourceId, true);
      });
    this.cacheService.getVehicleExteriorColor()
      .then(vehicleExteriorColor => {
        this.vm.vehicleExteriorColors = vehicleExteriorColor;
        // this.vm.selfVehicle.vehicleSource = this._reloadOption(vehicleSources, this.vm.advert.vehicleSourceId, true);
      });

    this.vm.vehicleYearMins = this._u._generateYear(0, 0);
    this.vm.vehicleYearMaxs = this._u._generateYear(0, 0);
    // this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
    // this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
  }

  public _initVehicleBodyTypes() {
    this.cacheService.getVehicleBodyTypes()
      .then(vehicleBodyTypes => {
        this.vm.vehicleBodyTypes = vehicleBodyTypes;
        // this.vm.selfVehicle.vehicleBodyType = this._reloadOption(vehicleBodyTypes, this.vm.advert.vehicleBodyTypeId, true);
      });
  }

  public onSelectPlace(placeId) {
    // default
    if (!placeId) {
      placeId = this.vm.search.placeId;
    }
    this._handelUpdateUrl(this.PLACE_REFIX, placeId);
  }

  public async onSelectVehicleMake(vehicleMakeId) {
    let isSearch = (vehicleMakeId) ? true : false;
    // TODO BUG need update modelId param on url too
    if (isSearch) {
      // this._handelUpdateUrl(this.MAKE_REFIX, vehicleMakeId);
      if (vehicleMakeId == this.DEFAULT_VALUE) {
        this.vm.search.vehicleModelId = this.DEFAULT_VALUE;
      }
      this._handelNavigateByUrlForVehicleMake();
      return;
    }
    // default
    if (!vehicleMakeId) {
      vehicleMakeId = this.vm.search.vehicleMakeId;
    }
    // init data again
    if (!vehicleMakeId || vehicleMakeId == this.DEFAULT_VALUE) {
      // this._handelUpdateUrl(this.MODEL_REFIX, vehicleMakeId);
      // this._initVehicleBodyTypes();
      // this.vm.vehicleYearMins = this._u._generateYear(0, 0);
      // this.vm.vehicleYearMaxs = this._u._generateYear(0, 0);

      this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
      this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
      this.vm.search.vechileModelId = this.DEFAULT_VALUE;
      return;
    }

    // this._handelUpdateUrl(this.MAKE_REFIX, vehicleMakeId);
    await this.cacheService.getVehicleModelsByVehicleMakeId(vehicleMakeId)
      .then(vehicleModels => {
        console.log('vehicleModels: ', vehicleModels);
        this.vm.vehicleModels = vehicleModels;
        let found = false;
        for (let vModel of vehicleModels) {
          if (vModel.id == this.vm.search.vehicleModelId) {
            found = true;
          }
        }
        if (!found) {
          // reset selected vehicle model
          this.vm.search.vehicleModelId = this.DEFAULT_VALUE;
        }
        this.onSelectVehicleModel(undefined);
        // handle body type too
        return;
      });

    // // TODO BUG need update modelId param on url too
    // if (isSearch) {
    //   // this._handelUpdateUrl(this.MAKE_REFIX, vehicleMakeId);
    //   this._handelNavigateByUrlForVehicleMake();
    //   // this.getAdverts();
    // }
  }

  public onSelectVehicleModel(vehicleModelId) {
    let isSearch = (vehicleModelId) ? true : false;
    // default
    if (!vehicleModelId) {
      vehicleModelId = this.vm.search.vehicleModelId;
    }

    // init data
    if (!vehicleModelId || vehicleModelId == this.DEFAULT_VALUE) {
      this._initVehicleBodyTypes();
      // this.vm.vehicleYearMins = this._u._generateYear(0, 0);
      // this.vm.vehicleYearMaxs = this._u._generateYear(0, 0);
      // from max year selected to min cfg
      this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, 0);
      this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
    }

    // get v-model object
    for (let model of this.vm.vehicleModels) {
      if (model.id == vehicleModelId) {
        let { vehicleYearReleases } = model;
        // sort
        vehicleYearReleases.sort((a, b) => b - a);
        this.vm.vehicleBodyTypes = model.vehicleBodyTypes;
        let min = 0;
        let max = 0;
        // this.vm.vehicleYearMaxs = vehicleYearReleases.sort((a, b) => b - a);
        // this.vm.vehicleYearMins = vehicleYearReleases;
        // re-default year
        if (!vehicleYearReleases.includes(this.vm.search.vehicleYearMax)) {
          // this.vm.search.vehicleYearMin = this.DEFAULT_VALUE;
          this.vm.search.vehicleYearMax = this.DEFAULT_VALUE;
          min = [...vehicleYearReleases].pop();
        }
        if (!vehicleYearReleases.includes(this.vm.search.vehicleYearMin)) {
          // this.vm.search.vehicleYearMax = this.DEFAULT_VALUE;
          this.vm.search.vehicleYearMin = this.DEFAULT_VALUE;
          max = vehicleYearReleases[0];
        }
        if (!min) {
          min = this.vm.search.vehicleYearMin;
        }
        if (!max) {
          max = this.vm.search.vehicleYearMax;
        }

        // generate again
        this.vm.vehicleYearMins = this._u._generateYear(max, [...vehicleYearReleases].pop());
        this.vm.vehicleYearMaxs = this._u._generateYear(vehicleYearReleases[0], min);
        break;
      }
    }
    // when user re-selected vehicle model when bodytype already selected, if bodytype not exted on model we re-default bodytype
    if (this.vm.search.vehicleBodyTypeId != this.DEFAULT_VALUE) {
      let found = false;
      for (let bt of this.vm.vehicleBodyTypes) {
        if (bt.id == this.vm.search.vehicleBodyTypeId) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.vm.search.vehicleBodyTypeId = this.DEFAULT_VALUE;
      }
    }

    if (isSearch) {
      this._handelNavigateByUrlForVehicleModel();
    }
  }

  public onSelectVehicleBodyType() {
    this._handelUpdateUrl(this.BODY_REFIX, this.vm.search.vehicleBodyTypeId);
    // this.getAdverts();
  }

  // re-generate min year
  public onSelectVehicleYearMin() {
    this.vm.vehicleYearMaxs = this._u._generateYear(this.vm.vehicleYearMaxs[0], this.vm.search.vehicleYearMin);
    this._handelUpdateUrlForYearReplease(this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax);
  }

  // belong to model too
  public onSelectVehicleYearMax() {
    this.vm.vehicleYearMins = this._u._generateYear(this.vm.search.vehicleYearMax, [...this.vm.vehicleYearMins].pop());
    this._handelUpdateUrlForYearReplease(this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax);
  }

  public onUserChangePriceEnd(changeContext: ChangeContext) {
    console.log("onUserChangePriceEnd:", this.vm.search.minPrice);
    this._handelNavigateByUrlPriceSlider(this.vm.search.minPrice, this.vm.search.minPriceCfg, this.vm.search.maxPrice, this.vm.search.maxPriceCfg)
  }

  public onUserChangeKmUsedEnd(changeContext: ChangeContext) {
    console.log("onUserChangeKmUsedEnd:", this.vm.search.minPrice);
    this._handelNavigateByUrlKmUsedSlider(this.vm.search.maxKmUsed, this.vm.search.maxKmUsedCfg);
  }

  public onSelectVhicleTransmission() {
    this._handelNavigateByUrlForQueryParam();
  }

  public onSelectVehicleSource() {
    this._handelNavigateByUrlForQueryParam();
  }

  public onSelectVhicleExteriorColor() {
    this._handelNavigateByUrlForQueryParam();
  }

  public onSearchNewVehicle() {
    this.vm.search.vehicleStatus = this.NEW_VEHICLE_SUBFIX;
    this.vm.search.maxKmUsed = this.vm.search.maxKmUsedCfg;
    this._goUrlV2([{ prefix: this.STATUS_REFIX, predictParam: this.STATUS_REFIX + this.vm.search.vehicleStatus }, { prefix: this.KM_USED_REFIX, predictParam: '' }]);
  }

  public onSearchOldVehicle() {
    this.vm.search.vehicleStatus = this.USED_VEHICLE_SUBFIX;
    this._handelUpdateUrl(this.STATUS_REFIX, this.vm.search.vehicleStatus);
  }

  public onSearchALlVehicle() {
    this.vm.search.vehicleStatus = this.ALL_VEHICLE_SUBFIX;
    this._handelUpdateUrl(this.STATUS_REFIX, this.vm.search.vehicleStatus);
  }

  public onReset() {
    this.vm.search = { ...this.vm.searchCfg };
    // reset transmission selected
    this.vm.vehicleTransmissions.forEach(element => {
      element.isSelected = false;
    });
    // reset exterior colors selected
    this.vm.vehicleExteriorColors.forEach(element => {
      element.isSelected = false;
    });
    this.router.navigateByUrl('/danh-sach-xe-oto');
  }

  public onSaveSearch() {
    let detail = 'Tất cả xe';
    if (this.vm.search.vehicleMakeId != this.DEFAULT_VALUE) {
      detail += `${this.vm.search.vehicleMakeId}`;
    }
    if (this.vm.search.vehicleModelId != this.DEFAULT_VALUE) {
      detail = `Tất cả xe ${this.vm.search.vehicleModelId.replace('-', ' ')}`;
    }
    if (this.vm.search.vehicleYearMin != this.DEFAULT_VALUE) {
      detail += ` từ năm ${this.vm.search.vehicleYearMin}`;
    }
    if (this.vm.search.vehicleYearMax != this.DEFAULT_VALUE) {
      detail += ` đến năm ${this.vm.search.vehicleYearMax}`;
    }
    // let detail = `Tất cả xe ${this.vm.search.vehicleModelId.replace('-', ' ')} từ năm ${this.vm.search.vehicleYearMin} đến ${this.vm.search.vehicleYearMax}`;
    this.ssm.savedSearches.url = window.location.origin;
    this.ssm.savedSearches.name = this.location.path();
    this.ssm.savedSearches.detail = detail;
    this.ssm.savedSearches.userId = this.userId;


    let path = this.location.path();
    this.advertService.creatSavedSearches(this.ssm.savedSearches)
    .then(savedSearche => {
      console.log('save' + savedSearche)
      this.toastr.success('Lưu Tìm Kiếm Thành Công', undefined);
    })
    .catch(resp => {
      console.log('resp error: ', resp);
      this.toastr.error('Tin Này Đã Tồn Tại', undefined);
    });
  }

  public showMoreItem() {
    this.vm.search.pageCurrent++;
    this.getAdverts(true);
  }



  // --------------------------------------------------------------------
  // util section
  /**
   *  Nagivate to predict route
   * @param prefix prefix of route
   * @param data param of route
   */
  private _handelUpdateUrl(prefix, data) {
    let predictParam = (!data || data == this.DEFAULT_VALUE) ? '' : prefix + data;
    this._goUrl(prefix, predictParam);
  }

  /**
   * VehicleModel belongs to Vehicle Make, so when update make we should be update model too
   */
  private _handelNavigateByUrlForVehicleMake() {
    let _vehicleMakeId = this.vm.search.vehicleMakeId;
    let _vehicleModelId = this.vm.search.vehicleModelId;
    let predictVehicleMakeParam = (!_vehicleMakeId || _vehicleMakeId == this.DEFAULT_VALUE) ? '' : this.MAKE_REFIX + _vehicleMakeId;
    let predictVehicleModelParam = (!_vehicleModelId || _vehicleModelId == this.DEFAULT_VALUE) ? '' : this.MODEL_REFIX + _vehicleModelId;
    this._goUrlV2([{ prefix: this.MAKE_REFIX, predictParam: predictVehicleMakeParam }, { prefix: this.MODEL_REFIX, predictParam: predictVehicleModelParam }]);
  }

  /**
   * Bodytype, ReleaseYears belongs to Vehicle Model, so when update make we should be update them too
   */
  private _handelNavigateByUrlForVehicleModel() {
    let _vehicleModelId = this.vm.search.vehicleModelId;
    let _vehicleBodyTypeId = this.vm.search.vehicleBodyTypeId;
    let predictVehicleModelParam = (!_vehicleModelId || _vehicleModelId == this.DEFAULT_VALUE) ? '' : this.MODEL_REFIX + _vehicleModelId;
    let predictVehicleBodyTypeParam = (!_vehicleBodyTypeId || _vehicleBodyTypeId == this.DEFAULT_VALUE) ? '' : this.BODY_REFIX + _vehicleBodyTypeId;
    let predictVehicleYearsParam = this._handelUpdateUrlForYearReplease(this.vm.search.vehicleYearMin, this.vm.search.vehicleYearMax, true);
    this._goUrlV2([
      { prefix: this.MODEL_REFIX, predictParam: predictVehicleModelParam },
      { prefix: this.BODY_REFIX, predictParam: predictVehicleBodyTypeParam },
      { prefix: this.YEAR_REFIX, predictParam: predictVehicleYearsParam },
    ]);
  }

  private _goUrl(prefix, predictParam) {
    let path = this.location.path();
    let _params = path.split("/");
    let isReplace = false;

    for (let param of _params) {
      if (param.startsWith(prefix)) {
        path = path.replace(param, predictParam);
        isReplace = true;
        break;
      }
    }
    if (!isReplace) {
      let query = ""
      if (path.indexOf('?') > -1) {
        [path, query] = path.split('?');
        query = '?' + query;
      }
      path += '/' + predictParam + query;
    } {
      // after replace '//' by '/' after remove pram
      path = path.replace("//", "/");
    }

    this.router.navigateByUrl(path);
  }

  // for related and replace/add mutiple param
  private _goUrlV2(dataArray) {
    let path = this.location.path();
    let _params = path.split("/");
    let _subParam = "";
    for (let data of dataArray) {
      let isReplace = false;
      for (let param of _params) {
        if (param.startsWith(data.prefix)) {
          path = path.replace(param, data.predictParam);
          isReplace = true;
          break;
        }
      }
      // summary totals
      if (!isReplace && data.predictParam) {
        _subParam += "/" + data.predictParam
      }
    }
    // consider split query param for update and add it again
    if (_subParam) {
      let query = ""
      if (path.indexOf('?') > -1) {
        [path, query] = path.split('?');
        query = '?' + query;
      }
      path += _subParam + query;
    } {
      // after replace '//' by '/' after remove pram
      path = path.replace("//", "/");
    }

    this.router.navigateByUrl(path);
  }


  private _handelUpdateUrlForYearReplease(paramMin, paramMax, isNotGoUrl?) {
    // only set when have param
    let predictParam = '';
    if ((!paramMin && !paramMax) || (paramMin == this.DEFAULT_VALUE && paramMax == this.DEFAULT_VALUE)) {
      predictParam = '';
    } else {
      if (paramMin != this.DEFAULT_VALUE && paramMax != this.DEFAULT_VALUE) {
        predictParam = this.YEAR_REFIX_RANGE + paramMin + '-' + paramMax;
      } else if (paramMin != this.DEFAULT_VALUE) {
        predictParam = this.YEAR_REFIX_MIN + paramMin;
      } else if (paramMax != this.DEFAULT_VALUE) {
        predictParam = this.YEAR_REFIX_MAX + paramMax;
      } else {
        predictParam = '';
      }
    }
    if (isNotGoUrl) {
      return predictParam;
    }

    this._goUrl(this.YEAR_REFIX, predictParam);
  }

  private _handelNavigateByUrlPriceSlider(paramMin, paramMinCfg, paramMax, paramMaxCfg) {
    let predictParam = "";
    // only adjust
    if (paramMin != paramMinCfg && paramMax != paramMaxCfg) {
      // TODO improve over (+) add meaning word like 'hơn 5 tỉ'
      predictParam = this.PRICE_REFIX + this._u._convertPrice2VietNamese(paramMin, 'ti', 'trieu', '-') + this.PRICE_MIDLE_REFIX + this._u._convertPrice2VietNamese(paramMax, 'ti', 'trieu', '-');
    }
    this._goUrl(this.PRICE_REFIX, predictParam);
  }

  private _handelNavigateByUrlKmUsedSlider(paramMax, paramMaxCfg) {
    let predictParam = "";
    // only adjust
    if (paramMax != paramMaxCfg) {
      // TODO improve over (+) add meaning word like 'hơn 300,000 km'
      predictParam = this.KM_USED_REFIX + paramMax + this.KM_USED_UNIT;
    }
    this._goUrl(this.KM_USED_REFIX, predictParam);
  }

  private _handelNavigateByUrlForQueryParam() {
    // transmission
    let queryParam = {
    }
    for (let trans of this.vm.vehicleTransmissions) {
      // console.log('onSelectVhicleTransmission', trans);
      if (trans.isSelected) {
        // only init when have isSelete because stringify generate empty param like "hop-so="
        if (!queryParam[this.QP_TRANS]) {
          queryParam[this.QP_TRANS] = [];
        }
        queryParam[this.QP_TRANS].push(trans.id);
      }
    }
    // vehicle source
    if (this.vm.search.vehicleSourceId != this.DEFAULT_VALUE) {
      queryParam[this.QP_VEHICLE_SOURCE] = this.vm.search.vehicleSourceId;
    }

    // exterior color
    for (let color of this.vm.vehicleExteriorColors) {
      // console.log('onSelectVhicleTransmission', color);
      if (color.isSelected) {
        // only init when have isSelete because stringify generate empty param like "mau="
        if (!queryParam[this.QP_EXTERIOR_COLOR]) {
          queryParam[this.QP_EXTERIOR_COLOR] = [];
        }
        queryParam[this.QP_EXTERIOR_COLOR].push(color.id);
      }
    }
    // let query = decodeURI(stringify(queryParam, { arrayFormat: 'brackets' }));
    let query = decodeURI(stringify(queryParam, { arrayFormat: 'comma' }));
    let path = this.location.path();
    if (path.indexOf('?') >= -1) {
      path = path.split('?')[0];
    }

    console.log("goto: ", path + "?" + query);
    this.router.navigateByUrl(path + '?' + query);
  }

}
