import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AdvertComponent } from './advert/advert.component';
import { AdvertListComponent } from './advert-list/advert-list.component';
import { AdvertDetailComponent } from './advert-detail/advert-detail.component';
import { AllMakeComponent } from './all-make/all-make.component';
import { DealerListComponent } from './dealer-list/dealer-list.component';
import { JoinusComponent } from './joinus/joinus.component';

import { BlogComponent } from './blog/blog.component';
import { SinglePostComponent } from './blog/single-post.component';
import { ContactComponent } from './contact/contact.component';
import { AdvertManagementComponent } from './advert-management/advert-management.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'tat-ca-thuong-hieu-xe-oto', component: AllMakeComponent, pathMatch: 'full' },
  { path: 'joinus', component: JoinusComponent, pathMatch: 'full' },
  { path: 'blog', component: BlogComponent},
  { path: 'blog/:slug', component: SinglePostComponent, pathMatch: 'full'},
  { path: 'lien-he-meoto', component: ContactComponent, pathMatch: 'full'},
  { path: 'chi-tiet-tin/:slug/:id', component: AdvertDetailComponent, pathMatch: 'full'},
  { path: 'dang-ban-xe-oto/:slug', component: AdvertComponent, pathMatch: 'full' },
  { path: 'users/:userId/:slug', component: AdvertManagementComponent, pathMatch: 'full' },
  // { path: 'users/:userId/tim-kiem', component: AdvertManagementComponent, pathMatch: 'full' },
  // { path: 'users/:userId/tin-da-luu', component: AdvertManagementComponent, pathMatch: 'full' },
  // { path: 'users/:userId/quan-ly-tin-ban-xe', component: AdvertManagementComponent, pathMatch: 'full' },
  // { path: 'users/:userId/profile', component: AdvertManagementComponent, pathMatch: 'full' },
  // { path: 'users/:userId/setting', component: AdvertManagementComponent, pathMatch: 'full' },
  { path: 'salon-oto-ca-nuoc/:region', component: DealerListComponent, pathMatch: 'full' },
  { path: 'danh-sach-xe-oto',
   children: [
    {
      path: '', //make|bodytype|price|year
      component: AdvertListComponent,
    },
    {
      path: ':param0', //make|bodytype|price|year
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1', //make/bodytype|model|bodytype|price|year
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1/:pram2',
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1/:param2/:pram3',
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1/:param2/:pram3/:pram4',
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1/:param2/:pram3/:pram4/:param5',
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1/:param2/:pram3/:pram4/:param5/:param6',
      component: AdvertListComponent,
    },
    {
      path: ':param0/:param1/:param2/:pram3/:pram4/:param5/:param6/:param7',
      component: AdvertListComponent,
    },
   ] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
