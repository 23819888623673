// https://stackoverflow.com/questions/52787570/how-to-format-number-input-in-angular
// https://jasonwatmore.com/post/2020/04/19/angular-9-reactive-forms-validation-example
// https://www.itsolutionstuff.com/post/angular-ngmodel-example-ngmodel-directive-in-angular-9-8example.html
import { Component, OnInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CanActivate, ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';

import { CacheService } from '../services/cache.service';
import { VehicleService } from '../services/vehicle.service';
import { AdvertService } from '../services/advert.service';
import { UtilsService } from '../services/utils.service';
import { of } from 'rxjs';
import { LoginService } from '../services/login.service';
declare var FB: any;
declare var jQuery: any;
import { ScriptService } from '../services/load-script.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-advert',
  templateUrl: './advert.component.html',
  styleUrls: ['./advert.component.css']
})
export class AdvertComponent implements OnInit {
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  public vm: any = {};
  public STEP_NAME: string = 'chon-xe';
  public isLoaded = false;
  private CLIENT_KEY = 'clientId';
  private USER_KEY = '_u';
  private ADS_KEY = 'advertId';

  constructor(
    private toastr: ToastrService,
    // private elementRef: ElementRef, @Inject(DOCUMENT) private doc,
    private route: ActivatedRoute,
    private router: Router,
    private _u: UtilsService,
    private scriptService: ScriptService,
    private formBuilder: FormBuilder,
    private advertService: AdvertService,
    private cacheService: CacheService,
    private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,

  ) {
    // async orders
    // of(this.getOrders()).subscribe(orders => {
    //   this.orders = orders;
    // });

    this.vm.header = 'Adverts';
    this.vm.search = {
      vehicleMakeId: '',
      vehicleModelId: '',
      vehicleYearRelease: '',
    };
    this.vm.advert = {
      vehicleAddons: [],
      isSaleToSalon: false,
      vehicleMakeId: '',
      vehicleModelId: '',
      vehicleYearRelease: '',
      placeId: '',
      vehicleExteriorColorId: '',
      phone: '',
      kmUsed: ''
    };

    this.vm.selectedVehicleTemplate = null;
    this.vm.vehicleMakes = [];
    this.vm.selectedVechicleMake = null;
    this.vm.vehicleModel = [];
    this.vm.selectedVechicleModel = null;
    this.vm.vehicleTemplates = null;
    this.vm.vehicleAddons = null;
    this.vm.selectedVehicleAddons = [];

    this.vm.selfVehicle = {}

    //------------
    this.isLoaded = false;

    // public
    // this.vm.pickVehicleMake = this.pickVehicleMake;
    // // this.vm.onSelectVehicleModel = onSelectVehicleModel;
    // this.vm.findVehicleTemplate = this.findVehicleTemplate;
    // this.vm.addVechileAndNext = this.addVechileAndNext;
    // // this.vm.addAddonAndNext = this.addAddonAndNext;
    // this.vm.addCarInfoAndNext = this.addCarInfoAndNext;
    // this.vm.addCarImageAndNext = this.addCarImageAndNext;
    // this.vm.completeAdvert = this.completeAdvert;
    // this.vm.moveToSelectVehicleTab = this.moveToSelectVehicleTab;
    // this.vm.selfEnterVehicleInfo = this.selfEnterVehicleInfo;
    this.scriptService.load(this.scriptService.INIT)
      .then(data => {
        this._triggerClick(this.STEP_NAME);
      });
    // need init here, because maybe user can access any slug param, and after that click to "chon xe"
    // So we will facing empty data
    this._initData();
  }


  /**
   * If existed advertId in localstorage mean guest-user not login yet, we should be reload their option and next
   * 
   */
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      // // facebook SDK
      // this.facebookSDK();
      // // google SDK
      // this.googleSDK();
      // set for _triggerClick after loading script
      let slug = params['slug'];
      this.STEP_NAME = slug;
      this.step(slug);
      console.log('just go to:: ', slug);
      // this.scriptService.load(this.scriptService.PROCESS_BAR)
      //   .then(data => {
      this._triggerClick(this.STEP_NAME);
      // });
      // get advert data
      // let advertId = localStorage.getItem(this.ADS_KEY);
      // if (advertId) {
      //   this.advertService.getAdvert(advertId)
      //     .then(advert => {
      //       this.vm.advert = advert;
      //       this.step(slug);
      //     });
      // } else {
      //   let slug = params['slug'];
      //   this.step(slug);
      //   console.log('just go to:: ', slug);
      // }
    });

    // let advertId = localStorage.getItem(this.ADS_KEY);
    // if (advertId) {
    //   this.advertService.getAdvert(advertId)
    //     .then(advert => {
    //       this.vm.advert = advert;
    //       // go to screen
    //       this.route.params.subscribe(params => {
    //         let slug = params['slug'];
    //         this.step(slug);
    //         console.log('slug', slug);
    //       });
    //     })
    //     .catch(resp => {
    //       // remove notfound advert
    //       if (resp.status == 404) {
    //         localStorage.removeItem(this.ADS_KEY);
    //       }
    //       // go to screen
    //       this.route.params.subscribe(params => {
    //         let slug = params['slug'];
    //         this.step(slug);
    //         console.log('slug', slug);
    //       });
    //     });
    // }
  }
  // ngAfterViewChecked(): void {
  //   // this._triggerClick(this.STEP_NAME);
  // }

  // ngAfterViewInit(): void {
  //   this.route.params.subscribe(params => {
  //     let slug = params['slug'];
  //     this.STEP_NAME = slug;
  //     this._triggerClick(this.STEP_NAME);
  //     console.log('ngAfterViewInit.......');
  //   });
  // }


  public onSelectVehicleMake(vehicleMakeId) {
    if (!vehicleMakeId) {
      vehicleMakeId = this.vm.search.vehicleMakeId;
    }
    this.cacheService.getVehicleModelsByVehicleMakeId(vehicleMakeId)
      .then(vehicleModels => {
        this.vm.vehicleModels = vehicleModels;
        this.vm.search.vehicleModelId = "";
        // this.vm.selectedVechicleModel = this._reloadOption(vehicleModels, this.vm.advert.vehicleModelId, false);
      });
  }

  public onSelectVehicleModel(vehicleModelId) {
    if (!vehicleModelId) {
      // vehicleModelId = this.vm.search.vehicleModelId;
      this.vm.search.vehicleYearRelease = "";
      return;
    }
    for (let model of this.vm.vehicleModels) {
      if (model.id == vehicleModelId) {
        let { vehicleYearReleases } = model;
        // sort
        this.vm.vehicleYearReleases = vehicleYearReleases.sort((a, b) => b - a);
        if (!vehicleYearReleases.includes(this.vm.search.vehicleYearRelease)) {
          // default again
          this.vm.search.vehicleYearRelease = "";
        }
        break;
      }
    }
  }

  public onSelectVehicleExteriorColor(exteriorColorId) {
    for (let color of this.vm.vehicleExteriorColors) {
      if (exteriorColorId == color.id) {
        this.vm.advert.vehicleExteriorColorName = color.name;
        break;
      }
    }
  }


  // TODO implement filter params
  public onFindVehicleTemplate() {
    this.vehicleService.getVehicleTemplates(this.vm.search)
      .then(vehicleTemplates => {
        this.vm.vehicleTemplates = vehicleTemplates;
      });
  }

  public addVechileAndNext(vehicleTemplate) {
    const nextTo = 'chon-tinh-nang-xe';
    this.vm.selectedVehicleTemplate = { ...vehicleTemplate };
    // remove result list for not BUG when go back after this function go to next step
    this.vm.vehicleTemplates = [];
    // if re-selected we just go to next page
    if (this.vm.advert.vehicleTemplateId == vehicleTemplate.id) {
      this._triggerClick(nextTo);
      return;
    } else if (this.vm.advert.id) { // if already existed advert
      // copy field from vehicleTemplate to this.vm.advert and overide it
      let vehicleTemplateId = vehicleTemplate.id;
      delete vehicleTemplate.additionalProp1;
      delete vehicleTemplate.id;
      delete vehicleTemplate.createAt;
      delete vehicleTemplate.updateAt;
      this.vm.advert = { ...this.vm.advert, ...vehicleTemplate }
      this.vm.advert.vehicleTemplateId = vehicleTemplateId
      this.advertService.patchAdverts(this.vm.advert.id, this.vm.advert)
        .then(advert => {
          console.log("addVechileAndNext update advertss ok");
          // this.vm.advert = advert;
          this._triggerClick(nextTo);
        })
        .catch(() => this.toastr.error("Vui lòng kiểm tra thông hoặc liên hệ với Moto, code: [MOAD0001]"));

      return;
    }

    // create new advert
    let advert = { ...vehicleTemplate };
    // save to API
    // $state.go('adverts', {slug:nextTo });
    // if not login we create tmp ID
    let user = localStorage.getItem("_u");
    if (!user) {
      let clientId = localStorage.getItem(this.CLIENT_KEY);
      // generate ClientId if not exited
      if (!clientId) {
        clientId = this._generateId(19);
        localStorage.setItem(this.CLIENT_KEY, clientId);
      }
      // assgin to tmp user
      advert.clientId = clientId;
    }
    advert.vehicleTemplateId = vehicleTemplate.id
    // remove redudant fields
    delete advert.additionalProp1;
    delete advert.id;
    delete advert.createAt;
    delete advert.updateAt;
    // add
    this.advertService.createAdverts(advert)
      .then(advert => {
        console.log("advertss::", advert);
        this.vm.advert = advert;
        localStorage.setItem(this.ADS_KEY, advert.id);
        this._triggerClick(nextTo);
      })
      .catch(() => this.toastr.error("Vui lòng kiểm tra thông hoặc liên hệ với Moto, code: [MOAD0002]"));
  }

  public selfEnterVehicleInfo() {
    const nextTo = 'chon-tinh-nang-xe';

    this.vm.advert.vehicleMakeName = this.vm.selfVehicle.vehicleMakeName;
    this.vm.advert.vehicleMakeId = "";
    this.vm.advert.vehicleModelName = this.vm.selfVehicle.vehicleModelName;
    this.vm.advert.vehicleModelId = "";
    this.vm.advert.vehicleVersion = this.vm.selfVehicle.vehicleVersion;
    // body type
    this.vm.advert.vehicleBodyTypeId = this.vm.selfVehicle.vehicleBodyType.id;
    this.vm.advert.vehicleBodyTypeName = this.vm.selfVehicle.vehicleBodyType.name;
    // year
    this.vm.advert.vehicleYearRelease = (this.vm.selfVehicle.vehicleYearRelease) ? Number(this.vm.selfVehicle.vehicleYearRelease) : 0;
    // door
    this.vm.advert.vehicleDoorId = this.vm.selfVehicle.vehicleDoor.id;
    this.vm.advert.vehicleDoorQuantity = this.vm.selfVehicle.vehicleDoor.quantity;
    // seat
    this.vm.advert.vehicleSeatId = this.vm.selfVehicle.vehicleSeat.id;
    this.vm.advert.vehicleSeatQuantity = this.vm.selfVehicle.vehicleSeat.quantity;
    // transmission
    this.vm.advert.vehicleTransmissionId = this.vm.selfVehicle.vehicleTransmission.id;
    this.vm.advert.vehicleTransmissionName = this.vm.selfVehicle.vehicleTransmission.name;
    // drivetype
    this.vm.advert.vehicleDriveTypeId = this.vm.selfVehicle.vehicleDriveType.id;
    this.vm.advert.vehicleDriveTypeName = this.vm.selfVehicle.vehicleDriveType.name;
    // engine
    this.vm.advert.vehicleEngineId = this.vm.selfVehicle.vehicleEngine.id;
    this.vm.advert.vehicleEngineName = this.vm.selfVehicle.vehicleEngine.name;
    // fuel
    this.vm.advert.vehicleFuelId = this.vm.selfVehicle.vehicleFuel.id;
    this.vm.advert.vehicleFuelName = this.vm.selfVehicle.vehicleFuel.name;
    // source
    this.vm.advert.vehicleSourceId = this.vm.selfVehicle.vehicleSource.id;
    this.vm.advert.vehicleSourceName = this.vm.selfVehicle.vehicleSource.name;


    // if aleady selected vehicle template
    if (this.vm.advert.vehicleTemplateId) {
      //TODO consider remove or delete advert
      this.vm.advert.vehicleTemplateId = "";
      this.vm.advert.selectedVehicleTemplate = {};

      this.advertService.patchAdverts(this.vm.advert.id, this.vm.advert)
        .then(advert => {
          // this.vm.advert = advert;
          console.log("selfEnterVehicleInfo update advert ok");
          this._triggerClick(nextTo);
        })
        .catch(resp => console.log(resp));
      return;
    }

    delete this.vm.advert.id
    delete this.vm.advert.createAt;
    delete this.vm.advert.updateAt;
    this.advertService.createAdverts(this.vm.advert)
      .then(advert => {
        console.log("advertss::", advert);
        this.vm.advert = advert;
        localStorage.setItem(this.ADS_KEY, advert.id);
        this._triggerClick(nextTo);
      })
      .catch(() => this.toastr.error("Vui lòng kiểm tra thông hoặc liên hệ với Moto, code: [MOAD0003]"));
  }

  public addAddonAndNext() {
    const nextTo = 'them-thong-tin-xe';
    let advertId = localStorage.getItem(this.ADS_KEY);
    if (!advertId) {
      console.error("Bạn vui lòng chọn xe trước nhé!");
      return;
    }
    const vehicleAddon = [];
    let index = 0;
    for (let vAddon of this.vm.selectedVehicleAddons) {
      if (vAddon) {
        vehicleAddon.push(this.vm.vehicleAddons[index]);
      }
      index++;
    }
    this.vm.advert.vehicleAddons = vehicleAddon;
    // console.log(vehicleAddon);
    this.advertService.patchAdverts(advertId, this.vm.advert)
      .then(advert => {
        console.log("update advert ok");
        // this.vm.advert = advert;
        this._triggerClick(nextTo);
      })
      .catch(() => this.toastr.error("Vui lòng kiểm tra thông hoặc liên hệ với Moto, code: [MOAD0004]"));

  }

  public addCarInfoAndNext() {
    const nextTo = 'them-anh-va-mo-ta-xe';
    let advertId = localStorage.getItem(this.ADS_KEY);
    if (!advertId) {
      console.error("Bạn vui lòng chọn xe trước nhé!");
      return;
    }
    this.advertService.patchAdverts(advertId, this.vm.advert)
      .then(advert => {
        console.log("advertss::", advert);
        // this.vm.advert = advert;
        this._triggerClick(nextTo);
      })
  }

  public addCarImageAndNext() {
    const nextTo = 'hoan-tat';
    let advertId = localStorage.getItem(this.ADS_KEY);
    if (!advertId) {
      console.error("Bạn vui lòng chọn xe trước nhé!");
      return;
    }
    this.advertService.patchAdverts(advertId, this.vm.advert)
      .then(advert => {
        console.log("addCarImageAndNext advertss ok");
        // this.vm.advert = advert;
        this._triggerClick(nextTo);
      })
  }

  public completeAdvert() {
    // self enter not have ID so only
    if (!this.vm.advert.vehicleMakeName && !this.vm.advert.vehicleModelName) {
      alert("Vui lòng quay lại bước chọn xe trước nhé!");
      return;
    }
    const nextTo = 'hoan-tat';
    let advertId = localStorage.getItem(this.ADS_KEY);
    if (!advertId) {
      this.toastr.error('Bạn vui lòng chọn xe trước nhé!', undefined);
      return;
    }
    // check login
    let isLogin = this.loginService.isLogin();
    if (!isLogin) {
      jQuery("#modalUser").modal("show");
      return;
    }
    let user = JSON.parse(localStorage.getItem(this.USER_KEY));
    // add userId
    this.vm.advert.userId = user.id;
    // delete clientId
    delete this.vm.advert.clientId;
    localStorage.removeItem(this.CLIENT_KEY);
    // add advert
    this.advertService.patchAdverts(advertId, this.vm.advert)
      .then(advert => {
        this.toastr.success("Bạn đã tạo thành công, Meoto sẽ nhanh chóng duyệt tin của bạn!");
        localStorage.removeItem(this.ADS_KEY);
        // this.vm.advert = advert;
        // TODO define later
      })
      .catch(() => this.toastr.error("Có lỗi xãy ra, bạn vui lòng kiểm tra lại thông tin!"));
  }

  //---------------------------------
  // init steps
  private async _initData() {
    this.vm.vehicleMakes = await this.cacheService.getVehicleMakes();
    // load advert
    let advertId = localStorage.getItem(this.ADS_KEY);
    try {
      this.vm.advert = await this.advertService.getAdvert(advertId);
    } catch (error) {
      console.log("NO ads temp found");
      // this.vm.advert = {};
    }
    this.onSelectVehicleMake(undefined);
    if (this.vm.advert.vehicleTemplateId) {
      // get slected vehicle template
      this.vehicleService.getVehicleTemplate(this.vm.advert.vehicleTemplateId)
        .then(vehicleTemplate => {
          this.vm.selectedVehicleTemplate = vehicleTemplate;
          console.log('vehicleTemplate', vehicleTemplate);
        });
    } else {
      this.vm.selfVehicle.vehicleMakeName = this.vm.advert.vehicleMakeName;
      this.vm.selfVehicle.vehicleModelName = this.vm.advert.vehicleModelName;
      this.vm.selfVehicle.vehicleVersion = this.vm.advert.vehicleVersion;
      this.vm.selfVehicle.vehicleYearRelease = this.vm.advert.vehicleYearRelease;
    }



    const promises: any[] = [];
    // push the returned promise of each loadScript call 
    promises.push(this.cacheService.getVehicleBodyTypes());
    promises.push(this.cacheService.getVehicleDoors());
    promises.push(this.cacheService.getVehicleSeats());
    promises.push(this.cacheService.getVehicleTransmissions());
    promises.push(this.cacheService.getVehicleDriveTypes());
    promises.push(this.cacheService.getVehicleEngines());
    promises.push(this.cacheService.getVehicleFuels());
    promises.push(this.cacheService.getVehicleExteriorColor());
    promises.push(this.cacheService.getVehicleSources());
    // return promise.all that resolves when all promises are resolved
    Promise.all(promises)
      .then(([vehicleBodyTypes, vehicleDoors, vehicleSeats, vehicleTransmissions, vehicleDriveTypes, vehicleEngines, vehicleFuels, vehicleExteriorColors, vehicleSources]) => {
        this.vm.vehicleBodyTypes = vehicleBodyTypes;
        this.vm.vehicleDoors = vehicleDoors;
        this.vm.vehicleSeats = vehicleSeats;
        this.vm.vehicleTransmissions = vehicleTransmissions;
        this.vm.vehicleDriveTypes = vehicleDriveTypes;
        this.vm.vehicleEngines = vehicleEngines;
        this.vm.vehicleFuels = vehicleFuels;
        this.vm.vehicleExteriorColors = vehicleExteriorColors;
        this.vm.vehicleSources = vehicleSources;

        this.vm.selfVehicle.vehicleBodyType = this._reloadOption(vehicleBodyTypes, this.vm.advert.vehicleBodyTypeId, true);
        this.vm.selfVehicle.vehicleDoor = this._reloadOption(vehicleDoors, this.vm.advert.vehicleDoorId, true);
        this.vm.selfVehicle.vehicleSeat = this._reloadOption(vehicleSeats, this.vm.advert.vehicleSeatId, true);
        this.vm.selfVehicle.vehicleTransmission = this._reloadOption(vehicleTransmissions, this.vm.advert.vehicleTransmissionId, true);
        this.vm.selfVehicle.vehicleDriveType = this._reloadOption(vehicleDriveTypes, this.vm.advert.vehicleDriveTypeId, true);
        this.vm.selfVehicle.vehicleEngine = this._reloadOption(vehicleEngines, this.vm.advert.vehicleEngineId, true);
        this.vm.selfVehicle.vehicleFuel = this._reloadOption(vehicleFuels, this.vm.advert.vehicleFuelId, true);
        this.vm.selfVehicle.vehicleSource = this._reloadOption(vehicleSources, this.vm.advert.vehicleSourceId, true);
      });
    this.vm.vehicleYearReleases = this._u._generateYear();
  }

  private _initVehicleAddons() {
    this.cacheService.getVehicleAddons()
      .then(vehicleAddons => {
        this.vm.vehicleAddons = vehicleAddons;
        // init addons already selected
        for (let vSelectedAddon of this.vm.advert.vehicleAddons) {
          let index = 0;
          for (let vAddon of vehicleAddons) {
            if (vSelectedAddon.id == vAddon.id) {
              this.vm.selectedVehicleAddons[index] = true;
              break;
            }
            index++;
          }
        }
        this.isLoaded = true;
      })
    // .catch(resp => console.error("err getVehicleAddons::", resp));
  }

  public initAddVehicleImage() {
    this.isLoaded = true;
  }

  public initComplate() {
    this.isLoaded = true;
  }

  private step(stepName) {
    // we cannot go to step here, because html render after controller execute, so lead to cannot have event processbar and check
    switch (stepName) {
      case 'chon-xe':
        // this._initData();
        // this.processPercent(0);
        break;
      case 'chon-tinh-nang-xe':
        this._initVehicleAddons();
        // this.processPercent(25);
        break;
      case 'them-thong-tin-xe':
        // this._initAddVehicleInfo();
        // this.processPercent(50);
        break;
      case 'them-anh-va-mo-ta-xe':
        this.initAddVehicleImage();
        // this.processPercent(75);
        break;
      case 'hoan-tat':
        this.initComplate();
        // this.processPercent(100);
        break;
      default:
        this._initData();
        // this.processPercent(0);
        break;
    }
  }



  //-----------------------
  // Donot important
  public moveToSelectVehicleTab() {
    // TODO document.querySelector('#select-vehicle-tab').click();
    this._triggerClick('select-vehicle-tab');
  }

  private _generateId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private _reloadOption(source, key, isVerify) {
    // when use already select vehicleTemplete we don't re-select
    if (isVerify && this.vm.advert.vehicleTemplateId) {
      return "";
    }
    for (let vObj of source) {
      if (key == vObj.id) {
        return vObj;
      }
    }
    return "";
  }

  private _triggerClick(nextTo) {
    let element: HTMLElement = document.getElementById(nextTo) as HTMLElement;
    element.click()
  }
  // // button login facebook
  // public async submitLoginFacebook() {
  //   this.loginService.submitLoginFacebook(true);

  // }

  // // button login google
  // public async submitLoginGoogle() {
  //   this.loginService.submitLoginGoogle(this.loginElement, true);

  // }

  // // SDK facebook library
  // facebookSDK() {
  //   this.loginService.facebookSDK();
  // }

  // // SDK google library
  // googleSDK() {
  //   this.loginService.googleSDK(this.loginElement, true);
  // }

  //   checkValue(event: any){
  //     if (event === 'phone'){
  //       this.phone.nativeElement.value = '';
  //       this.vm.registerType = 'phone';
  //       this.isChecked = true;
  //     }else {
  //       this.email.nativeElement.value = '';
  //       this.vm.registerType = 'email';
  //       this.isChecked = false;
  //     }
  //     console.log(event)
  //     // return event;
  //  }

  //  // button login facebook
  //  public async onSubmit(from) {
  //   //  console.log(this.form.get('firstName').value)
  //     console.log("12312312")
  //    console.log(this.vm.registerType == 'phone')
  //    if(this.vm.registerType == 'phone'){
  //     delete from.email
  //     await this.loginService.registerPhone(from)
  //    }
  //    if(this.vm.registerType == 'email'){
  //     delete from.phone
  //   }

  //   // this.loginService.loginByPhone(from)


  //    console.log(from)
  //   // this.loginService.loginByPhone(from)
  //   // if (!from.checkbox) {
  //   //   return alert("Bạn Chưa Chấp Nhận Điều Khoản");
  //   // }
  //   // delete from.checkbox;
  //   // from.deviceType = "WEB";
  //   // console.log(from);
  //   // let register = await this.joinusService.registerByPhone(from)
  //   // console.log(register)
  // }

  // this.toastr.success('Đăng ký thành công', undefined, {
  //   positionClass: 'toast-top-center'
  // });

}
