import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root'
})
export class AdvertService {

  constructor(private apiService: ApiService) { }

  public getAdverts = this._getAdverts;
  public countAdverts = this._countAdverts;
  public getRecentlyAdverts = this._getRecentlyAdverts;
  public getAdvert = (advertId) => this.apiService.get('ads/' + advertId);
  public getUserAdvert = (userId) => this.apiService.get('ads?filter[where][userId]=' + userId + '&filter[where][isDelete]=false&');
  public getSavedAdvert = (userId) => this.apiService.get('users/' + userId + '/saved-ads' + '?filter[where][isDelete]=false&' + 'filter[include][][relation]=ads');
  public getSavedSearch = (userId) => this.apiService.get('saved-searches?filter[where][userId]=' + userId + '&filter[where][isDelete]=false');// TODO move later
  // public getSavedAdvert = (userId) => this.apiService.get(`save-ads?filter[where][userId]=5eeb40fe295e6ac4e8c9a154&filter[include][][relation]=ads` + userId);// TODO move later


  public createAdverts = (advert) => this.apiService.post('ads', advert);
  public creatSavedAdverts = (savedAdvert) => this.apiService.post('saved-ads/', savedAdvert);
  public creatSavedSearches = (savedSearches) => this.apiService.post('saved-searches/', savedSearches);


  public patchAdverts = (id, advert) => this.apiService.patch('ads/' + id, advert);
  public updateSavedAdvert = (id, savedAdvert) => this.apiService.patch('saved-ads/' + id, savedAdvert);


  public updateSavedSearch = (id, savedSearch) => this.apiService.patch('saved-searches/' + id, savedSearch);
  public updateUserAdvert = (id, userAdvert) => this.apiService.patch('ads/' + id, userAdvert);




  private _getAdverts(search) {
    if (!search) {
      return this.apiService.get('ads');
    }

    let queryParam = this._buildSearchQuery(search);
    return this.apiService.get('ads' + queryParam);
  }

  private _countAdverts(search) {
    if (!search) {
      return this.apiService.get('ads/count');
    }

    let queryParam = this._buildSearchQuery(search, true);
    return this.apiService.get('ads/count' + queryParam);
  }

  private _buildSearchQuery(search, isCount?) {
    let where: any = {};
    let queryBuilder = {
      filter: {
        where: where,
        limit: search.pageItems,
        skip: search.pageItems * search.pageCurrent,
      },
    };
    if (isCount) {
      queryBuilder = <any>{
        where: where
      }
    }
    // inint query param
    let queryParam = '';
    // for place sale the car
    if (search.placeId && search.placeId != '-') {
      where.placeId = search.placeId;
    }
    // for satus new, used
    if (search.vehicleStatus && search.vehicleStatus != '-') {
      if (search.vehicleStatus == 'moi') {
        where.isNew = true;
      } else {
        where.isNew = false;
      }
    }
    // for Make
    if (search.vehicleMakeId && search.vehicleMakeId != '-') {
      where.vehicleMakeId = search.vehicleMakeId;
    }
    // for model
    if (search.vehicleModelId && search.vehicleModelId != '-') {
      where.vehicleModelId = search.vehicleModelId;
    }
    // for bodytype
    if (search.vehicleBodyTypeId && search.vehicleBodyTypeId != '-') {
      where.vehicleBodyTypeId = search.vehicleBodyTypeId;
    }
    // for year release
    if (search.vehicleYearMin && search.vehicleYearMin != '-') {
      where.vehicleYearRelease = { 'gte': search.vehicleYearMin };
    }
    if (search.vehicleYearMax && search.vehicleYearMax != '-') {
      // if already add year relese above
      if (where.vehicleYearRelease) {
        // where.vehicleYearRelease.lte = search.vehicleYearMax;
        where.vehicleYearRelease = { between: [search.vehicleYearMin, search.vehicleYearMax] };
      } else {
        where.vehicleYearRelease = { 'lte': search.vehicleYearMax };
      }
    }
    // TODO unique add + symbol and check change here
    if (search.minPrice) {
      where.price = { 'gte': search.minPrice };
    }
    if (search.maxPrice && search.maxPrice != search.maxPriceCfg) {
      // if already add year relese above
      if (where.price) {
        // where.price.lte = search.maxPrice;
        where.vehicleYearRelease = { between: [search.minPrice, search.maxPrice] };
      } else {
        where.price = { 'lte': search.maxPrice };
      }
    }
    // searh for km used
    if (search.maxKmUsed && search.maxKmUsed != search.maxKmUsedCfg) {
      where.KmUsed = { 'lte': search.maxKmUsed };
    }
    if (search.vehicleTransmissions && search.vehicleTransmissions.length) {
      // TODO testing late, condider OR condition
      let temp = [];
      for (let entry of search.vehicleTransmissions) {
        temp.push(entry.id);
      }
      where.vehicleTransmissionId = { inq: temp };
    }
    if (search.vehicleSourceId && search.vehicleSourceId != '-') {
      where.vehicleSourceId = search.vehicleSourceId;
    }
    // Exterior color
    if (search.vehicleExteriorColors && search.vehicleExteriorColors.length) {
      // TODO testing late, condider OR condition
      let temp = [];
      for (let entry of search.vehicleExteriorColors) {
        temp.push(entry.id);
      }
      where.vehicleExteriorColorId = { inq: temp };
    }
    // advert status
    if (search.status) {
      where.status = search.status;
    }
    if (Object.keys(where).length > 0 || !isCount) {
      queryParam = "?" + decodeURI(stringify(queryBuilder));
    }

    return queryParam;
  }

  private _getRecentlyAdverts() {
    let queryBuilder = {
      filter: {
        where : {status: 'public'},
        limit: 10,
        order: 'createAt DESC'
      },
    };
    // inint query param
    let queryParam = "?" + decodeURI(stringify(queryBuilder));
    return this.apiService.get('ads' + queryParam);
  }

}
